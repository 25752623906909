import { int } from "aws-sdk/clients/datapipeline";

export class TaskDocument {
  id?: number;
  dateCreated?: Date;
  documentName: string = '';
  name:string;
  description?: string = null;
  subCategoryID?: number = 0;
  file?: File;
  fileLocation?: string;
  taskId?: number;
  userId?: number;
  active: boolean = true;
  fileExt: string;
  size:number;
}
export class AddOrUpdateTaskDocs {
  taskId: number;
  documentJSON: string;
  userId: number;
}
