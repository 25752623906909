export class RolePermission {
  id: number;
  roleId: number;
  permissionId: number;
  dateCreated: Date;
}


export class Permission {
  id: number;
  permissionName: string;
  isPage: boolean;
  description: string;
  dateCreated: Date;
  dateUpdated: Date;
  parentId:number;
  isChecked:boolean;
  className:string;
}
export class AddPermissionsRequest {
  roleId: number;
  ids: string;
}