import
{
  Component,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Deals } from 'src/app/Models/Deals';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-adddeals',
  templateUrl: './adddeals.component.html',
  styleUrls: ['./adddeals.component.css'],
})
export class AdddealsComponent
{
  data: Deals | undefined;
  dealStatusList: any | undefined;
  public selectedUers: User[] = [];
  public usersList: User[] = [];
  public selectedUersFilter: User[] = [];
  public dropdownSettingsUsers = {
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: 'There is no item availabale to show',
    singleSelection: false,
    enableCheckAll: true,
    clearSearchFilter: true,
  };
  projectId: any;
  isDataSaving: boolean;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService
  )
  {
    this.data = this.dialogConfig.data.deal;
    this.dealStatusList = this.dialogConfig.data.dealStatusList;
    this.selectedUers = this.dialogConfig.data.selectedUers;


  }
  ngAfterViewInit(): void
  {
    this.getAllUsers()
      .then((result) =>
      {
        if (result)
        {
          if (
            this.data !== undefined &&
            this.data.userIds &&
            this.data.userIds.length > 0
          )
          {
            const array = this.usersList.filter((item) =>
              this.data.userIds.includes(item.id)
            );
            this.selectedUers = array;
          } else
          {
            this.selectedUers = [];
          }
        }
      })
      .catch((error) =>
      {
        console.error(error);
      });
  }
  public getAllUsers(): Promise<boolean>
  {
    return new Promise((resolve, reject) =>
    {
      this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
        next: (resp: Result) =>
        {
          if (resp.status === 'success')
          {
            this.usersList = resp.data;
            resolve(true);
          } else
          {
            this.usersList = resp.data;
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000, });
          }
        },
        error: (error) =>
        {
          reject(true);
          console.error('error:', error);
        },
      });
    });
  }

  close(): void
  {
    this.dialogRef.close(true);
  }
  public validateAndSetData()
  {
    if (!this.selectedUers || this.selectedUers.length === 0)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select at least one user.',
        life: 3000,
      });
      return false;
    }

    if (!this.data.statusId || this.data.statusId === 0)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select status',
        life: 3000,
      });
      return false;
    }
    if (!this.data.dealOwner)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill concern name of this deal owner name',
        life: 3000,
      });
      return false;
    }

    if (!this.data.name)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the company name',
        life: 3000,
      });
      return false;
    }
    if (!this.data.projectId || this.data.projectId === 0)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'ProjectId is mandatory',
        life: 3000,
      });
      return false;
    }
    this.data.userIds = this.selectedUers.map((x) => x.id);
    this.data.createdByUserId = this.authService.currentUserValue.id;
    this.data.updatedByUserId = this.authService.currentUserValue.id;
    this.data.dateCreated = new Date();
    this.data.probability = parseFloat(this.data.probability.toFixed(2));
    return true;
  }
  public onSubmit()
  {
    if (this.validateAndSetData())
    {
      this.isDataSaving = true;
      const x = this.apiService.addUpdateDeals(this.data).subscribe({
        next: (resp: Result) =>
        {
          if (resp.status === 'success')
          {
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });
            this.close();
          } else
          {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000, });
          }
          this.isDataSaving = false;
        },
        error: (error) =>
        {
          this.isDataSaving = false;
          console.error('error:', error);
        },
      }
      );

    }
  }
  selectUsers($event: any)
  {
    const data = $event;
    this.selectedUers.push(data);
  }

  onItemDeSelectUsers(item: any)
  {
    this.selectedUers?.forEach((value: any, index: any) =>
    {
      if (value == item.id) this.selectedUers?.splice(index, 1);
    });
  }

  onSelectAllUsers(items: any)
  {
    this.selectedUers = items;
  }

  onDeSelectAllIUsers(items: any)
  {
    this.selectedUers = items;
  }

  selectUsersFilter(e: any)
  {
    const data = e;
    this.selectedUersFilter.push(data);
  }

  onItemDeSelectUsersFilter(item: any)
  {
    this.selectedUersFilter?.forEach((value: any, index: any) =>
    {
      if (value == item.id) this.selectedUersFilter?.splice(index, 1);
    });
  }

  onSelectAllUsersFilter(items: any)
  {
    this.selectedUersFilter = items;
  }

  onDeSelectAllIUsersFilter(items: any)
  {
    this.selectedUersFilter = items;
  }
}
