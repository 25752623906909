import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  routeName: string = '';
  settingsVisible = false;
  constructor(public router: Router, public authService: AuthService) {
    this.router.events.subscribe((event) => {
      event instanceof NavigationEnd ? (this.routeName = event.url) : null;
    });
  }

  OnChildClicked() {
    this.settingsVisible = !this.settingsVisible;
  }
}
