import { Component, Renderer2 } from '@angular/core';

import 'src/app/extensions/DateExtensions';
import { DialogService } from 'primeng/dynamicdialog';
import { TaskSetupComponent } from '../../tasks/task-setup/task-setup.component';
import { Task } from 'src/app/Models/Task';
import { OrganizationOverview } from 'src/app/Models/OrganizationOverview';
import { ApiService } from 'src/app/services/api.service';
import { Result } from 'src/app/Models/Result';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-overall-analytics',
  templateUrl: './overall-analytics.component.html',
  styleUrls: ['./overall-analytics.component.css'],
})
export class OverallAnalyticsComponent {
  selectedDate: Date = new Date();
  public isLoading = false;
  overview: any;
  monthlyCostData: any;
  chartData:any;
  workloadData1:any;
  constructor(
    private dialogService: DialogService,
    private apiService: ApiService,
    public authService: AuthService,
    private renderer: Renderer2
  ) {
    this.selectedDate = new Date();
  }

  ngAfterViewInit() {
  }

  applyFilter() {
    this.isLoading = true;
    this.loadData().then((t) => {
     // this.createCharts();
     this.getCostForcast();
     this.getEstimatedCostToCompletion();
     this.loadMonthlyCostTrend(t.monthlyCost);
     this.loadEngagementData(t.engagementData);
     this.workloadResource(t.workloadData);
    });
  }

  totalTasks = 120;
  completedTasks = 75;
  pendingTasks = 45;
  overdueTasks = 10;
  tasksInProgress = 35;
  averageCompletionTime = 3.5;
  totalTimeSpent = 500;

  overdueTasksCount = 10;
  overbudgetTasksCount = 5;

  taskStatusData: any;
  workloadData: any;
  workloadChartOptions: any;
  timeSpentData: any;
  timeSpentChartOptions: any;
ChartOptions1: any;

  resourceUtilization = 80;
  availableResources = 5;

  totalBudget: number = 25000; // Example total budget
  totalCosts: number = 12000;
  actualCost: number = 18000;
  totalHours: number = 500;
  costForecast: number = 0;
  costToCompletion: number = 0;

  // Dummy data for charts
  engagementData = {
    labels: ['Client Onboarding', 'Audit', 'Tax Preparation', 'Consultation'],
    data: [20, 40, 25, 15],
    completion: [20,20,30,21]
  };

  financialPerformanceData = {
    labels: ['Revenue', 'Expenses', 'Net Profit'],
    data: [500000, 300000, 200000],
  };

  complianceData = {
    labels: ['Compliant', 'Non-Compliant'],
    data: [85, 15],
  };

  findingsData = {
    labels: ['Resolved', 'Pending', 'Critical'],
    data: [60, 25, 15],
  };

  teamWorkload = {
    teamMembers: ['Alice', 'Bob', 'Charlie', 'Diana', 'Eve'],
    completedTasks: [8, 5, 6, 2, 3],
    pendingTasks: [2, 3, 1, 4, 5],
  };

  projectHealth = {
    totalClients: 100,
    overallEngagement: 75,
    overdueEngagements: 10,
    totalRevenue: 85000,
    totalExpenses: 60000,
  };

  topBoxes = [
    { title: 'Total Cases', value: 300 },
    { title: 'Active Cases', value: 80 },
    { title: 'Overall Completion Pct', value: '70%' },
    { title: 'Risk/Overdue Cases', value: 20 },
    {
      title: 'Total Actual Cost Vs Estimated Cost',
      value: 'Rs 2,281.33 / Rs 631,063',
    },
    { title: 'Remaining Budget', value: 'Rs 628,781.67' },
  ];







  casesPerServiceData = {
    labels: ['Service A', 'Service B', 'Service C', 'Service D'],
    datasets: [
      {
        data: [300, 500, 100, 200],
        backgroundColor: ['#FF638450', '#36A2EB50', '#FFCE5650', '#FF634750'],
      },
    ],
  };

  completionRatesData = {
    labels: ['Service A', 'Service B', 'Service C', 'Service D'],
    datasets: [
      {
        label: 'Completion Rate',
        data: [70, 60, 80, 50],
        backgroundColor: '#66BB6A50',
      },
    ],
  };

  topPerformers = [
    { name: 'User 1', value: '95%' },
    { name: 'User 2', value: '92%' },
    { name: 'User 3', value: '90%' },
    { name: 'User 4', value: '85%' },
    { name: 'User 5', value: '80%' },
  ];

  serviceCostData = {
    labels: ['Actual Cost', 'Estimated Cost'],
    datasets: [
      {
        data: [50000, 60000],
        backgroundColor: ['#FF638450', '#36A2EB50'],
      },
    ],
  };

  overallSummary = {
    totalClients: 100,
    overallEngagement: 75,
    overdueEngagements: 10,
    totalRevenue: 85000,
    totalExpenses: 60000,
  };

  topClients: any[] = [
    {
      clientName: 'Acme Corp',
      caseName: 'CASE# 003',
      totalTasks: 30,
      completionRate: '90',
      plannedDates: '01/01/2024 - 31/01/2024',
      riskStatus: 'On Track',
      statusClass: 'status on-track bg pill',
      progress: 75,
      totalTimeSpent: 120,
    },
    {
      clientName: 'Beta Solutions',
      caseName: 'CASE# 001',
      totalTasks: 25,
      completionRate: '80',
      plannedDates: '01/02/2024 - 28/02/2024',
      riskStatus: 'At Risk',
      statusClass: 'status at-risk bg pill',
      progress: 25,
      totalTimeSpent: 100,
    },
    {
      clientName: 'Gamma Industries',
      caseName: 'CASE# 006',
      totalTasks: 20,
      completionRate: '70',
      plannedDates: '01/03/2024 - 31/03/2024',
      riskStatus: 'Overdue',
      statusClass: 'status overdue bg pill',
      progress: 51,
      totalTimeSpent: 80,
    },
    {
      clientName: 'Delta Enterprises',
      caseName: 'CASE# 002',
      totalTasks: 15,
      completionRate: '60',
      plannedDates: '01/04/2024 - 30/04/2024',
      riskStatus: 'On Track',
      statusClass: 'status on-track bg pill',
      progress: 85,
      totalTimeSpent: 60,
    },
    {
      clientName: 'Epsilon Tech',
      caseName: 'CASE# 006',
      totalTasks: 10,
      completionRate: '50',
      plannedDates: '01/05/2024 - 31/05/2024',
      riskStatus: 'At Risk',
      statusClass: 'status at-risk bg pill',
      progress: 67,
      totalTimeSpent: 40,
    },
  ];

  ngOnInit() {
    this.applyFilter();
    this.taskStatusData = {
      labels: ['Completed', 'Pending'],
      datasets: [
        {
          data: [75, 45],
          backgroundColor: ['#42A5F550', '#FF638450'],
          hoverBackgroundColor: ['#64B5F650', '#FF638450'],
        },
      ],
    };

    //#region workload
    const dataValues = [5, 10, 15, 8, 5, 10, 15, 8, 5, 10, 15, 8];

    // Calculate min, max, and thresholds
    const minValue = Math.min(...dataValues);
    const maxValue = Math.max(...dataValues);
    const range = maxValue - minValue;

    // Define thresholds
    const lowThreshold = minValue + range * 0.33; // Low threshold (33rd percentile)
    const highThreshold = minValue + range * 0.67; // High threshold (67th percentile)

    // Create a backgroundColor array based on dynamic thresholds
    const backgroundColors = dataValues.map((value) => {
      if (value <= lowThreshold) return 'rgba(13, 202, 240, 0.4)'; // Low
      else if (value <= highThreshold)
        return 'rgba(75, 192, 192, 0.4)'; // Medium
      else return 'rgba(255, 99, 132, 0.4)'; // High
    });

    // Create a borderColor array based on dynamic thresholds (darker shades)
    const borderColors = dataValues.map((value) => {
      if (value <= lowThreshold)
        return 'rgba(13, 202, 240, 1)'; // Darker shade for Low
      else if (value <= highThreshold)
        return 'rgba(75, 192, 192, 1)'; // Darker shade for Medium
      else return 'rgba(255, 99, 132, 1)'; // Darker shade for High
    });

    this.workloadData = {
      labels: [
        'CASE #001',
        'CASE #002',
        'CASE #003',
        'CASE #004',
        'CASE #005',
        'CASE #006',
        'CASE #007',
        'CASE #008',
        'CASE #009',
        'CASE #0010',
        'CASE #0011',
        'CASE #0012',
      ],
      datasets: [
        {
          label: 'Resources',
          data: dataValues,
          backgroundColor: backgroundColors, // Use the created color array
          borderColor: borderColors, // Use the created border color array
          borderWidth: 1, // Set border width
        },
      ],
    };

    this.workloadChartOptions = {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
      },
      maintainAspectRatio: false,
      aspectRatio: 0.6,
    };

    //#endregion
    //#region timeSpent
    const timeSpentPerDay = [
      8, 48, 36, 45, 70, 44, 51, 49, 53, 61, 72, 68, 39, 27, 39, 43, 55, 66, 74,
      58, 49, 36, 51, 70, 39, 48, 54, 62, 75, 82, 59,
    ];
    const resourcesPerDay = [
      1, 2, 1, 3, 2, 1, 2, 3, 2, 4, 5, 3, 4, 2, 1, 3, 2, 4, 5, 3, 2, 1, 4, 5, 2,
      3, 4, 1, 2, 3, 4,
    ];

    // Labels for the days of the month
    const labels = Array.from(
      { length: timeSpentPerDay.length },
      (_, i) => `Day ${i + 1}`
    );

    this.timeSpentData = {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: 'Time Spent (hours)',
          data: timeSpentPerDay,
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          fill: true,
          tension: 0.4, // Adjust this value for smoothing
          yAxisID: 'y1',
        },
        {
          type: 'line',
          label: 'Number of Resources',
          data: resourcesPerDay,
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          borderDash: [5, 5],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          fill: false,
          tension: 0.4, // Adjust this value for smoothing
          yAxisID: 'y2',
        },
      ],
    };

    this.timeSpentChartOptions = {
      scales: {
        y1: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Time Spent (hours)',
          },
        },
        y2: {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'Number of Resources',
          },
          grid: {
            drawOnChartArea: false, // Only want the grid lines for one axis to show up
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
      },
      elements: {
        line: {
          tension: 0.4, // Smoothness for all lines
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
    };

    //#endregion


  }

  loadData(): Promise<OrganizationOverview> {
    return new Promise<OrganizationOverview>((resolve, reject) => {
      this.apiService.getOverview(
          this.selectedDate.getFullYear()
        )
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.overview = resp.data;
              this.isLoading = false;
              if (this.overview) {
                // You can add any additional logic here if needed
              }

              resolve(this.overview); // Resolve the promise when the operation is successful
            } else {
              // Handle cases where the response status is not 'success'
              reject(new Error('Failed to get totals: ' + resp.status));
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
            reject(error); // Reject the promise on error
          },
        });
    });
  }

  getProgressBarClass(progress) {
    if (progress <= 25) {
      return 'low';
    } else if (progress <= 50) {
      return 'medium';
    } else if (progress <= 90) {
      return 'high';
    } else {
      return 'full';
    }
  }

  loadMonthlyCostTrend(monthlyCost: any) {
    let canvas = this.renderer.createElement('canvas');
    const gradient = canvas
      .getContext('2d')
      .createLinearGradient(100, 0, 50, 150);
    gradient.addColorStop(0, 'rgba(0, 205, 255, 0.2)');
    gradient.addColorStop(1, 'rgba(0, 205, 255, 0)');

    const gradient2 = canvas
      .getContext('2d')
      .createLinearGradient(5, 0, 5, 100);
    gradient2.addColorStop(0, 'rgba(42, 33, 186, 0.2)');
    gradient2.addColorStop(1, 'rgba(42, 33, 186, 0)');

    this.monthlyCostData = {
      labels: monthlyCost.map(x => x.monthName),
      datasets: [
        {
          label: 'Actual Cost',
          data: monthlyCost.map(x => x.totalCost),
          fill: true,
          pointBorderWidth: 1,
          pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
          tension: 0.4,
          borderDash: [5, 5],
          borderWidth: 1.5,
          borderColor: 'rgba(0, 205, 255)',
          backgroundColor: gradient, //'rgba(0, 205, 255, 0.2)',
        },
        {
          label: 'Estimated Cost',
          data: monthlyCost.map(x => x.totalEstimatedCost),
          fill: true,
          pointBorderWidth: 1,
          pointRadius: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          pointHoverRadius: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
          tension: 0.4,
          borderWidth: 1.5,
          borderColor: 'rgb(198, 33, 176)',
          backgroundColor: gradient2, //'rgba(42, 33, 186, 0.2)',
        },
      ],
    };
    this.ChartOptions1 = {
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const value = tooltipItem.raw;
              return tooltipItem.dataset.label + ': Rs. ' + value.toLocaleString();
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            callback: function(value) {
              return 'Rs. ' + value.toLocaleString(); // Format y-axis labels with 'Rs.'
            }
          }
        }
      }
    };
  }

  loadEngagementData(engagementData: any) {
    this.chartData = {
      labels: engagementData.map(x => x.serviceCategory),
      datasets: [
        {
          label: 'Engagement Phases',
          data: engagementData.map(x => x.taskPercentage),
          backgroundColor: '#FF638450',
          borderColor: '#FF638450',
          borderWidth: 1
        },
        {
          label: 'Completion Rates',
          data: engagementData.map(x => x.completionRate),
          backgroundColor: '#66BB6A50',
          borderColor: '#66BB6A50',
          borderWidth: 1
        }
      ]
    }
  }
  chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            // Format tooltip text with percentage sign
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw + '%';
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            return value + '%'; // Format y-axis labels as percentages
          }
        }
      }
    }
  };
  workloadResource(workload:any){
    this.workloadData1 = {
      labels: workload.map(x => x.name),
      datasets: [
        {
          label: 'Workload',
          data: workload.map(x => this.getAbsoluteValue(x.workload)),
          backgroundColor: '#42A5F550',
        },
      ],
    };
  }
  //#region Cost Forcast

  getCostForcast() {
    // Assuming you have the following data:
    //totalCosts: number = 0 //- Current total costs
    //totalBudget: number = 0 //- Total budget
    if (this.overview?.organizationTotals?.avgStartDate && this.overview?.organizationTotals?.avgEndDate) {
    let projectStartDate = new Date(this.overview.organizationTotals.avgStartDate); //- Project start date
    let projectEndDate = new Date(this.overview.organizationTotals.avgEndDate); //- Project end date
    let currentDate = new Date(); //- Current date

    // Calculate the number of days elapsed and total project duration
    let daysElapsed =
      (currentDate.getTime() - projectStartDate.getTime()) / (1000 * 3600 * 24);
    let totalProjectDuration =
      (projectEndDate.getTime() - projectStartDate.getTime()) /
      (1000 * 3600 * 24);

    // Calculate the daily spending rate
    let dailySpendingRate = this.overview.organizationTotals.actualCost / daysElapsed;

    // Forecast the total cost by multiplying the daily spending rate by the total project duration
    this.costForecast = dailySpendingRate * totalProjectDuration;
    }
    else{
      this.costForecast = 0;
    }

  }

  getEstimatedCostToCompletion() {
    // Assuming you have the following data:
    // this.totalCosts: number - Current total costs
    // this.totalBudget: number - Total budget
    let remainingScope: number = 50; //- Estimated remaining scope of work (as a percentage)
    this.costToCompletion =
    this.overview?.organizationTotals?.estimatedCost ?? 0 +
    (this.overview?.organizationTotals?.estimatedCost ?? 0 * remainingScope) / 100;
  }
  get budgetUtilization(): number {
    const actualCost = this.overview?.organizationTotals?.actualCost ?? 0;
    const estimatedCost = this.overview?.organizationTotals?.estimatedCost ?? 0;

    if (estimatedCost === 0) {
      return 0; // Prevent division by zero
    }

    return (actualCost / estimatedCost) * 100;
  }
  getAbsoluteValue(value: number): number {
    return Math.abs(value);
  }
  //#endregion

  createNewTask() {
    const tsModalRef = this.dialogService.open(TaskSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'New Task',
      width: '60%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        task: new Task(),
      },
    });
    tsModalRef?.onClose.subscribe(() => {});
  }
  hasPermission(): boolean {
    if(this.authService.hasPermission('budget-costing'))
      {
        return true;
      }
      else{
        return false;
      }
  }
}
