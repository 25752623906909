<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-timer"></i> {{ "Task Activities" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Month:</div>
      <p-calendar [(ngModel)]="selectedDate" view="month" dateFormat="MM, yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'170px'" />
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>

  <div [@slide]="slideState" class="filter-container">
    <app-task-activity-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
  </div>

  <div style="height: calc(100vh - 235px); overflow-y: auto; padding-bottom: 2rem;">
    <div class="card-body">
      <div class="log-container"
        *ngIf="recentTaskActivitiesList && recentTaskActivitiesList.length > 0; else  !isLoading ? noData : loader">
        <hr class="hr activity-divider">
        <div *ngFor="let item of recentTaskActivitiesList" class="d-flex flex-column position-relative ps-3 pe-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="likers-group"><img [src]="item.profilePictureUrl" [alt]="item.userName" [title]="item.userName"
                role="button">
              <b>{{item.userName}}</b>
            </div>
            <span class="me-2">{{item?.activityDate |date:'medium'}}</span>
          </div>
          <div class="ml-1 mt-2" style="color: firebrick;"> <i class="fa-brands fa-slack mr-1"></i>
            {{!item?.activityType ? 'no-status': item?.activityType}}</div>
          <div class="ml-1">{{!item?.activityDescription ? 'no-data': item?.activityDescription}}</div>
          <hr class="hr activity-divider">
        </div>
      </div>
    </div>

  </div>
  <div class="pagination-container">
    <div class="d-flex align-items-center">
      <span class="mr-3">{{"Page" | translate}} {{ currentPage }} {{"of" | translate}} {{
        calculateTotalPages(totalCount, pageSize) }} {{"of" | translate}}
        {{totalCount}}</span>
      <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
          width: 68px;
          text-align: center;
          font-size: 12px;">
        <option class="dropdown-item d-flex align-items-center" value="10">10</option>
        <option class="dropdown-item d-flex align-items-center" value="20">20</option>
        <option class="dropdown-item d-flex align-items-center" value="50">50</option>
        <option class="dropdown-item d-flex align-items-center" value="100">100</option>
      </select>
    </div>



    <div style="max-width: 500px;">
      <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
        <i class="fa-sharp fa-solid fa-angles-left"></i></button>
      <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
        [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
      <ng-container *ngFor="let page of displayedPages">
        <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
          [class.active]="page === currentPage">
          {{ page }}
        </button>
      </ng-container>
      <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
        [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
          class="fa-sharp fa-solid fa-angle-right"></i></button>
      <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
        [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
          class="fa-sharp fa-solid fa-angles-right"></i></button>
    </div>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>
  <ng-template #noData>
    <app-nodatafound message="{{'No task activity has been performed yet.' | translate }}"
      height="calc(100vh - 285px)"></app-nodatafound>
  </ng-template>

</div>
