export class Leads
{

  id!: number;
  name!: string | null;
  createdBy!: string | null;
  concernPerson!: string | null;
  contactPerson!: string | null;
  statusId!: number;
  designation!: string | null;
  email!: string | null;
  dueDate!: Date;
  comments!: string | null;
  phoneNumber!: string | null;
  mobileNumber!: string | null;
  createdByUserId!: number;
  updatedByUserId!: number;
  dateCreated!: Date;
  dateUpdated!: Date;
  leadStatus!: string | null;
  status!: string | null;
  color!: string | null;
  userIds: number[] = [];
  assignees: string;
  assigneesArray: string[];
  firstAssignee: string;

  assignedUsersNames: string = '';
  userIdsString: string;

  updatedBy: string = '';
  userNames: string[] = [];
  organizationId!: number;
  projectId: number;
  rowNumber: number;
  totalRecords: number;
}


export class LeadsFilter
{
  id!: number | null;
  query!: string | null;
  statusId!: number;
  fromDate!: Date | null;
  toDate!: Date | null;
  userIds: number[] = [];
  projectId!: number;
  pageNumber!: number;
  pageSize!: number;
}

export class LeadsData
{
  totalCount!: number;
  leads: Leads[] = [];
}
