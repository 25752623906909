import { Component, Input } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientProfileComponent } from '../client-profile/client-profile.component';
import { Result } from 'src/app/Models/Result';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedService } from 'src/app/services/shared.service';
import { AddEventComponent } from '../../events/addevent/addevent.component';
import { GlobalVariables } from 'src/environments/environment';
import { ClientSetupComponent } from '../client-setup/client-setup.component';
import { Clients, ClientsFilter } from 'src/app/Models/Clients';
import { Country, States, City } from 'src/app/Models/Country';
import { TranslateService } from '@ngx-translate/core';
import { padZero } from 'src/app/utils/utils';

declare var swal: any;

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ClientListComponent {
  public statusTypeId: number = 2; // Incase of lead component
  slideState = 'right';
  public clientsFilter: ClientsFilter = new ClientsFilter();
  public client: Clients = new Clients();
  public clients: Clients[] = [];
  public totalCount: number = 0;
  public currentPage = 1;
  public pageSize = 50;
  public countriesList: Country[] = [];
  public statesList: States[] = [];
  public citiesList: City[] = [];
  public displayedPages: number[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public totalPages: number = 0;
  public pageHeading: string;
  //#region refrence storing variables for model
  public addLogModal: any;
  clientSetupHeader: string;
  clientType: string;
  //#endregion

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public sharedService: SharedService,
    private translate: TranslateService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      // Fetch data based on the new projectId
      GlobalVariables.selectedProjectId = projectId;
      this.loadData();
    });

    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.clientsFilter.pageNumber = this.currentPage;
    this.clientsFilter.pageSize = this.pageSize;
    this.getClients();
    this.sharedService.triggerChildLoaded();
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onClientTypeFilterChange(clientType: string) {
    this.clientsFilter.clientType = clientType;
    this.clientType = clientType;
    this.getClients();
  }

  public onSearchRequest(filters: ClientsFilter) {
    this.toggleFilter();
    this.clientsFilter = filters;
    this.currentPage = this.clientsFilter.pageNumber;
    this.getClients();
  }

  openEventAddEditView(leadId: number): void {
    const modalRef = this.dialogService.open(AddEventComponent, {
      modal: true,
      header: 'New Event',
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        mode: 'New',
        leadId: leadId,
      },
    });
  }

  public addNewClient(item: any) {
    this.clientSetupHeader = 'Client Setup';
    this.client = new Clients();

    if (item) {
      this.clientSetupHeader = 'Client Setup';
      this.client = item;
    }

    const modalRef = this.dialogService.open(ClientSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.clientSetupHeader,
      width: '60%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        client: this.client,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.client = new Clients();
      this.getClients();
    });
  }

  public viewClientProfile(item: Clients) {
    this.router.navigate(['/client'], {
      queryParams: { id: item.id }, //Cleint Id
    });
  }

  public getClients() {
    this.isLoading = true;
    this.clientsFilter.pageNumber = this.currentPage;
    this.clientsFilter.pageSize = this.pageSize;

    this.apiService.getClients(this.clientsFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.clients = [];
        if (resp.status === 'success') {
          this.clients = resp.data;

          if (this.clients?.length > 0)
            this.totalCount = this.clients[0]?.totalRecords;
          this.generateDisplayedPages();
          this.sharedService.triggerChildLoaded();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public deleteClientById(id: number) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this client?',
      accept: () => {
        this.apiService.deleteClientById(id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: resp.message,
                life: 3000,
              });
              this.loadData();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getClients();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.getClients();
  }

  getProgressBarClass(progress) {
    if (progress <= 25) {
      return 'progress-bar low';
    } else if (progress <= 50) {
      return 'progress-bar medium';
    } else if (progress <= 90) {
      return 'progress-bar high';
    } else {
      return 'progress-bar full';
    }
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  viewCase(id:number) {
    this.router.navigate(['/manage-case'], {
      queryParams: { clientId: id}, //Cleint Id
    });
  }
}
