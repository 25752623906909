import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../services/auth.service';

export interface Permission {
  access: string;
  action: 'hide' | 'disable' | 'preventClick';
}

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
  @Input('hasPermission') permission: Permission;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!this.authService.hasPermission(this.permission.access)) {
      this.applyOptions();
    }
  }

  private applyOptions() {
    switch (this.permission.action) {
      case 'hide':
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
        break;
      case 'disable':
        this.renderer.setAttribute(
          this.el.nativeElement,
          'title',
          "You're not allowed to perform this action"
        );
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        this.preventClick(this.el);
        break;
      case 'preventClick':
        this.preventClick(this.el);
        break;
      default:
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }

  private preventClick(el: any) {
    this.renderer.setAttribute(
      el.nativeElement,
      'title',
      "You're not allowed to perform this action"
    );
    this.renderer.listen(this.el.nativeElement, 'click', (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();
    }); // capture phase
    //this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none'); // Completely disable click events
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'not-allowed');
  }
}
