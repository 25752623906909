<div class="lead-view-container">
  <div class="modal-body pt-0 pb-0">
    <div class="row lead-view-content">
      <div class="col-3 lead-info-left-container">
        <div class="lead-info-left-container-child-container">
          <div class="d-flex justify-content-center align-items-center flex-column">
            <b style="font-size: 1rem;">{{lead?.name}}</b>
            <div class="badge"
              [style]="lead?.color ? 'background-color:'+ lead?.color :'background-color: rgb(11, 94, 215)' ">
              <span>{{!lead?.leadStatus ? 'no-status': lead?.leadStatus}}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-4">
            <span>Assigned To:
              <p-button label="{{lead.assigneesArray?.length > 1 ? 'Multiple' :
                lead.assigneesArray?.length === 0 ?
                noAssignee : lead.assignees?.split(';')[1]}}" badge="{{lead.assigneesArray?.length}}"
                [pTooltip]="tooltipContent" tooltipPosition="bottom" styleClass="lead-assignee-badge mr-2"></p-button>
              <ng-template #tooltipContent>
                <div *ngFor="let assignee of lead.assigneesArray" class="flex align-items-center">
                  <img [src]="assignee?.split(';')[2]" height="20" class="mr-2" />
                  <span style="font-size: 11px;">{{assignee?.split(';')[1]}}</span>
                </div>
              </ng-template>
              <ng-template #noAssignee>
                <b>Not Assigned Yet</b>
              </ng-template>
            </span>
          </div>
          <hr class="hr" />
          <div class="lead-info-left-scroll-area mt-2">
            <div class="lead-info-label">
              <i class="fa-solid fa-circle-info"></i>
              <b>About this Lead</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-regular fa-user"></i>
                <label>Contact Person</label>
              </div>
              <b style="text-transform: capitalize;">{{lead?.contactPerson}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-regular fa-user"></i>
                <label>Concerned Person</label>
              </div>
              <b style="text-transform: capitalize;">{{lead?.concernPerson}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-regular fa-envelope"></i>
                <label>Email Address</label>
              </div>
              <b style="color: #ff949e;">{{lead?.email}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-solid fa-phone"></i>
                <label>Phone</label>
              </div>
              <b>{{lead?.phoneNumber}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-solid fa-mobile-screen"></i>
                <label>Mobile</label>
              </div>
              <b>{{lead?.mobileNumber}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-regular fa-user"></i>
                <label>Created By</label>
              </div>
              <b style="text-transform: capitalize;">{{lead?.createdBy}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-regular fa-user"></i>
                <label>Updated By</label>
              </div>
              <b>{{lead?.updatedBy}}</b>
            </div>
            <div class="lead-info-field-container">
              <div class="lead-info-label">
                <i class="fa-regular fa-note-sticky"></i>
                <label>Note</label>
              </div>
              <span style="font-style: italic;">{{lead?.comments}}</span>
            </div>
          </div>
        </div>

      </div>
      <div class="col-7 lead-info-center-container">
        <div class="lead-info-center-container-header">
          <h4 style="color: white; text-align: center; margin: 0;">Remarks</h4>
        </div>
        <div style="height: calc(100vh - 320px); overflow-y: auto; padding-bottom: 2rem;">
          <div class="log-container" *ngIf="logs.length>0; else noData">
            <div *ngFor="let item of logs">
              <table cellpadding="0" cellspacing="0" style="width: 100%;">
                <tbody>
                  <tr>
                    <td style="font-weight: 600; width: 115px;">Updated By:
                    </td>
                    <td style="position: relative;">
                      <div class="d-flex justify-content-between align-items-center">
                        <b>{{item?.name}}</b>
                        <div class="d-flex align-items-center">
                          <span class="me-2">{{item?.dateCreated |date:'medium'}}</span>
                          <button class="action-button-tbl-sm" (click)="editLogById(item)">
                            <i class="fa-solid fa-pencil"></i>
                          </button>
                          <button class="action-button-tbl-sm" (click)="deleteLogById(item.id)">
                            <i class="fa-regular fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: 600;">Status:
                    </td>
                    <td>
                      <span>{{!item?.leadStatus ? 'no-status': item?.leadStatus}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: 600; vertical-align: super;">Remarks:
                    </td>
                    <td style="vertical-align: super;">{{!item?.details ? 'no-data': item?.details}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr class="hr activity-divider">
            </div>
          </div>
        </div>

        <div class="remark-editor-area">
          <textarea class="textarea" [(ngModel)]="remarks.details"></textarea>
          <div class="d-flex justify-content-between align-items-center">
            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="remarks.statusId" class="form-select"
              style="width: 200px;">
              <option *ngFor="let item of statusList" value="{{item.id}}">{{item.name}}
              </option>
            </select>
            <div class="d-flex justify-content-end align-items-center">
              <button type="button" class="btn btn-sm action-button-cancel mr-2" (click)="close()">Close</button>
              <button *ngIf="this.remarks.isEdit" type="button" class="btn btn-sm btn-success mr-2" (click)="resetLogForm()">Cancel</button>
              <button type="button" class="btn btn-primary btn-sm action-button" (click)="addRemarks()">{{this.remarks.isEdit?'Update Remarks':'Save Remarks'}}</button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-2 lead-info-right-container">
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>
    </div>
  </div>
</div>


<ng-template #noData>
  <app-noactivity message="No activity has been logged for this lead yet."></app-noactivity>
</ng-template>
