
export class ClientLocations {
    id!: number;
    clientId!: number;
    latitude!: number;
    longitude!: number;
    radius!: number;
    createdBy!: number;
    isActive!: boolean;
    locationName:string;   
  }