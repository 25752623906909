import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isSameDay, isSameMonth } from 'date-fns';
import {
  CalendarEvent,
  CalendarEventTitleFormatter,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { CookieService } from 'ngx-cookie-service';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventTitleFormatter } from 'src/app/provider/event-title-formatter.provider';
import { SharedService } from 'src/app/services/shared.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomCalendarEvent } from 'src/app/interfaces/CustomCalendarEvent';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  getCurrentMonthStartEndDates,
  isCurrentDay,
} from 'src/app/utils/utils';
import { Status } from 'src/app/Models/Status';
import { GlobalVariables } from 'src/environments/environment';
import { User } from 'src/app/Models/Users';
import 'src/app/extensions/DateExtensions';
import { TimeFilter } from 'src/app/Models/Task';
import { TimeEntriesComponent } from '../time-entries/time-entries.component';
import moment from 'moment';
import { HasPermissionDirective } from 'src/app/directives/permission.directive';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-monthly-calendar',
  templateUrl: './monthly-calendar.component.html',
  styleUrls: ['./monthly-calendar.component.css'],
  styles: [
    `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
  ],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: EventTitleFormatter,
    },
  ],
})
export class MonthlyCalendarComponent {
  @ViewChild('modalContent', { static: true }) modalContent:
    | TemplateRef<any>
    | undefined;

  usersList: User[] = [];

  timeFilters: TimeFilter = null;

  calenderView: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  excludeDays = []; //[0, 6];

  modalData:
    | {
        action: string;
        event: CalendarEvent;
      }
    | undefined;

  refresh = new Subject<void>();
  tasks: CustomCalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  statuses: Status[] = [];
  selectedAssignees: User;
  selectedUserId: number;

  constructor(
    public modalService: NgbModal,
    private apiService: ApiService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.applyFilter();
    });
  }

  ngOnInit() {
    this.getAllUsers();
    this.applyFilter();
  }

  applyFilter() {
    this.getData();
    //this.refresh.next();
  }

  public getData() {
    this.timeFilters = new TimeFilter();

    if (this.calenderView === CalendarView.Month) {
      //Month View

      const dates = getCurrentMonthStartEndDates(this.viewDate);

      this.timeFilters.fromDate = dates[0].toApiFormattedString();
      this.timeFilters.userId = this.selectedUserId;
      // this.eventFilters.toDate = dates[1].toApiFormattedString();
    }
    this.tasks = [];
    this.apiService.getMonthlyTaskDurationByUserId(this.timeFilters).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          //
          this.tasks = [];

          resp.data?.forEach((e) => {
            if (e.totalDailyHours >= 0 && e.totalDailyHours <= 10) {
              e.icon = '';
              e.iconColor = '#3c6f71';
            } else if (e.totalDailyHours > 10 && e.totalDailyHours <= 24) {
              e.icon = 'fa-regular fa-triangle-exclamation';
              e.iconColor = 'rgb(227 167 7)';
            } else if (e.totalDailyHours > 24) {
              e.icon = 'fa-regular fa-triangle-exclamation';
              e.iconColor = 'rgb(227 90 7)';
            }
            this.tasks.push({
              start: new Date(e.entryDate),
              end: new Date(e.entryDate),
              title: e.totalDailyHours,
              color: {
                primary: '#dbdbdb',
                secondary: e.bgColor,
                secondaryText: e.textColor,
              },
              cssClass:
                this.calenderView === CalendarView.Day ? 'dayView-cell' : '',
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
              dbValue: e,
            });
          });

          this.sharedService.triggerChildLoaded();
          //this.refresh.next();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  openTaskDetailView(action: string, tasks: any): void {
    const list = JSON.parse(tasks);
    const modalRef = this.dialogService.open(TimeEntriesComponent, {
      ariaLabelledBy: 'modal-basic-title',
      modal: true,
      header:
        list.length > 0
          ? 'Time Entries - ' +
            moment(list[0].startTime).format('dddd, MMMM Do YYYY')
          : '',
      showHeader: true,
      width: '80%',
      contentStyle: { overflow: 'auto', padding: '0 1rem 1rem 1rem' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        data: tasks,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
  }

  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          if(this.authService.hasPermission('time-tracking-user'))
          {
            this.usersList = [{ id: 0, name: 'All Users' }, ...resp.data];
            this.selectedAssignees = this.usersList[0];
            this.selectedUserId = this.selectedAssignees.id;
          }
          else{
            this.usersList = resp.data.filter(x => x.id === this.authService.currentUserValue.id);
            this.selectedAssignees = this.usersList[0];
            this.selectedUserId = this.selectedAssignees.id;
          }

        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  onUserSelect($event: any) {
    this.selectedUserId = $event.value.id;
  }

  dateChange() {
    this.activeDayIsOpen = false;
  }

  isCurrentDay(date: Date) {
    return isCurrentDay(date);
  }

  toggleEventDetails(event: any) {
    event.expanded = !event.expanded; // Toggle expanded state
  }
}
