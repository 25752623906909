import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/Models/Result';
import { Role } from 'src/app/Models/Role';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-role-setup',
  templateUrl: './role-setup.component.html',
  styleUrls: ['./role-setup.component.css']
})
export class RoleSetupComponent {
  role: Role = new Role();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService,
  ) {
    this.role = this.dialogConfig.data.role;
  }

  ngOnInit(): void {

  }

  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addOrUpdateRole(this.role).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });

            this.sharedService.triggerProjectUpdates();
            this.dialogRef.close();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public validateAndSetData() {
    if (!this.role.roleName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter role name',
        life: 3000,
      });
      return false;
    }
    this.role.active = true;
    return true;
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
