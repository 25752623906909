export class TaskCost {
  taskId: number;
  taskTitle: string;
  clientId: number;
  clientName: string;
  caseId: number;
  caseName: string;
  billableTypeId: number;
  billableType: string;
  isBillable: boolean;
  estimatedHours: number;
  totalHours: number;
  estimatedCost: number;
  totalCost: number;
  isLoadingBreakout: boolean;
  breakOut: TaskCostBreakout[] = [];
  taskWarnings: any[] = [];
  expanded: boolean = false;
}

export class TaskCostBreakout {
  taskId: number;
  taskTitle: string;
  clientId: number;
  clientName: string;
  caseId: number;
  caseName: string;
  billableTypeId: number;
  billableType: string;
  isBillable: boolean;
  estimatedHours: number;
  totalHours: number;
  estimatedCost: number;
  totalCost: number;
  userId: number;
  userName: string;
}

export class CostingFilters {
  organizationId: number;
  taskTitle!: string | '';
  clientName!: string | '';
  billableTypeId!: number | null;
  isBillable!: boolean | null;
  startDate?: Date;
  endDate?: Date;
  dataTypeId: number;
}
