import { Component, EventEmitter, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { HrmsPluginComponent } from 'src/app/plugins/hrms/hrms-plugin/hrms-plugin.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent {
  @Output() OnChildClicked = new EventEmitter<boolean>();
  constructor(private dialogService: DialogService) {}

  hide() {
    this.OnChildClicked.emit(true);
  }

  hrmsPluginSetup() {
    const tsModalRef = this.dialogService.open(HrmsPluginComponent, {
      modal: true,
      showHeader: true,
      header: 'Radiant Workforce HRMS Plugin',
      width: '40%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        //task: new Task(),
      },
    });
    tsModalRef?.onClose.subscribe(() => {});
  }

  downloadInstaller() {
    const installerUrl =
      'https://tms.radiantworkforce.com/downloads/tms-Agent-Setup.exe';
    const link = document.createElement('a');
    link.href = installerUrl;
    link.download = 'TMS_Agent_Setup.exe';
    link.click();
  }
}
