<div class="modal-body">
  <div class="pt-3">
    <div class="d-flex justify-content-between mb-3">
      <p class="m-0"><strong><i class="fas fa-calendar-alt mr-1"></i>Title:</strong> <span
          style="text-transform: capitalize;">
          {{event.title }}</span></p>
      <p class="m-0">
        <strong><i class="fas fa-clock mr-1"></i>Status:</strong>
        <span
          style="padding: 2px 5px;margin-left: 5px;border-radius: 4px; margin-left:5px; font-weight: 600;background-color: {{event.statusBgColor}}; color: {{event.statusColor}}">{{event.status}}</span>
      </p>
    </div>

    <div class="d-flex align-items-center mb-2"> <strong><i class="fas fa-user mr-1"></i>Organizer: </strong>
      <div class="d-flex align-items-center border border-opacity-25 border-blue rounded ml-1 p-1"
        style="background-color: #553c710f;">
        <img [src]="event?.organizer?.split(';')[2]" class="createdby-img" />
        <span>{{ event?.organizer?.split(';')[1] }}</span>
      </div>
    </div>

    <div class="d-flex align-items-center mb-2"> <strong><i class="fas fa-user mr-1"></i>Created By: </strong>
      <span class="ml-1">{{ event?.createdBy.split(';')[1] }}</span>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <p class="m-0"><strong><i class="fas fa-clock mr-1"></i>Starts At:</strong> {{event.startDateTime | date:'MMM d,
          y,h:mm a'
          }}</p>
      </div>
      <div class="form-group col">
        <p class="m-0"><strong><i class="fas fa-clock mr-1"></i>Ends At:</strong> {{event.endDateTime | date:'MMM d, y,
          h:mm a' }}
        </p>
      </div>
      <div class="form-group col">
        <p class="m-0"><strong><i class="far fa-clock mr-1"></i>Reminder Time:</strong> {{event.reminderTime | date:'MMM
          d, y, h:mm a'}}
        </p>
      </div>
    </div>
    <p><strong><i class="fas fa-id-badge mr-1"></i>Lead Name: </strong> <a class="lead-link"
        (click)="viewLead(selectedLead)">{{selectedLead?.name }}</a></p>

    <p><strong><i class="fas fa-map-marker-alt mr-1"></i>Location Name:</strong>
      {{event.locationName? event.locationName : ' --- ' }}</p>
    <p><strong><i class="fas fa-map-marker-alt mr-1"></i>Location Address:</strong>
      {{event.locationAddress?event.locationAddress:' - - - '}}</p>
    <p><strong><i class="fas fa-info-circle mr-1"></i>Description:</strong> {{event.description}}</p>

    <p><strong><i class="fas fa-info-circle mr-1"></i>Additional Information:</strong></p>
    <ng-container *ngIf="event.additionalFields">
      <div class="additionalField" *ngFor="let field of event.additionalFields; let i = index">
        <div *ngIf="field.isDeleted === false" class="form-row align-items-center">
          <div class="form-group col m-0">
            <strong style="text-transform: capitalize;">{{field.fieldName}}</strong> : <span>{{field.fieldValue}}</span>
          </div>
        </div>
      </div>
    </ng-container>

  </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">
    OK
  </button>
</div>
