<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-list-check"></i> {{ "Task List" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <button class="btn btn-primary action-button text-nowrap" [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }" (click)="addEditTask(null)"><i
          class="fa-light fa-plus"></i> {{ "New" | translate }}</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline ml-1 " (click)="toggleFilter()"><i
          class="fa-light fa-filter-list"></i>
        {{ "Apply Filters" | translate }}</button>
    </div>
  </div>


  <div [@slide]="slideState" class="filter-container">
    <app-task-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
  </div>

  <div>

    <div class="table-responsive" style="height: calc(100vh - 235px);"
      *ngIf="tasks != null && tasks.length > 0; else !isLoading ? noData : loader">
      <table class="table table-hover">
        <thead>
          <tr class="sticky-header">
            <th style="width: 20px; padding: 0.75rem;"> #</th>
            <th style="width: 300px;">{{"Task" | translate}}</th>
            <th>{{"Client Name" | translate}}</th>
            <th>{{"Status" | translate}}</th>
            <th>{{"Assignee" | translate}}</th>
            <th>{{"Priority" | translate}}</th>
            <th>{{"Due Date" | translate}}</th>
            <th>{{"Billable" | translate}}</th>
            <th>{{"Actions" | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tasks;let rowIndex = index">
            <td style="vertical-align: sub;">
              <div class="">{{ item.rowNumber }}</div>
            </td>
            <td class="taskname-td">
              <div class="d-flex align-items-center taskname-container">
                <div class="taskname m-0">
                  {{ padZero(item.id,3) + ' - ' + item.title }}
                  <p class="task-title-date m-0">Created At: {{ item.dateCreated | date }}</p>
                </div>
                <div class="taskname-btn-group">
                  <button
                    [disabled]="item.taskStatusId === 3 || !item.assigneeIds?.includes(currentUserId) || item.caseStatus === false"
                    [pTooltip]="item.taskStatusId === 3 ? 'Task is already completed and cannot be started again' :
                    !item.assigneeIds?.includes(currentUserId)  ? 'This task is not assigned to you, so you can\'t start it' :
                    item.caseStatus === false ? 'You cannot start this task because this engagement is closed' :
                    item.isStarted ? 'Stop Task' : 'Start Task'" class="btn-soft-light action-button-tbl-sm"
                    (click)="item.isStarted ? initiateTaskStop(item) : initiateTaskStart(item)">
                    <i [className]="item.isStarted ? 'fa-solid fa-stop text-danger' : 'fa-solid fa-play text-info'"
                      [ngClass]="{ 'text-danger': item.isStarted && item.taskStatusId !== 3,
                  'text-info': !item.isStarted && item.taskStatusId !== 3,
                  'text-gray': item.taskStatusId === 3 }"></i>
                  </button>
                  <button (click)="viewTask(item)"  pTooltip="{{'View Task' | translate}}"
                    class="btn-soft-light action-button-tbl-sm"><i class="fa-regular fa-eye"></i>
                  </button>
                  <button (click)="addEditTask(item)"  [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }" pTooltip="{{'Edit Task' | translate}}"
                    class="btn-soft-light action-button-tbl-sm"><i class="fa-solid fa-pencil"></i>
                  </button>
                </div>
              </div>
            </td>

            <td>
              <div class="ellipsis" style="max-width: 200px;font-weight: 500; text-transform: capitalize;">
                <p class="mb-1">{{ !item.clientName ? '-':item.clientName |translate }}</p>
              </div>
              <div class="ellipsis" style="color: #7d7b7b;text-transform: capitalize;background: #f7f7f7;">
                <p class="fs-11px mb-0">{{ !item.caseName ? '-' : item.caseName | translate }}</p>
              </div>
            </td>
            <td>
              <p class="status bg border badge rounded-pill mb-1" [ngClass]="getStatusClass(item)">
                <span>{{ item.status | translate }}</span>
              </p>
              <p class="fs-11px mb-0">Time Spent: <strong>{{ item.taskStatusId > 1 ? item.actualHours : 0 }} H</strong>
              </p>
            </td>
            <td>
              <div class="likers-group">
                <ng-container *ngFor="let item of item?.taskAssignee?.slice(0, 3) ;  let i = index">
                  <img [src]="item.ProfilePictureUrl" [alt]="item.Name" [title]="item.Name" role="button">
                </ng-container>
              </div>
            </td>

            <td>
              <div class="badge rounded-pill" [style.color]="item.priorityForeColor"
                [style.backgroundColor]="item.priorityBgColor">{{ item.priorityName | translate }}</div>
            </td>

            <!-- <td>
            <div class="ellipsis" style="max-width: 130px; font-weight: 500">{{ !item.description ? '-' :item.description | translate }}</div>
          </td> -->

            <td>
              <div class="ellipsis" style="max-width: 85px;font-weight: 500; text-transform: capitalize;">
                <p class="mb-0">{{ item.dueDate | date }}</p>
              </div>
              <span [ngSwitch]="getCompletionStatus(item)">
                <span *ngSwitchCase="'Overdue'" class="badge status overdue bg">Overdue</span>
                <span *ngSwitchCase="'On Track'" class="badge status on-track bg">On Track</span>
                <span *ngSwitchCase="'Completed Early'" class="badge status completed bg">Completed Early</span>
              </span>
            </td>
            <td>
              <div style="font-weight: 500;">{{ item.isBillable ? 'YES' : 'NO' }}
              </div>
            </td>
            <td>
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ "Options" | translate }}
                </button>
                <div class="dropdown-menu">
                  <button class="dropdown-item table-action-btn mb-2" (click)="viewTask(item)">
                    <i class="fa-regular fa-eye mr-1"></i>
                    {{"View Task Details" | translate}}</button>
                  <button class="dropdown-item table-action-btn mb-2"  [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }" (click)="addEditTask(item)">
                    <i class="fa-regular fa-pencil mr-1"></i>
                    {{"Edit Task Information" | translate}}</button>
                  <button class="dropdown-item table-action-btn mb-2"  [hasPermission]="{ access: 'deleteTasks', action: 'hide' }"  (click)="deleteTask(item.id,item.isStarted)"
                    title="Delete Client"><i class="fa-regular fa-trash"></i>
                    {{"Delete Task" | translate}}</button>
                  <button class="dropdown-item table-action-btn mb-2"  [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }"  (click)="addTaskToTemplate(item)"
                    title="Add Task To Template"><i class="fa-regular fa-plus"></i>
                    {{"Add Task To Template" | translate}}</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination DIV -->
    <div class="pagination-container">
      <div class="d-flex align-items-center">
        <span class="mr-3">{{"Page" | translate}} {{ currentPage }} {{"of" | translate}} {{
          calculateTotalPages(totalCount, pageSize) }} {{"of" | translate}}
          {{totalCount}}</span>
        <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
        width: 68px;
        text-align: center;
        font-size: 12px;">
          <option class="dropdown-item d-flex align-items-center" value="10">10</option>
          <option class="dropdown-item d-flex align-items-center" value="20">20</option>
          <option class="dropdown-item d-flex align-items-center" value="50">50</option>
          <option class="dropdown-item d-flex align-items-center" value="100">100</option>
        </select>
      </div>



      <div style="max-width: 500px;">
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
          <i class="fa-sharp fa-solid fa-angles-left"></i></button>
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
        <ng-container *ngFor="let page of displayedPages">
          <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
            [class.active]="page === currentPage">
            {{ page }}
          </button>
        </ng-container>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angle-right"></i></button>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angles-right"></i></button>
      </div>
    </div>
  </div>



  <ng-template #loader>
    <app-project-loading-indicator height="calc(100vh - 205px)" />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="{{'No task has been created yet.' | translate }}"
      height="calc(100vh - 205px)"></app-nodatafound>
  </ng-template>


</div>
