import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/Models/Result';
import { Role } from 'src/app/Models/Role';
import { SystemTemplates } from 'src/app/Models/SystemTemplates';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-tasks-template-setup',
  templateUrl: './tasks-template-setup.component.html',
  styleUrl: './tasks-template-setup.component.css'
})
export class TasksTemplateSetupComponent {
  taskTemplate: SystemTemplates = new SystemTemplates();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService,
  ) {
    this.taskTemplate = this.dialogConfig.data.taskTemplate;
  }

  ngOnInit(): void {

  }
  onSubmit(){
    if (this.validateAndSetData()) {
      this.apiService.addOrUpdateSystemTemplates(this.taskTemplate).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });

            this.dialogRef.close();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  
  }
  public validateAndSetData() {
    if (!this.taskTemplate.name) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter template name',
        life: 3000,
      });
      return false;
    }
    if (!this.taskTemplate.templateType) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter template description',
        life: 3000,
      });
      return false;
    }
    this.taskTemplate.active = true;
    this.taskTemplate.createdBy = this.authService.currentUserValue.id;
    return true;
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
