<div class="container">

  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-dollar" style="width: 30px;text-align: center;"></i> Cost Explorer</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Year:</div>
      <p-calendar [(ngModel)]="selectedDate" view="year" dateFormat="yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'110px'" />
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>
  <div class="row">

    <!-- Total Budget Card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body" style="height: 215px;">
          <h5 class="card-title">Total Budget</h5>
          <div class="row mt-4">
            <div class="col-md-7">
              <h2>Rs {{ totals.estimatedCost | number: '1.0-2' }}</h2>
              <p>{{ totals.estimatedCost - totals.actualCost | number: '1.0-2' }} Remaining</p>
              <p [ngClass]="{'text-danger': budgetUtilization > 0, 'text-success': budgetUtilization < 0}">
                Currently <span
                  [ngClass]="{'text-danger': budgetUtilization > 0, 'text-success': budgetUtilization < 0}">
                  {{ budgetUtilization > 0 ? '▲' : '▼' }} {{ budgetUtilization | number:'1.0-0' }}%</span>
                {{ budgetUtilization > 0 ? 'Over Target' : 'Under Target' }}
              </p>
            </div>
            <div class="col-md-5">
              <p-chart type="bar" [data]="budgetChartData" [options]="chartOptions" height="150"></p-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Actual Cost vs Estimated Cost Card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body" style="height: 215px;">
          <h5 class="card-title">Actual vs Estimated Cost</h5>
          <p class="card-text mt-3">
            Actual Cost: Rs {{ totals.actualCost | number: '1.0-2' }} <br>
            Estimated Cost: Rs {{ totals.estimatedCost | number: '1.0-2' }} <br>
            Variance: <span [class]="costVariance.includes('-') ? 'text-success fw-bold' : 'text-danger fw-bold'">{{
              costVariance
              }}%</span>
          </p>
          <p-chart type="bar" [data]="actualVsEstimatedCostData" [options]="horizontalChartOptions"></p-chart>
        </div>
      </div>
    </div>

    <!-- Estimated Hours vs Actual Hours Card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body" style="height: 215px;">
          <h5 class="card-title">Actual vs Estimated Hours</h5>
          <p class="card-text mt-3">
            Actual Hours: {{ totals?.actualHours?.toFixed(2) }} hours <br>
            Estimated Hours: {{ totals?.estimatedHours?.toFixed(2) }} hours <br>
            Variance: <span [class]="hoursVariance.includes('-') ? 'text-success fw-bold' : 'text-danger fw-bold'">{{
              hoursVariance }}%</span>
          </p>
          <p-chart type="bar" [data]="actualVsEstimatedHoursData" [options]="horizontalChartOptions"></p-chart>
        </div>
      </div>
    </div>

  </div>

  <!-- Donut Chart for Billable vs Non-Billable Hours -->
  <div class="row mt-4">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Billable vs Non-Billable Hours</h5>
          <p-chart type="doughnut" [data]="billableVsNonBillableHoursData"
            [options]="chartOptionsBillableVsNonBillableData" height="200"></p-chart>
          <div class="center-text-billable-vs-non-billable">
            <p class="mb-1">Total Hours</p>
            <p class="m-0">{{ totals?.actualHours?.toFixed(2) }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Monthly Actual vs Estimated Cost Chart -->
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Cost Trend Over Time</h5>
          <p-chart type="line" [data]="monthlyActualvsEstimatedCostData" [options]="chartOptionsCost"
            height="200"></p-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title mb-0">Top 5 Engagements</h5>
      <div class="table-responsive">
        <table class="table table-bordered" style="border: 1px solid #ccc">
          <thead>
            <tr>
              <th>Engagement Name</th>
              <th>Client Name</th>
              <th>Total Hours</th>
              <th>Total Cost</th>
              <th>Billable Hours</th>
              <th>Non Billable Hours</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of topCases">
              <td>{{ item.caseName }}</td>
              <td>{{ item.clientName }}</td>
              <td>{{ item.hoursSpent.toFixed(2) }}</td>
              <td>{{ item.totalCost |number: '1.0-2'}}</td>
              <td>{{ item.billableHours.toFixed(2)}}</td>
              <td>{{ item.nonBillableHours.toFixed(2)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
