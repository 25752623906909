import { Component } from '@angular/core';

@Component({
  selector: 'app-leads-analytics',
  templateUrl: './leads-analytics.component.html',
  styleUrls: ['./leads-analytics.component.css']
})
export class LeadsAnalyticsComponent {

}
