import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/Models/Result';
import { SystemTemplates, TasksTemplate } from 'src/app/Models/SystemTemplates';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-task-template-list',
  templateUrl: './task-template-list.component.html',
  styleUrl: './task-template-list.component.css'
})
export class TaskTemplateListComponent {
  public taskTemplate: TasksTemplate[] = [];
  systemTemp: SystemTemplates = new SystemTemplates();
  isSystemTemplate: boolean;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService,
  ) {
    this.taskTemplate = this.dialogConfig.data.taskTemplate;
    this.systemTemp = this.dialogConfig.data.systemTemp;
    this.isSystemTemplate = this.systemTemp.isSystemTemplate;
  }
  public modalClose() {
    this.dialogRef.close();
  }
  deleteTask(task){
    this.taskTemplate.splice(task,1);
  }
  saveSetting(){
    this.systemTemp.createdBy = this.authService.currentUserValue.id
    this.systemTemp.jsonTemplate = JSON.stringify(this.taskTemplate);
    this.apiService.updateTasksTemplate(this.systemTemp).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.modalClose();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: resp.message,
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
    console.log(this.systemTemp);
  }
}
