import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Result } from 'src/app/Models/Result';
import { Project, User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { SystemTemplates, TasksTemplate } from 'src/app/Models/SystemTemplates';
import { TasksTemplateSetupComponent } from '../tasks-template-setup/tasks-template-setup.component';
import { AddTasksToTemplateComponent } from '../add-tasks-to-template/add-tasks-to-template.component';
import { TaskTemplateListComponent } from '../task-template-list/task-template-list.component';
import { padZero, parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';

@Component({
  selector: 'app-manage-tasks-template',
  templateUrl: './manage-tasks-template.component.html',
  styleUrl: './manage-tasks-template.component.css'
})
export class ManageTasksTemplateComponent {
  taskTemplate: SystemTemplates[] =[];
  isLoading: boolean = true;
  systemTemplate: any = [];
  public taskTemplateList: TasksTemplate[] = [];
  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    public sharedService: SharedService,
    private confirmationService: ConfirmationService,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private authService: AuthService
  ) {
  }
  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loadTaskTemplate();
  }
  addNewTemplate(){
    const modalRef = this.dialogService.open(TasksTemplateSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'New Template',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        taskTemplate: new SystemTemplates(),
      },
    });
    modalRef.onClose.subscribe(() => {
      this.loadTaskTemplate();
      // this.getAllRoles();
    });
  }
  async loadTaskTemplate() {
    this.isLoading = true;
    // hrmsUsers.toggle(event);
    this.apiService.getSystemTemplates().subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          debugger;
          this.taskTemplate = resp.data;
          this.taskTemplate.forEach(x => {
            x.taskList = JSON.parse(x.jsonTemplate);
          });
          // this.selectedTemplate = this.systemTemplate[0];
          // this.taskAllList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
          // this.taskList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
          // this.taskList = this.taskAllList.filter(x=>x.categoryId === this.selectedServices.id);
          // this.taskList.forEach(task => {
          //   const category = this.serviceCategoryDbList.find(cat => cat.id === task.categoryId);
          //   if (category) {
          //     task.categoryName = category.categoryName;
          //   }
          //   const service = this.servicesDbList.find(cat => cat.id === task.serviceId);
          //   if (service) {
          //     task.serviceName = service.serviceName;
          //   }
          // });
          // if (this.selectedTemplate) {
          //   this.selectedTemplate.startDate = new Date();
          // }
          // if (this.selectedTemplate) {
          //   let currentDate = new Date();
          //   let nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
          //   this.selectedTemplate.endDate = nextMonthDate;
          // }
          console.log(this.systemTemplate);
          this.isLoading = false;
          // this.loadHrmsEmployees(this.selectedLocation.ID);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }
  bulkTasks(systemTemplate){
    

    const modalRef = this.dialogService.open(AddTasksToTemplateComponent, {
      modal: true,
      showHeader: true,
      header: 'Task To Template',
      width: '60%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        bulkTasks: systemTemplate,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.loadTaskTemplate();
    });
  }
  tasktemplateList(systemTemp) {
    this.taskTemplateList = parseJSONToLowerCaseFirstChar(systemTemp.jsonTemplate);
    const modalRef = this.dialogService.open(TaskTemplateListComponent, {
      modal: true,
      showHeader: true,
      header: 'Task Template',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        taskTemplate: this.taskTemplateList,
        systemTemp: systemTemp,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.loadTaskTemplate();
    });
  }
}
