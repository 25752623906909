import { Component } from '@angular/core';

@Component({
  selector: 'app-clients-budgets',
  templateUrl: './clients-budgets.component.html',
  styleUrls: ['./clients-budgets.component.css']
})
export class ClientsBudgetsComponent {

}
