import
  {
    trigger,
    state,
    style,
    transition,
    animate,
  } from '@angular/animations';
import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';
import { Clients, ClientsFilter } from 'src/app/Models/Clients';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { CaseFilters, ClientCase } from 'src/app/Models/Case';
import { ManageAssigneeComponent } from '../client-cases/manage-assignee/manage-assignee.component';
import { BillingSettingsSetupComponent } from '../client-cases/billing-settings-setup/billing-settings-setup.component';
@Component({
  selector: 'app-client-share-filters',
  templateUrl: './client-share-filters.component.html',
  styleUrls: ['./client-share-filters.component.css']
})
export class ClientShareFiltersComponent {
  @Input() clientId: number;
  @Input() type: string;
  @Output() caseSelected = new EventEmitter<object>();
  public clientsFilter: ClientsFilter = new ClientsFilter();
  public client: Clients = new Clients();
  selectedCase: ClientCase = null;
  cases: ClientCase[] = [];
  caseSetupHeader: string;
  clientInfo: any;
  completedSteps = {
    case: false,
    assignee: false,
    billing: false
  };
  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private renderer: Renderer2
  ) {
  }
  ngOnInit(): void {
    this.loadData();
  }
  viewCase() {
    this.router.navigate(['/manage-case'], {
      queryParams: { clientId: this.clientId }, //Cleint Id
    });
  }
  fileManagement() {
    this.router.navigate(['/document'], {
      queryParams: { clientId: this.clientId }, //Cleint Id
    });
  }
  loadData(){
    if (this.clientId > 0) {
      this.getCases(this.clientId).then((engs: ClientCase[]) => {
        this.cases = engs;
        this.getClientInfo(this.clientId).then((c) => {
          this.clientInfo = c;
          if (this.cases) {
            if(this.cases.find(
              (x) => x.active === true
            )){
              this.selectedCase = this.cases.find(
                (x) => x.active === true
              );
            }
            else{
              this.selectedCase = this.cases[0];
            }
            this.caseSelected.emit(this.selectedCase);
          }
          //Code Modification doneby - hira
          // this.isLoading = false;
        });
      });
    }

  }
  getCases(clientId: number): Promise<ClientCase[]> {
    return new Promise((resolve, reject) => {
      const filters: CaseFilters = new CaseFilters();
      filters.clientId = clientId;

      this.apiService.getCases(filters).subscribe({
        next: (resp: Result) => {
          this.cases = [];
          debugger;
          if (resp.status === 'success') {
            resolve(resp.data);
          } else {
            resolve(null);
          }
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }
  getClientInfo(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.getClientById(id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            resolve(resp.data);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
            resolve(null);
          }
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }
  manageAssignee(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Manage Assignee';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(ManageAssigneeComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe((result: any) => {
      if (result && result.success) {
      this.completedSteps.assignee = true; // Mark step as completed
      }
      this.loadData();
    });
  }
  addBillingSettings(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Billing Settings';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(BillingSettingsSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe((result: any) => {
      if (result && result.success) {
      this.completedSteps.billing = true; // Mark step as completed
      }
      this.loadData();
    });
  }
  onCaseSelect($event: any) {
    this.caseSelected.emit($event.value);
  }
  viewClientLocation(){
    this.router.navigate(['/manage-client-location'], {
      queryParams: { clientId: this.clientId }, //Cleint Id
    });
  }
}
