<div class="modal-body">
  <form class="forms-sampme pt-3">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Lead Name:</label>
          <!-- <input type="text" class="form-control" name="name" [(ngModel)]="leads.name"> -->

          <input type="text" class="form-control" ngbInput [(ngModel)]="leads.name" name="name"
            [resultFormatter]="leadFormatter_search" [inputFormatter]="leadFormatter_search"
            [ngbTypeahead]="searchLead">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="usersselect">Assigned To:</label>
          <div>
            <p-multiSelect name="usersselect" [options]="usersList" [(ngModel)]="selectedUers"
              placeholder="Select Assignees" optionLabel="name" display="chip">
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" class="form-control" name="email" [(ngModel)]="leads.email">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="concernName">Contact Person Name:</label>
          <input type="text" class="form-control" name="concernName" [(ngModel)]="leads.contactPerson">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="contactName">Concern Person Name:</label>
          <input type="text" class="form-control" name="contactName" [(ngModel)]="leads.concernPerson">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="statusselect">Status:</label>
          <select name="statusselect" [(ngModel)]="leads.statusId" class="form-control custom-select">
            <option *ngFor="let item of leadStatusList" value="{{item.id}}">{{item.name}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="mobileNumber">Mobile Number:</label>
          <input class="form-control" [maxlength]="100" pattern="[0-9]{4}-[0-9]{7}" placeholder="0000-0000000"
            name="mobileNumber" [(ngModel)]="leads.mobileNumber">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="phNumber">Phone:</label>
          <input pattern="[0-9]{4}-[0-9]{7}" [maxlength]="100" placeholder="0000-0000000" class="form-control"
            name="phNumber" [(ngModel)]="leads.phoneNumber">
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="comments">Comments:</label>
      <textarea class="form-control mb-2" rows="3" placeholder="Notes" name="comments"
        [(ngModel)]="leads.comments"></textarea>
    </div>

    <div class="text-right">
      <button type="button" (click)="onSubmit()" [disabled]="this.isDataSaving"
        class="border-0 btn btn-primary btn-sm action-button" style="width: 80px;">Save</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-sm action-button-cancel" style="width: 80px;" (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>
