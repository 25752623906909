import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { padZero, parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { Team } from 'src/app/Models/Teams';
import { TeamSetupComponent } from '../team-setup/team-setup.component';
import { JobTitle } from 'src/app/Models/JobTitle';

@Component({
  selector: 'app-manage-teams',
  templateUrl: './manage-teams.component.html',
  styleUrls: ['./manage-teams.component.css'],
})
export class ManageTeamsComponent {
  public teams: Team[] = [];
  public filteredTeams: Team[] = [];
  newTeam: Team;
  jobTitles: JobTitle[] = [];

  searchKeyword: string = '';
  isLoading: boolean = true;

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    public sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    // this.sharedService.onProjectChange.subscribe((projectId) => {
    //   GlobalVariables.selectedProjectId = projectId;
    //   this.getAllTeams();
    // });
  }

  ngOnInit(): void {
    this.newTeam = new Team();
    this.getAllTeams();
    this.getJobTitles();
  }

  getAllTeams() {
    this.apiService.getAllTeams().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.teams = resp.data;
          this.filteredTeams = this.teams;

          this.filteredTeams.forEach((team) => {
            if (team.membersJson) {
              team.members = parseJSONToLowerCaseFirstChar(team.membersJson);
            }
          });
        }

        this.isLoading = false;
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
        this.isLoading = false;
      },
    });
  }

  searchTeams(): void {
    // Filter teams based on the searchKeyword
    this.filteredTeams = this.teams.filter((team) => {
      // Check if team name or description matches the searchKeyword
      const teamMatches =
        team.name?.toLowerCase().includes(this.searchKeyword.toLowerCase()) ||
        team.description
          ?.toLowerCase()
          .includes(this.searchKeyword.toLowerCase());

      // Check if any member's name or email matches the searchKeyword
      const membersMatch = team.members.some(
        (member) =>
          member.name?.toLowerCase().includes(this.searchKeyword.toLowerCase()) ||
          member.emailAddress?.toLowerCase().includes(this.searchKeyword.toLowerCase()) ||
          member.jobTitle?.toLowerCase().includes(this.searchKeyword.toLowerCase())
      );

      // Return true if either the team matches or any member matches
      return teamMatches || membersMatch;
    });
  }

  addNewTeam() {
    if (!this.checkPermission()) {
      return;
    }

    const modalRef = this.dialogService.open(TeamSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Add New Team',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        team: new Team(),
        jobTitles: this.jobTitles,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllTeams();
    });
  }

  editTeam(team: Team) {
    if (this.authService.currentUserValue.id !== team.id) {
      if (!this.checkPermission()) {
        return;
      }
    }

    const modalRef = this.dialogService.open(TeamSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Update Team',
      width: '40%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        team: team,
        jobTitles: this.jobTitles,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllTeams();
    });
  }

  deleteTeam(team: Team) {
    if (!this.checkPermission()) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + team.name + '?',
      header: 'Confirmation',
      icon: 'fa-regular fa-circle-info',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.apiService.deleteTeam(team.id).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.getAllTeams();
              this.messageService.add({
                severity: 'success',
                summary: 'User Deleted',
                detail: resp.message,
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warnig',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            this.messageService.add({
              severity: 'warn',
              summary: 'Warnig',
              detail: 'Something went wrong',
              life: 3000,
            });
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

  getJobTitles() {
    this.apiService.getJobTitles().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.jobTitles = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }

  checkPermission() {
    if (this.authService.currentUserValue.userTypeId > 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warnig',
        detail: 'You are not allowed to perform this action',
        life: 3000,
      });

      return false;
    }
    return true;
  }
}
