<div class="modal fade" id="attendanceConfirmationModal" tabindex="-1" role="dialog"
  aria-labelledby="attendanceConfirmationModalLabel" aria-hidden="true" style="z-index: 150000 !important;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="attendanceConfirmationModalLabel">
          <i class="fas fa-calendar-check"></i> Attendance Confirmation
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img src="https://cdn-icons-png.flaticon.com/512/2766/2766216.png" alt="Attendance Icon" class="mb-3">
        <p class="lead">{{ message }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClose()">No, skip
          attendance</button>
        <button type="button" class="btn btn-danger" (click)="confirmMarkAttendance()">Yes, {{attendanceType}}</button>
      </div>
      <!-- <app-toast (retry)="requestLocation()" #toast></app-toast> -->
    </div>
  </div>
</div>
