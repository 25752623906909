import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { ClientBudgetLastSetting, Clients } from 'src/app/Models/Clients';
import {
  BillableType,
  BudgetBillingType,
  CaseBillingSettings,
  ClientCase,
  CaseService,
} from 'src/app/Models/Case';
import { Result } from 'src/app/Models/Result';
import {
  Services,
  ServiceCategories,
  ServicesList,
} from 'src/app/Models/Services';
import { ApiService } from 'src/app/services/api.service';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';

@Component({
  selector: 'app-billing-settings-setup',
  templateUrl: './billing-settings-setup.component.html',
  styleUrls: ['./billing-settings-setup.component.css'],
})
export class BillingSettingsSetupComponent {
  caseId: number = 0;
  billableType: BillableType[] = [];
  clientInfo: Clients = new Clients();
  caseBillingSettings: CaseBillingSettings = new CaseBillingSettings();
  selectedBillingType: BillableType = new BillableType();
  selectedBillingTypeId: number = 0;
  ratesPerHour: number = 0;
  isHours: boolean = false;
  formula: string;
  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {
    this.caseId = this.dialogConfig.data.case;
    this.clientInfo = this.dialogConfig.data.clientInfo;
    this.getBillableType();
  }
  getBillableType() {
    this.apiService.getBillableType(this.caseId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.billableType = resp.data;
          var billableType = this.billableType.filter(
            (x) => x.isChecked === true
          );
          if (billableType.length > 0) {
            this.onSelectionChange(billableType[0]);
          } else {
            this.onSelectionChange(
              this.billableType.filter((x) => x.id === 4)[0]
            );
          }
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  onSelectionChange(value: any) {
    this.selectedBillingType = value;
    this.selectedBillingTypeId = value.id;
    this.formula = value.formula;
    this.isHours = false;
    if (value.id === 3) {
      this.isHours = true;
      this.ratesPerHour = value.ratesPerHour;
    }
    else{
      this.ratesPerHour = 0;
    }
  }
  onSubmit() {
    this.caseBillingSettings.clientId = this.clientInfo.id;
    this.caseBillingSettings.caseId = this.caseId;
    if (this.selectedBillingTypeId === 3) {
      this.caseBillingSettings.ratesPerHour = this.selectedBillingType.ratesPerHour;
    } else {
      this.caseBillingSettings.ratesPerHour = 0;
    }
    this.caseBillingSettings.billableTypeId = this.selectedBillingTypeId;
    this.apiService
      .addUpdateCaseBillingSettings(this.caseBillingSettings)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close({ success: true });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
  }
  closeModal(): void {
    this.dialogRef.close({ success: false });
  }
}
