export class ClientDocuments {
    id!: number;
    dateCreated!: Date;
    documentName!: string;
    description!: string;
    subCategoryId!: number;
    fileLocation!: string;
    clientId!: number;
    caseId!: number;
    documentStatusId!: number;
    active?: boolean | null;
    createdByUserId!: number;
    rowNumber: number;
    totalRecords: number;
    fileSize:number;
    fileExt:string;
  }
  export class ClientsFilterDocuments
{
  clientId!: number | null;
  caseId!: number | null;
  subCategoryId!: number | null;
  pageNumber!: number;
  pageSize!: number;
}
export class ClientDocumentsCounts
{
  mainCategoryId: number;
  mainCategoryName: string;
  subCategoriesWithCount: string;
  hasDocuments: boolean;
}
