import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[required]',
  providers: [NgModel]
})
export class RequiredDirective implements OnInit {
  @Input() requiredField: boolean = true; // To enable/disable the behavior

  private errorMessageElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngModel: NgModel
  ) {
    this.addAsteriskToLabel();
    this.createErrorMessageElement();
  }

  ngOnInit() {
    if (this.ngModel) {
      // Check if the field is required
      this.ngModel.valueChanges?.subscribe(() => {
        this.validate();
      });
    }
  }

  private validate() {
    const inputElement = this.el.nativeElement;
    const errors: any = {};

    if (this.requiredField || inputElement.hasAttribute('required')) {

      this.checkFieldValidity();
      this.ngModel.statusChanges?.subscribe(() => {
        this.checkFieldValidity();
      });
    }
    this.ngModel.control?.setErrors(Object.keys(errors).length ? errors : null);
  }

  // Add asterisk to nearest parent label
  private addAsteriskToLabel() {
    const label = this.el?.nativeElement?.closest('.form-group')?.querySelector('label');
    if (label && !label.innerHTML.includes('*')) {
      const asterisk = this.renderer.createElement('span');
      asterisk.innerHTML = ' *';
      this.renderer.addClass(asterisk, 'text-danger');
      this.renderer.appendChild(label, asterisk);
    }
  }

  // Check if the field is invalid and display the error message
  private checkFieldValidity() {

    let fieldValue = this.el.nativeElement.value;
    if (fieldValue === undefined) {
      console.log(this.el.nativeElement.outerHTML.search('ng-reflect-model'));
      if(this.el.nativeElement.outerHTML.search('ng-reflect-model')===-1)
        fieldValue = false;
      else
        fieldValue = true;
      //fieldValue = this.el.nativeElement.querySelector('p-dropdown').ngModel;
    }
    console.log(fieldValue);
    if (!fieldValue) {
      this.renderer.setStyle(this.errorMessageElement, 'display', 'table');
    } else {
      this.renderer.setStyle(this.errorMessageElement, 'display', 'none');
    }
  }

  // Create error message element below the input field
  private createErrorMessageElement() {
    this.errorMessageElement = this.el.nativeElement.nextElementSibling;
    if (!this.errorMessageElement || !this.errorMessageElement.classList.contains('error-message')) {
      this.errorMessageElement = this.renderer.createElement('span');
      const errorMessageText = this.renderer.createText('*Field is required');
      this.renderer.addClass(this.errorMessageElement, 'validation-error');
      this.renderer.addClass(this.errorMessageElement, 'text-danger');
      this.renderer.setStyle(this.errorMessageElement, 'display', 'none');
      this.renderer.appendChild(this.errorMessageElement, errorMessageText);
      this.renderer.insertBefore(this.el.nativeElement.parentNode, this.errorMessageElement, this.el.nativeElement.nextSibling);
    }
    return this.errorMessageElement;
  }

  @HostListener('blur') onBlur() {
    this.validate();
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event: FocusEvent): void {
    this.checkFieldValidity(); // Ensure field validity on focus in
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent): void {
    this.validate();
  }
}
