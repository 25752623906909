export interface Status {
    id: number;
    name: string | null;
    color: string | null;
    bgColor: string | null;
    isActive: boolean;
}
export interface LeadStatus {
  id: number;
  name: string | null;
  color: string | null;
  bgColor: string | null;
  isActive: boolean;
}
export interface DealStatus {
  id: number;
  name: string | null;
  color: string | null;
  bgColor: string | null;
  description: string | null;
  dateCreated: Date |null;
  isActive: boolean;
}

export enum StatusTypeId {
  system = 1,
  leads = 2,
  deals = 3,
  events = 4,
}

