<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-sitemap"></i> {{ "Teams" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <span class="me-2 fw-600">Search:</span>
        <input type="text" class="form-control" placeholder="type a keyword to search" [(ngModel)]="searchKeyword"
          (input)="searchTeams()">
      </div>

      <button [hasPermission]="{ access: 'addUpdateTasks', action: 'disable' }"
        class="btn btn-primary action-button text-nowrap ms-5" (click)="addNewTeam()"><i class="fa-light fa-plus"></i>
        {{ "Add New Team" | translate }}</button>
    </div>
  </div>

  <ng-container *ngIf="filteredTeams != null && filteredTeams.length > 0; else isLoading ? loader : noData">
    <div *ngFor="let team of filteredTeams" class="team-section">
      <h5 class="team-name" [title]="team.description"><i class="fa-regular fa-users me-2 fs-16px"></i>{{ team.name }}<button
          [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }" (click)="editTeam(team)"
          class="btn btn-sm btn-outline-dark action-button-outline team-action-btn ml-2" style="padding: 8px 8px;"><i
            class="fa-light fa-pencil"></i></button>
        <button [hasPermission]="{ access: 'deleteUser', action: 'hide' }" (click)="deleteTeam(team)"
          class="btn btn-sm btn-outline-dark action-button-outline team-action-btn ml-0" style="padding: 8px 8px;"><i
            class="fa-light fa-trash"></i></button>
      </h5>
      <p class="team-desc">{{team.description}}</p>
      <div class="team-member-container">
        <div *ngFor="let member of team.members" class="team-member">

          <div class="d-flex align-items-center">
            <img src="{{ member.profilePictureUrl }}" alt="{{ member.name }}" class="profile-pic">
            <div>
              <h5 class="mb-1 fw-600">{{ member.name }}</h5>
              <p class="mb-2 badge status inactive bg fs-10px">{{ member.jobTitle }}</p>
            </div>
          </div>
          <p class="mb-1"><i class="fa-regular fa-envelope me-2"></i><a class="text-decoration-none"
              href="mailto:{{ !member.emailAddress ? 'N/A' : member.emailAddress}}">{{ member.emailAddress }}</a></p>
          <p class="mb-1"><i class="fa-regular fa-phone me-2"></i>{{ !member.phoneNo ? 'N/A' : member.phoneNo }}</p>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- <div class="table-responsive" style="height: calc(100vh - 200px);"
    *ngIf="filteredTeams != null && filteredTeams.length > 0; else !isLoading ? noData : loader">
    <table class="table table-hover">
      <thead>
        <tr class="sticky-header">
        <tr>
          <th scope="col">Team Info</th>
          <th scope="col">Description</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let team of teams; let i = index">
          <td class="d-flex align-items-center position-relative">
            {{team.name}}
          </td>
          <td><b>{{ team.description }}</b></td>
          <td>
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ "Options" | translate }}
              </button>
              <div class="dropdown-menu">
                <button class="dropdown-item table-action-btn mb-2"
                  [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }" (click)="editTeam(team)">
                  <i class="fa-regular fa-pencil mr-1"></i>
                  {{"Edit Team" | translate}}</button>
                <button class="dropdown-item table-action-btn"
                  [hasPermission]="{ access: 'deleteUser', action: 'hide' }" (click)="deleteTeam(team)">
                  <i class="fa-regular fa-trash mr-1"></i>
                  {{"Delete Team" | translate}}</button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->

  <ng-template #loader>
    <app-project-loading-indicator height="calc(100vh - 205px)" />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="{{'No team created yet.' | translate }}" height="calc(100vh - 205px)"></app-nodatafound>
  </ng-template>

</div>
