import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BulkTaskTemplate } from 'src/app/Models/BulkTaskTemplate';
import { CaseService, ClientCase } from 'src/app/Models/Case';
import { Clients } from 'src/app/Models/Clients';
import { HrmsEmployee } from 'src/app/Models/HrmsEmployee';
import { HrmsPluginSettings } from 'src/app/Models/HrmsPluginSettings';
import { INotificationThreshold, NotificationThreshold } from 'src/app/Models/NotificationThreshold';
import { Result } from 'src/app/Models/Result';
import { ServiceCategories, Services, ServicesList } from 'src/app/Models/Services';
import { Task } from 'src/app/Models/Task';
import { Project, User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';
@Component({
  selector: 'app-tasks-template',
  templateUrl: './tasks-template.component.html',
  styleUrls: ['./tasks-template.component.css']
})
export class TasksTemplateComponent {
  isProcessing: boolean = false;
  isLoading: boolean = true;
  selectAll: boolean = false;
  thresholds: INotificationThreshold[];
  case: ClientCase = new ClientCase();
  clientInfo: Clients = new Clients();
  tempServicesList: ServicesList[] = [];
  serviceCategoryDbList: ServiceCategories[] = [];
  servicesDbList: Services[] = [];
  systemTemplate: any = [];
  selectedTemplate: any = null;
  selectedServices: any = null;
  taskList: Task[] = [];
  taskAllList: Task[] = [];
  bulkTaskTemplate:BulkTaskTemplate = new BulkTaskTemplate();
  message:string = null;
  collapseStates: { [id: number]: boolean } = {};
i: number;



  constructor(
    private dialogRef: DynamicDialogRef,
    private apiService: ApiService,
    private messageService: MessageService,
    private authService: AuthService,
    public dialogConfig: DynamicDialogConfig,
    private confirmationService: ConfirmationService
  ) {
    this.thresholds = new NotificationThreshold().GetThresholds();

    this.case = this.dialogConfig.data.case;
this.message = '';
    this.clientInfo = this.dialogConfig.data.clientInfo;
    if (!this.case.services || this.case.services?.length === 0) {
      this.case.services = [];
      this.addService();
      this.tempServicesList = [];
    }
    if (this.selectedTemplate) {
      this.selectedTemplate.startDate = new Date(this.selectedTemplate.startDate);
    }
    if (this.selectedTemplate) {
      this.selectedTemplate.endDate = this.selectedTemplate.endDate.setDate(this.selectedTemplate.endDate.getDate() + 2);
    }
    this.getServicesCategory();
    this.getServices();
  }

  ngOnInit() {
    this.getServicesCategory();
    this.getServices();
    this.selectedTemplate = [];
    if (this.selectedTemplate) {
      this.selectedTemplate.startDate = new Date();
    }
    if (this.selectedTemplate) {
      let currentDate = new Date();
      this.selectedTemplate.endDate = currentDate.getDate() + 1;
    }
  }
  ngAfterViewInit(): void {
    this.loadTaskTemplate();
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
  addService() {
    if (this.case.services.length > 0) {
      this.case.services.push(new CaseService());
    } else {
      this.case.services = [];
      this.case.services.push(new CaseService());
    }
  }
  public getServicesCategory() {
    this.apiService.getServicesCategory().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.serviceCategoryDbList = resp.data;
          this.selectedServices = this.serviceCategoryDbList[0];
        } else {
          this.serviceCategoryDbList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  public getServices(): void {
    this.apiService.getServices().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.servicesDbList = resp.data;

          this.tempServicesList = this.servicesDbList.map(() => {
            const serviceList = new ServicesList();
            serviceList.servicesList = [...this.servicesDbList];
            return serviceList;
          });
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  async loadTaskTemplate() {
    debugger;
    this.isLoading = true;
    // hrmsUsers.toggle(event);
    this.apiService.getSystemTemplates().subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          debugger;
          this.systemTemplate = resp.data;
          this.selectedTemplate = this.systemTemplate[0];
          this.taskAllList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
          this.taskList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
          this.taskList = this.taskAllList.filter(x=>x.categoryId === this.selectedServices.id);
          this.collapseStates = Array(this.taskList.length).fill(true);
          this.taskList.forEach(task => {
            const category = this.serviceCategoryDbList.find(cat => cat.id === task.categoryId);
            if (category) {
              task.categoryName = category.categoryName;
            }
            const service = this.servicesDbList.find(cat => cat.id === task.serviceId);
            if (service) {
              task.serviceName = service.serviceName;
            }
          });
          if (this.selectedTemplate) {
            this.selectedTemplate.startDate = new Date();
          }
          if (this.selectedTemplate) {
            let currentDate = new Date();
            let nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
            this.selectedTemplate.endDate = nextMonthDate;
          }
          console.log(this.systemTemplate);
          this.isLoading = false;
          // this.loadHrmsEmployees(this.selectedLocation.ID);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }






  toggleTaskOptions() {
    if (!this.selectedTemplate.isCreatedInBulk) {
      this.selectedTemplate.isCreatedInBulk = false;
    }
    console.log(
      'Allow Mark Attendance from Task Track:',
      this.selectedTemplate.isCreatedInBulk
    );
  }



  toggleAllCheckboxes() {
    this.taskList.forEach((user) => {
      if (this.isServiceInCase(user.serviceId)) {
        user.selected = this.selectAll;
      }
      if (!this.isServiceInCase(user.serviceId)) {
        this.message = "You cannot add some tasks to this service because it is not associated with your engagement.";
      }
    });
  }

  updateSelectAllCheckbox() {
    this.selectAll = this.taskList.every((user) => user.selected);
  }
  filterByTemplate() {
    if (this.selectedTemplate) {
      this.taskAllList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
      this.taskAllList.forEach(task => {
        const category = this.serviceCategoryDbList.find(cat => cat.id === task.categoryId);
        if (category) {
          task.categoryName = category.categoryName;
        }
        const service = this.servicesDbList.find(cat => cat.id === task.serviceId);
            if (service) {
              task.serviceName = service.serviceName;
            }
      });
      this.taskList = this.taskAllList.filter(x=>x.categoryId === this.selectedServices.id);
    }
    this.updateSelectAllCheckbox();
  }
  filterByService(){
    var totalTaskList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
    this.taskList = totalTaskList.filter(x=>x.categoryId === this.selectedServices.id);
    this.taskList.forEach(task => {
      const category = this.serviceCategoryDbList.find(cat => cat.id === task.categoryId);
      if (category) {
        task.categoryName = category.categoryName;
      }
      const service = this.servicesDbList.find(cat => cat.id === task.serviceId);
            if (service) {
              task.serviceName = service.serviceName;
            }
    });
  }
  // filterByLocation() {
  //   if (this.selectedLocation) {
  //     this.loadHrmsEmployees(this.selectedLocation.ID);
  //   } else {
  //     this.filteredEmployees = this.hrmsEmployees;
  //   }
  //   this.updateSelectAllCheckbox();
  // }


  saveSetting(isActivating: boolean = false) {
    const tasksDataValid = this.getTasksData();

  // Stop execution if tasksDataValid is false
  if (!tasksDataValid) {
    this.isProcessing = false;
    return;
  }
    this.apiService.addBulkTasks(this.bulkTaskTemplate).subscribe({
      next: (resp: any) => {
        this.isProcessing = false;
        this.modalClose();
        if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Bulk tasks created successfully.',
              detail: resp.message,
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error in creating bulk tasks.',
              detail: resp.message,
              life: 3000,
            });
          }

      },
      error: (error) => {
        if (!isActivating) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An unknown error occurred',
            life: 3000,
          });
        }
      },
    });
  }

  modalClose() {
    this.dialogRef.close();
  }
  getTasksData(){
    this.selectedTemplate.taskList = this.taskList.filter(x=> x.selected === true);
    if (!this.selectedTemplate.taskList || this.selectedTemplate.taskList.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select the tasks from the list.',
        life: 3000,
      });
      return false;
    }
    // if(!this.selectedTemplate.isCreatedInBulk){
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'Warning',
    //     detail: 'Please allow the tasks to be created in bulk.',
    //     life: 3000,
    //   });
    //   return false;
    // }
    this.selectedTemplate.caseId = this.case.id;
    this.selectedTemplate.clientId = this.case.clientId;
    this.selectedTemplate.taskList = this.taskList.filter(x=> x.selected === true);
    this.selectedTemplate.createdBy = this.authService.currentUserValue.id;
    this.bulkTaskTemplate = this.selectedTemplate;
    console.log(this.bulkTaskTemplate, 12);
    return true;

  }
  isServiceInCase(userServiceId: number): boolean {
    return this.case.services.some(service => service.serviceId === userServiceId);
}
toggleDescription(index: number) {
  debugger
  // Object.keys(this.collapseStates).forEach(key => {
  //   this.collapseStates[+key] = true;
  // });
  this.collapseStates[index] = !this.collapseStates[index];
  // Toggle the selected user's description
  // Toggle the collapse state for the specific user

}
// In the component where you call this method:
}
