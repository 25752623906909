import { Component } from '@angular/core';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { FormControl, FormGroup, ReactiveFormsModule,FormBuilder } from '@angular/forms';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Result } from 'src/app/Models/Result';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ClientDocuments } from 'src/app/Models/ClientDocuments';
import { FileSelectEvent, FileUploadEvent } from 'primeng/fileupload';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DocumentSubCategories } from 'src/app/Models/ClientDocumentCategory';
import { S3SignedUrl } from 'src/app/Models/AWSServerCredentials';
@Component({
  selector: 'app-document-setup',
  templateUrl: './document-setup.component.html',
  styleUrls: ['./document-setup.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class DocumentSetupComponent {
  uploadedFiles: any[] = [];
  DocumentCategories = [];
  DocumentSubCategories: DocumentSubCategories[] = [];
  clientDocuments: ClientDocuments = new ClientDocuments();
  categories=[];
  selectedCategory: any;
  signedUrl: S3SignedUrl = new S3SignedUrl();
  selectedFile: any;
  clientInfo: any;
  isDataSaving: boolean;
  clientId: number;
  subCategoryId:number;
  caseId:number;
  path:any;
  mainCategory:any;
  subCategory:any;
  progress = 0;
  formGroup: FormGroup | undefined;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private fb: FormBuilder
  ){

    this.clientInfo = this.dialogConfig.data.client;
    this.path = this.dialogConfig.data.path;
    this.mainCategory = this.dialogConfig.data.mainCategory;
    this.subCategory = this.dialogConfig.data.subCategory;
    this.subCategoryId = this.dialogConfig.data.subCategoryId;
    this.caseId = this.dialogConfig.data.caseId;

    this.getDocumentcategory();
  }
  ngOnInit(): void {
this.getDocumentcategory();
this.formGroup = this.fb.group({
  selectedCategory: [null]
});

  }
  getDocumentcategory(): void
  {

    this.apiService.getDocumentcategory().subscribe({
      next: (resp: Result) =>
      {
        if (resp.status === 'success') {
          this.DocumentCategories = resp.data.map((DocumentCategories: any) => ({
            name: DocumentCategories.categoryName,
            id: DocumentCategories.id,
            subcategories: JSON.parse(DocumentCategories.documentSubCategoryJson).map((sub: any) => ({
              subCategory: sub.SubCategory,
              subCategoryId: sub.Id
            }))
          }));
        } else
        {

        }
      },
      error: (error) =>
      {
        console.error('error:', error);
      },
      complete: () => { },
    });
  }
  onSelectCategory(): void {

    // Retrieve selected category from form control
    this.selectedCategory = this.formGroup.get('selectedCategory').value;
  }
  onFileSelect(input: any): void {
    const file = input.files?.[0]; // Get the selected file (if any)
    if (file) {
      this.selectedFile = file;
      // console.log('Selected file:', file);
      // console.log('File name:', file.name);
      // console.log('File size:', file.size);
      // You can perform further actions with the selected file here
    } else {
      console.log('No file selected');
    }
  }
  onSubmit(): void {
if (this.validateAndSetData()) {
  this.clientDocuments.documentStatusId = 2;
  this.clientDocuments.caseId = this.caseId;
  this.clientDocuments.fileSize = this.selectedFile.size;
  this.clientDocuments.fileExt = this.selectedFile.name.split('.').pop().toLowerCase();
  this.apiService.addUpdateClientdocuments(this.clientDocuments).subscribe({
    next: (resp: Result) => {
      if (resp.status === 'success') {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: resp.message,
          life: 3000,
        });
        this.dialogRef.close();
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: resp.message,
          life: 3000,
        });
      }
    },
    error: (error) => {
      // Handle error
      console.error('error:', error);
    },
  });
}

  }
  onUpload(event: FileUploadEvent) {
    for (let file of event.files) {
      this.progress = 0;

    if (file) {
      this.getAwsSignUrl(this.selectedFile);
    }
    }


  }

  onSelect(event: FileSelectEvent) {
    this.selectedFile = event.files[0];
    this.messageService.add({
      severity: 'info',
      summary: 'File Selected',
      detail: '',
    });
  }
  public getAwsSignUrl(file: File): void {
    const awsPath = `tms/org_${this.authService.currentUserValue.organizationId}/documents/clients_${this.clientInfo.id}/${this.mainCategory.trim().replace(/\s+/g, ' ')}/${this.subCategory.trim().replace(/\s+/g, ' ')}`;
    const filePath = `${awsPath}/${file.name}`;
    let encodedPath = encodeURIComponent(filePath);
    let type = encodeURIComponent(file.type);
    this.signedUrl = new S3SignedUrl();
    this.signedUrl.encodedPath = encodedPath;
    this.signedUrl.type = type;

    this.apiService.getS3SignedUrlForDocuments(this.signedUrl).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.apiService.uploadToS3(this.selectedFile, resp.data).subscribe({
            next: (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event.type === HttpEventType.Response) {
                if (event.status === 200) {
                  const url = `https://d1zoy1lzkhwt04.cloudfront.net/${filePath}`;
                  this.clientDocuments.fileLocation = url;
                  this.messageService.add({
                    severity: 'info',
                    summary: 'File Uploaded',
                    detail: '',
                  });
                  this.uploadedFiles.push(file);
                } else {
                  console.log(event);
                }
              }
            },
            error: (error) => {
              // Handle error
              console.error('Upload error:', error);
            },
          });


        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  public validateAndSetData() {
    if (!this.clientDocuments.documentName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter document title',
        life: 3000,
      });
      return false;
    }

    if (!this.clientDocuments.description) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must enter a short description',
        life: 3000,
      });
      return false;
    }
      if (!this.clientDocuments.fileLocation) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'You may have forgotten to upload document.',
          life: 3000,
        });
        return false;
      }
    this.clientDocuments.subCategoryId = this.subCategoryId;
    this.clientDocuments.clientId = this.clientInfo.id;
    this.clientDocuments.createdByUserId = this.authService.currentUserValue.id;
    this.clientDocuments.active = true;

    return true;
  }
  public modalClose() {
    this.dialogRef.close();
  }
}
