import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BillingSettingsSetupComponent } from 'src/app/components/clients/client-cases/billing-settings-setup/billing-settings-setup.component';
import { CaseSetupComponent } from 'src/app/components/clients/client-cases/case-setup/case-setup.component';
import { ManageAssigneeComponent } from 'src/app/components/clients/client-cases/manage-assignee/manage-assignee.component';
import { CaseFilters, ClientCase } from 'src/app/Models/Case';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';

@Component({
  selector: 'app-incompletesetup',
  templateUrl: './incompletesetup.component.html',
  styleUrls: ['./incompletesetup.component.css']
})
export class IncompletesetupComponent {
  @Input() message: string = 'There is no record found.';
  @Input() height: string = 'unset';
  @Input() showBtn: boolean = false;
  @Input() cases: any[] =[];
  @Input() clientId: any =0;
  @Input() clientInfo: any;
  completedSteps = {
    case: false,
    assignee: false,
    billing: false
  };
  caseSetupHeader: string;
  caseFilters: CaseFilters = new CaseFilters();
  fromDate: any = new Date(new Date().setMonth(new Date().getMonth() - 1));
  toDate: any = new Date();
  currentPage: number = 1;
  pageSize: number = 100;


  /**
   *
   */
  constructor(
    private apiService: ApiService,

    private dialogService: DialogService,
    private messageService: MessageService,
  ) {


  }

  getCases(): void {
    this.caseFilters.pageNumber = this.currentPage;
    this.caseFilters.pageSize = this.pageSize;
    this.caseFilters.clientId = this.clientId;
    if (this.fromDate) {
      this.caseFilters.fromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 2,
        this.fromDate.day
      );
    }
    if (this.toDate) {
      this.caseFilters.toDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day + 1
      );
    }
    this.apiService.getCases(this.caseFilters).subscribe({
      next: (resp: Result) => {
        this.cases = [];
        if (resp.status === 'success') {
          this.cases = resp.data;
          if (this.cases && this.cases.length > 0) {
            const updatedCases = this.cases.map((caseObj) => {
              caseObj.services = parseJSONToLowerCaseFirstChar(
                caseObj.servicesJson
              );
              return caseObj;
            });
          }
          //For Future In case of pagination
          //this.generateDisplayedPages();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  addNewCase(item: any) {
    if (this.cases?.filter((e) => e.active).length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'New engagement cannot be opened when there is already an open engagement',
        life: 3000,
      });
    } else {
      this.caseSetupHeader = 'Engagement Info';
      let caseObj = new ClientCase();

      if (item) {
        this.caseSetupHeader = 'Engagement Info';
        caseObj = item;
      }

      const modalRef = this.dialogService.open(CaseSetupComponent, {
        modal: true,
        showHeader: true,
        header: this.caseSetupHeader,
        width: '60%',
        contentStyle: { overflow: 'hidden', padding: '0px' },
        baseZIndex: 10000,
        maximizable: false,
        data: {
          case: caseObj,
          clientInfo: this.clientInfo,
        },
      });
      modalRef.onClose.subscribe((result: any) => {
        if (result && result.success) {
          window.location.reload();
          this.completedSteps.case = true; // Mark step as completed
        }
      });

    }
  }
  addBillingSettings(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Billing Settings';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(BillingSettingsSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe((result: any) => {
      if (result && result.success) {
      this.completedSteps.billing = true; // Mark step as completed
      }
      this.getCases();
    });
  }
  manageAssignee(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Manage Assignee';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(ManageAssigneeComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe((result: any) => {
      if (result && result.success) {
      this.completedSteps.assignee = true; // Mark step as completed
      }
      this.getCases();
    });
  }
  isStepCompleted(step: string): boolean {
    return this.completedSteps[step];
  }
}
