import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Result } from 'src/app/Models/Result';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';
import { LeadviewComponent } from '../../leads/leadview/leadview.component';
import { TimeEntryDetailsMonthWise } from 'src/app/Models/Task';
import { padZero } from 'src/app/utils/utils';
@Component({
  selector: 'app-time-entries',
  templateUrl: './time-entries.component.html',
  styleUrls: ['./time-entries.component.css']
})
export class TimeEntriesComponent {
  @Input() data: any | undefined;
  timeEntryDetails: TimeEntryDetailsMonthWise[] = [];

  projectId: number | undefined;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) {
    this.data = this.dialogConfig.data.data;
    this.timeEntryDetails = this.data;
    this.projectId = this.dialogConfig.data.projectId;
  }
  ngOnInit(): void {
    if (this.data) {
      this.timeEntryDetails = this.data;
      this.timeEntryDetails = parseJSONToLowerCaseFirstChar(
          this.timeEntryDetails
        );


    }
  }


  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }


  close(): void {
    this.dialogRef.close(true);
  }
}
