export class SystemTemplates {
    id: number;
    dateCreated: Date;
    orgId: number;
    name: string;
    templateType: string;
    templateData: string;
    active: boolean;
    jsonTemplate: string;
    createdBy: number;
    isSystemTemplate: boolean;
    taskList:TasksTemp [] =[];
  }
  export class TasksTemplate {
    id: number;
    dateCreated: Date;
    categoryId: number;
    systemTemplateId: number;
    isActive: boolean;
    createdBy: number;
    taskData: string;
    title: string;
    description: string;
    acceptanceCriteria:string;
    taskId: number;
  }
  export class TasksTemp {
    Title: string;
    Description: string;
    TaskStatusId: number;
    TaskPriorityId: number;
    AcceptanceCriteria: string;
    CategoryId: number;
    ServiceId: number;
  }