import { User } from "./Users";

export class Team {
  id!: number;
  name!: string;
  description!: string;
  createdAt!: Date;
  updatedAt!: Date;
  updatedByUserId!: number;
  orgId!: number;
  isActive: boolean = true;
  members: User[] = null;
  membersJson: string = null;
}
