<div class="modal-body">
  <form class="pt-3">
    <div class="form-row">
      <div class="form-group col">
        <label for="title">Title</label>
        <input type="text" class="form-control" name="title" [(ngModel)]="event.title">
      </div>
      <div class="form-group col">
        <label for="eventType">Event Type</label>
        <div class="input-group">
          <select class="custom-select" name="eventType" [(ngModel)]="event.eventTypeId">
            <option selected value="0">Select event type...</option>
            <option *ngFor="let e of eventTypes" [ngValue]="e.id">{{e.type}}</option>
          </select>
        </div>
      </div>
      <div class="form-group col" *ngIf="eventId > 0">
        <label for="statusType">Status Type</label>
        <div class="input-group">
          <select class="custom-select" name="statusType" [(ngModel)]="event.statusId">
            <option selected>Select status...</option>
            <option *ngFor="let e of statuses" [ngValue]="e.id">{{e.name}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-row"
      style="border: 1px solid #f1f1f1; background: #f9f9f9; border-radius: 5px; padding: 5px; margin-bottom: 10px;"
      *ngIf="event.eventTypeId===3">
      <div class="form-group col">
        <label for="locationName">Location Name</label>
        <input type="text" class="form-control" name="locationName" [(ngModel)]="event.locationName">
      </div>
      <div class="form-group col">
        <label for="locationAddress">Location Address</label>
        <input type="text" class="form-control" name="locationAddress" [(ngModel)]="event.locationAddress">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="startDateTime">Starts At</label>
        <input type="datetime-local" class="form-control" name="startDateTime" [(ngModel)]="event.startDateTime">
      </div>
      <div class="form-group col">
        <label for="endDateTime">Ends At</label>
        <input type="datetime-local" class="form-control" name="endDateTime" [(ngModel)]="event.endDateTime">
      </div>
      <div class="form-group col">
        <label for="reminderTime">Reminder Time</label>
        <input type="datetime-local" class="form-control" name="reminderTime" [(ngModel)]="event.reminderTime">
      </div>
    </div>
    <div class="form-row">
      <!-- <div class="form-group col">
        <label for="reminderTime">Reminder Time</label>
        <input type="datetime-local" class="form-control" name="reminderTime" [(ngModel)]="event.reminderTime">
      </div> -->
      <div *ngIf="leadId === 0 ;else elseBlock" class="form-group col">
        <label for="leadRef">Referenced Lead</label>
        <p class="mb-1" style="font-size: 0.875rem;">Specify the related lead for this event by searching and selecting
          from the available options
        </p>
        <input type="text" class="form-control" ngbInput [(ngModel)]="selectedLead" name="leadRef"
          [resultFormatter]="leadFormatter_search" [inputFormatter]="leadFormatter_selected"
          [ngbTypeahead]="searchLead">
      </div>
      <ng-template #elseBlock>
        <div class="form-group col">
          <label for="leadRef">Referenced Lead</label>
          <input type="text" name="leadRef" class="form-control" [value]="selectedLead?.name" disabled="true" />
        </div>
      </ng-template>
    </div>
    <div class="form-group">
      <label for="description">Description</label>
      <textarea class="form-control" name="description" [(ngModel)]="event.description" rows="3"></textarea>
    </div>

    <div class="m-0 mb-2" style="border: 1px solid #edebeb;border-radius: 0.375rem;">
      <h5 class="p-2 text-center" style="background: #f5f5f5;">Event Organizer</h5>

      <div class="form-group col p-3 pb-0 pt-0">
        <label for="organizer" style="font-size: 0.875rem;" class="font-weight-normal me-3">Find and choose the event
          coordinator from search
          list</label>

        <p-autoComplete name="searchusers" [(ngModel)]="selectedUser" [dropdown]="true" [suggestions]="filteredUsers"
          (completeMethod)="searchUsers($event)" field="name">
          <ng-template let-user pTemplate="item">
            <div class="d-flex align-items-center gap-2 flex-row">
              <img [src]="user.profilePictureUrl" style="width: 18px" />
              <div>{{ user.name }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

    </div>

    <div class="m-0 mb-2" style="border: 1px solid #edebeb;border-radius: 0.375rem;">
      <h5 class="p-2 text-center" style="background: #f5f5f5;">Additional Fields (Optional)
      </h5>
      <ng-container *ngIf="additionalFields">
        <p style="margin-left: .8rem;font-size: 0.875rem;">Include as much information as needed to tailor your event
          details</p>
        <div class="additionalField" *ngFor="let field of additionalFields; let i = index">
          <div *ngIf="field.isDeleted === false" class="form-row align-items-center">
            <div class="form-group col m-0">
              <label for="fieldName{{i}}" class="font-weight-normal">Field Name</label>
              <input type="text" class="form-control" name="fieldName{{i}}" [(ngModel)]="field.fieldName">
            </div>
            <div class="form-group col m-0">
              <label for="fieldValue{{i}}" class="font-weight-normal">Field Value</label>
              <input type="text" class="form-control" name="fieldValue{{i}}" [(ngModel)]="field.fieldValue">
            </div>
            <div class="form-group col m-0">
              <div class="form-check" style="margin-top: 1.4rem;">
                <input class="form-check-input" type="checkbox" name="highlight{{i}}" [(ngModel)]="field.isHighlighted">
                <label class="form-check-label" for="flexCheckChecked">
                  Highlight this field
                </label>
              </div>
            </div>
            <div class="form-group col m-0">
              <button type="button" *ngIf="i!==0" class="btn btn-sm btn-danger"
                (click)="deleteField(i)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <button type="button" class="btn btn-primary btn-sm action-button"
        style="margin-top: 0.6rem;margin-left: 10px;margin-bottom: 10px;" (click)="addField()"><i
          class="fas fa-plus me-1"></i>Add More
      </button>
    </div>
  </form>
</div>
<div class="p-dialog-footer">
  <button type="submit" class="btn btn-primary btn-sm action-button mr-2" [disabled]="this.isDataSaving" (click)="saveEvent()">Save</button>
  <button type="button" class="btn btn-sm action-button-cancel" (click)="close(false)">
    Cancel
  </button>
</div>
