import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ClientLocations } from 'src/app/Models/ClientLocations';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MapGeocoder } from '@angular/google-maps';
import { ApiService } from 'src/app/services/api.service';
import { Result } from 'src/app/Models/Result';
import { AuthService } from 'src/app/services/auth.service';
import { OverlayPanel } from 'primeng/overlaypanel';
@Component({
  selector: 'app-client-setup-location',
  templateUrl: './client-setup-location.component.html',
  styleUrls: ['./client-setup-location.component.css']
})
export class ClientSetupLocationComponent {
  @ViewChild('loc') loc: OverlayPanel;
  @ViewChild('taskDropButton', { static: false })
  taskDropButton!: ElementRef;
  @ViewChildren('dependencyInput') dependencyInputs!: QueryList<ElementRef>;
  clientLocation: ClientLocations = new ClientLocations();
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };;
  zoom = 14;
  clientId: number;
  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private authService:AuthService,
    private geocoder: MapGeocoder
  ){
    this.clientLocation = this.dialogConfig.data.clientLocation;
    this.clientId = this.dialogConfig.data.client;
  }
  ngOnInit(): void {
    if(this.clientLocation.id){
      this.center.lat = this.clientLocation.latitude;
      this.center.lng = this.clientLocation.longitude;
    }
    else{
      this.getUserLocation().then(() => {

        // Toggle the overlay panel after location is retrieved
     });
    }

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }
  onSetLocation(event: Event): void {
    this.getUserLocation().then(() => {
      if(this.loc){
        this.loc.toggle(event);
      }
       // Toggle the overlay panel after location is retrieved
    });
  }

  getUserLocation(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          resolve();
        }, (error) => {
          console.error('Error getting location', error);
          // Set a default location if permission is denied or another error occurs
          resolve();  // Resolve even if we set a default location
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
        // Set a default location if geolocation is not supported
        resolve();
      }
    });
  }
  mapClicked(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    }
  }
  public modalClose()
  {
    this.dialogRef.close();
  }
  onSubmit(){
    if (this.validateAndSetData())
      {
    this.apiService.addorUpdateClientLocations(this.clientLocation).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: resp.message,
            life: 3000,
          });
          this.dialogRef.close();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  }
  saveLocation() {
    console.log('Location saved:', this.center.lat, this.center.lng);
  }
  public validateAndSetData()
  {
    this.clientLocation.latitude = this.center.lat;
    this.clientLocation.longitude = this.center.lng;
    if (!this.clientLocation.latitude)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the client location latitude',
        life: 3000,
      });
      return false;
    }
    if (!this.clientLocation.longitude)
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the client location longitude',
        life: 3000,
      });
      return false;
    }
    if (!this.clientLocation.radius)
      {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Please fill the client location radius',
          life: 3000,
        });
        return false;
      }
     this.clientLocation.clientId = this.clientId;
     this.clientLocation.createdBy = this.authService.currentUserValue.id;
     this.clientLocation.isActive = true;
     if(this.clientLocation.id > 0){

     }
     else{
      this.clientLocation.id = 0;
     }
    return true;
  }
  closeDropdown() {
    this.taskDropButton.nativeElement.click();
  }
}
