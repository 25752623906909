import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent {
  data: any | undefined;

  title: string = 'Confirm';
  message: string = 'Are you sure you want to continue?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'No';
  constructor(
    public activeModal: NgbActiveModal,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    this.data = this.dialogConfig.data;

    if (this.data) {
      this.message = this.data.message || this.message;
      this.title = this.data.title || this.title;
      if (this.data.buttonText) {
        this.confirmButtonText =
          this.data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText =
          this.data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onClose(): void {
    this.dialogRef.close(true);
  }
  onCancel(): void {
    this.dialogRef.close(false);
  }
}
