import { Component, Renderer2 } from '@angular/core';
import { CaseStatistics, CostAndHoursMonthlyTrend } from 'src/app/Models/CostAndHoursMonthlyTrend';
import { OrgTotals } from 'src/app/Models/OrgTotals';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';

@Component({
  selector: 'app-cost-explorer',
  templateUrl: './cost-explorer.component.html',
  styleUrls: ['./cost-explorer.component.css'],
})
export class CostExplorerComponent {
  selectedDate: Date = new Date();
  totals: OrgTotals = new OrgTotals();
  monthTrend: CostAndHoursMonthlyTrend[] = [];
  topCases:CaseStatistics[]=[];
  currentYear: number = this.selectedDate.getFullYear();

  estimatedRevenue: number = 15000; // Dummy data
  actualRevenue: number = 10000; // Dummy data
  caseCount: number=3;

  constructor(
    private renderer: Renderer2,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.applyFilter();
  }

  ngAfterViewInit() {}

  applyFilter() {
    this.currentYear = this.selectedDate.getFullYear();
    this.getTotals().then((t) => {
      this.loadBudgetChartData();
      this.loadBillableVsNonBillableHoursData();
      this.loadActualVsEstimatedCostData();
      this.loadActualVsEstimatedHoursData();
    });
    this.getCaseStatisticsByOrgId();
    this.getMonthlyTrend().then((t) => {
      this.loadMonthlyActualvsEstimatedCostChart(t);
    });
  }

  //#region Api Calls

  getTotals(): Promise<OrgTotals> {
    return new Promise<OrgTotals>((resolve, reject) => {
      this.apiService.getOrganizationTotals(this.currentYear).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.totals = resp.data;

            if (this.totals) {
              // You can add any additional logic here if needed
            }

            resolve(this.totals); // Resolve the promise when the operation is successful
          } else {
            // Handle cases where the response status is not 'success'
            reject(new Error('Failed to get totals: ' + resp.status));
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
          reject(error); // Reject the promise on error
        },
      });
    });
  }

  getMonthlyTrend(): Promise<CostAndHoursMonthlyTrend[]> {
    return new Promise<CostAndHoursMonthlyTrend[]>((resolve, reject) => {
      this.apiService.getCostAndHoursMonthlyTrend(this.currentYear).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.monthTrend = resp.data;

            if (this.monthTrend) {
              // You can add any additional logic here if needed
            }

            resolve(this.monthTrend); // Resolve the promise when the operation is successful
          } else {
            // Handle cases where the response status is not 'success'
            reject(new Error('Failed to get totals: ' + resp.status));
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
          reject(error); // Reject the promise on error
        },
      });
    });
  }

  getCaseStatisticsByOrgId(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getCaseStatisticsByOrgId(this.caseCount,this.currentYear).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.topCases = resp.data;
            resolve();
          } else {
            // Handle cases where the response status is not 'success'
            reject(new Error('Failed to get totals: ' + resp.status));
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
          reject(error); // Reject the promise on error
        },
      });
    });
  }

  //#endregion

  //#region Total Budget

  budgetChartData: any = null;

  loadBudgetChartData() {
    this.budgetChartData = {
      labels: ['Budget', 'Cost'],
      datasets: [
        {
          data: [
            this.totals.estimatedCost?.toFixed(2),
            this.totals.actualCost?.toFixed(2),
          ],
          backgroundColor: ['rgb(255 99 132 / 50%)', 'rgb(89 185 249 / 50%)'],
        },
      ],
    };
  }

  get budgetUtilization(): number {
    return (
      ((this.totals.actualCost - this.totals.estimatedCost) /
        this.totals.estimatedCost) *
      100
    );
  }

  //#endregion

  //#region Actual Cost vs Estimated Cost

  actualVsEstimatedCostData: any = null;

  loadActualVsEstimatedCostData() {
    const ctx = this.renderer.createElement('canvas').getContext('2d');

    const gradient = ctx.createLinearGradient(100, 0, 50, 150);
    //if normal use color code "0, 187, 255"
    //if exceeds use color code "255, 39, 15"
    if (this.totals.actualCost < this.totals.estimatedCost) {
      gradient.addColorStop(0, 'rgba(0, 187, 255, 0.6)');
      gradient.addColorStop(1, 'rgba(0, 187, 255, 0.1)');
    } else {
      gradient.addColorStop(0, 'rgba(255, 39, 15, 0.6)');
      gradient.addColorStop(1, 'rgba(255, 39, 15, 0.1)');
    }

    const gradient2 = ctx.createLinearGradient(100, 0, 50, 150);
    gradient2.addColorStop(0, 'rgba(255, 174, 0, 0.6)');
    gradient2.addColorStop(1, 'rgba(255, 174, 0, 0.1)');

    this.actualVsEstimatedCostData = {
      labels: ['Actual', 'Estimated'],
      datasets: [
        {
          label: 'Cost',
          data: [
            this.totals.actualCost?.toFixed(2),
            this.totals.estimatedCost?.toFixed(2),
          ],
          backgroundColor: [gradient, gradient2],
          barThickness: 6,
          maxBarThickness: 8,
          minBarLength: 2,
        },
      ],
    };
  }

  get costVariance(): string {
    return (
      ((this.totals.actualCost - this.totals.estimatedCost) /
        this.totals.estimatedCost) *
      100
    )?.toFixed(2);
  }

  //#endregion

  //#region Actual Hours vs Estimated Hours

  actualVsEstimatedHoursData: any = null;

  loadActualVsEstimatedHoursData() {
    const ctx = this.renderer.createElement('canvas').getContext('2d');

    const gradient = ctx.createLinearGradient(100, 0, 50, 150);
    //if normal use color code "0, 187, 255"
    //if exceeds use color code "255, 39, 15"
    if (this.totals.actualHours < this.totals.estimatedHours) {
      gradient.addColorStop(0, 'rgba(0, 187, 255, 0.6)');
      gradient.addColorStop(1, 'rgba(0, 187, 255, 0.1)');
    } else {
      gradient.addColorStop(0, 'rgba(255, 39, 15, 0.6)');
      gradient.addColorStop(1, 'rgba(255, 39, 15, 0.1)');
    }
    const gradient2 = ctx.createLinearGradient(100, 0, 50, 150);
    gradient2.addColorStop(0, 'rgba(255, 174, 0, 0.6)');
    gradient2.addColorStop(1, 'rgba(255, 174, 0, 0.1)');

    this.actualVsEstimatedHoursData = {
      labels: ['Actual', 'Estimated'],
      datasets: [
        {
          label: 'Hours',
          data: [
            this.totals.actualHours?.toFixed(2),
            this.totals.estimatedHours?.toFixed(2),
          ],
          backgroundColor: [gradient, gradient2],
          barThickness: 6,
          maxBarThickness: 8,
          minBarLength: 2,
        },
      ],
    };
  }

  get hoursVariance(): string {
    return (
      ((this.totals.actualHours - this.totals.estimatedHours) /
        this.totals.estimatedHours) *
      100
    )?.toFixed(2);
  }

  //#endregion

  //#region Billable vs Non-Billable Hours

  billableVsNonBillableHoursData: any = null;

  loadBillableVsNonBillableHoursData() {
    const ctx = this.renderer.createElement('canvas').getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 300, 0);
    gradient.addColorStop(0, 'rgba(255,22,131,0.2)');
    gradient.addColorStop(0.5, 'rgba(255,22,131,0.3)');
    gradient.addColorStop(1, 'rgba(255,22,131,0.5)');

    const gradient2 = ctx.createLinearGradient(0, 0, 300, 0);
    gradient2.addColorStop(0, 'rgba(240,192,144,0.2)');
    gradient2.addColorStop(0.5, 'rgba(240,192,144,0.3)');
    gradient2.addColorStop(1, 'rgba(240,192,144,0.5)');

    this.billableVsNonBillableHoursData = {
      labels: ['Billable', 'Non-Billable'],
      datasets: [
        {
          data: [
            this.totals.billableHours?.toFixed(2),
            this.totals.nonBillableHours?.toFixed(2),
          ], // Replace with your actual data
          backgroundColor: [gradient, gradient2],
          hoverBackgroundColor: ['rgba(224,0,104,1)', 'rgba(238,169,101,1)'],
          borderColor: ['rgba(224,0,104,0.2)', 'rgba(240,192,144,0.2)'],
          borderWidth: 2,
        },
      ],
    };
  }

  chartOptionsBillableVsNonBillableData: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%', // Adjust the cutout percentage to create the donut effect
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ': ' + tooltipItem.raw + ' hours';
          },
        },
      },
    },
  };

  //#endregion

  //#region Monthly Actual vs Estimated Cost

  monthlyActualvsEstimatedCostData: any = null;

  loadMonthlyActualvsEstimatedCostChart(trend) {
    let ctx = this.renderer.createElement('canvas');

    const gradient = ctx.getContext('2d').createLinearGradient(100, 0, 50, 150);
    gradient.addColorStop(0, 'rgba(0, 255, 17, 0.2)');
    gradient.addColorStop(1, 'rgba(0, 255, 17, 0)');

    const months = trend.map((t) => t.monthName);
    const actualCosts = trend.map((t) => t.totalCost);

    this.monthlyActualvsEstimatedCostData = {
      labels: months,
      datasets: [
        {
          label: 'Actual Costs',
          fontFamily: 'Poppins',
          data: actualCosts,
          fill: true,
          pointBorderWidth: 1,
          pointRadius: new Array(actualCosts.length).fill(2),
          pointHoverRadius: new Array(actualCosts.length).fill(4),
          tension: 0.4,
          borderDash: [5, 5],
          borderWidth: 1.5,
          borderColor: 'rgba(0, 255, 17)',
          backgroundColor: gradient,
        },
      ],
    };
  }

  //#endregion

  //#region Top 5 Cases

  top5Cases = [
    {
      ClientName: 'Client A',
      CaseName: 'Case 1',
      TotalHours: 120,
      TotalCost: 1500,
      EstimatedBudget: 2000,
      BudgetSpentPercentage: 75,
    },
    {
      ClientName: 'Client B',
      CaseName: 'Case 2',
      TotalHours: 80,
      TotalCost: 1000,
      EstimatedBudget: 1500,
      BudgetSpentPercentage: 66.67,
    },
    {
      ClientName: 'Client C',
      CaseName: 'Case 3',
      TotalHours: 60,
      TotalCost: 800,
      EstimatedBudget: 1000,
      BudgetSpentPercentage: 80,
    },
  ];

  //#endregion

  //#region Chart Options

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    aspectRatio: 3.5, // Adjust this ratio to control the height of the chart area
  };
  chartOptionsCost: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return tooltipItem.dataset.label + ': Rs. ' + value.toLocaleString();
          }
        }
      },
      legend: {
        display: false,
      },
    },
    aspectRatio: 3.5, // Adjust this ratio to control the height of the chart area
  };
  chartOptionsWithLegends: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
    aspectRatio: 3.5, // Adjust this ratio to control the height of the chart area
  };

  horizontalChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return tooltipItem.dataset.label + ': Rs. ' + value.toLocaleString();
          }
        }
      },
      legend: {
        display: false,
      },
    },
    aspectRatio: 3.5, // Adjust this ratio to control the height of the chart area
  };

  //#endregion
}
