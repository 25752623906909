import { Services } from './Services';
import { User } from './Users';

// src/app/Models/case.model.ts
export class CaseService {
  id!: number;
  caseId!: number;
  serviceId!: number;
  categoryId!: number;
  estimatedHours!: number;
  estimatedCost!: number;
  serviceName!: string;
}

export class ClientCase {
  id!: number;
  clientId!: number;
  caseName!: string;
  startDate!: Date;
  endDate!: Date;
  description!: string;
  active!: boolean;
  services: CaseService[] = [];
  servicesJson!: string;
  billingTypeId!: number;
  billingType!: string;
  notificationThreshold!: number;
  caseAssigneesJson!: string;
  billableTypeId!: number;
  billableType!: string;
  billableAmount: number;
  estHours: number;
  totalBudgetSpentInPercentage: number;
}

export class CaseFilters {
  pageNumber: number;
  pageSize!: number;
  clientId!: number;
  fromDate!: Date | null;
  toDate!: Date | null;
  query!: string;
}
export class BudgetBillingType {
  id: number;
  type: string;
}
export class BillableType {
  id: number;
  type: string;
  description: string;
  formula: string;
  isChecked: boolean;
  ratesPerHour: number;
}
export class CaseBillingSettings {
  id: number;
  clientId: number;
  caseId: number;
  billableTypeId: number;
  ratesPerHour: number;
}
export class CaseAssigneRates {
  id!: number;
  clientId!: number;
  caseId!: number;
  caseAssignees: User[] = [];
  caseAssigneesJson!: string;
}
export class CaseAssignees {
  userId!: number;
  ratesPerHour: number;
  name: string;
}
