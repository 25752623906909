<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">

      <ng-container *ngIf="!processCompleted; else success">
        <app-project-loading-indicator *ngIf="isLoading" />
        <div *ngIf="!isLoading && user; else notfound" class="card">
          <div class="card-header">
            <h4 class="card-title">Hi {{user.firstName}},</h4>
            <h5>Welcome to Radiant WorkForce TMS !</h5>
          </div>
          <div class="card-body">
            <p class="card-text">You've been added as a new user in our Radiant WorkForce TMS. To access your account,
              please set your password below:</p>
            <form (ngSubmit)="setPassword()">
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" name="password" placeholder="Enter your password"
                  [(ngModel)]="password" required>
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label">Confirm Password</label>
                <input type="password" class="form-control" name="confirmPassword" placeholder="Confirm your password"
                  [(ngModel)]="confirmPassword" required>
              </div>
              <div *ngIf="password !== confirmPassword" class="alert alert-danger" role="alert">
                Passwords do not match!
              </div>
              <button type="submit" class="btn btn-primary">Set Password</button>
            </form>
          </div>
        </div>
      </ng-container>

      <ng-template #notfound>
        <app-nodatafound message="Invalid Request" [showBtn]="true" />
      </ng-template>

      <ng-template #success>
        <div class="text-center login-main-right bg-white p-5 mt-5 mb-5" style="height: unset;">
          <img src="/assets/img/success-icon.png" alt="success image" style="max-width: 200px;" />
          <p style="text-align: center" class="mt-4">
            You've successfully setup your password. Now login to your account
            <br />

            <button class="btn btn-primary mt-5" [routerLink]="['/login']">Login Now</button>
          </p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
