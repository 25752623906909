<div class="modal-body">
    <div class="dep-scroll">
    <div class="permission-list">
        <div *ngFor="let permission of permissions; let i = index" class="permission-card">
          <div class="permission-header">
            <h3>{{ permission.permissionName }}</h3>
          </div>
          <div class="permission-body">
            <p>{{ permission.description }}</p>
          </div>
        </div>
      </div>
  
     </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm action-button-cancel mt-2" (click)="modalClose()">OK</button>
  </div>