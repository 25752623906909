<div class="row align-items-center p-1" style="background-color: #f1f1f1;">
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-light" mwlCalendarPreviousView [view]="calenderView" [(viewDate)]="viewDate"
        (viewDateChange)="dateChange()">
        Previous
      </div>
      <div class="btn btn-light" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="dateChange()">
        Today
      </div>
      <div class="btn btn-light" mwlCalendarNextView [view]="calenderView" [(viewDate)]="viewDate"
        (viewDateChange)="dateChange()">
        Next
      </div>
    </div>
  </div>
  <div class="col-md-4 text-center">
    <h3 class="m-0">{{ viewDate | calendarDate:(calenderView + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="col-md-4 text-right">
    <div class="btn-group">
      <div class="btn btn-light" (click)="setView(CalendarView.Day)" [class.active]="calenderView === CalendarView.Day">
        Day
      </div>
      <div class="btn btn-light" (click)="setView(CalendarView.Week)"
        [class.active]="calenderView === CalendarView.Week">
        Week
      </div>
      <div class="btn btn-light" (click)="setView(CalendarView.Month)"
        [class.active]="calenderView === CalendarView.Month">
        Month
      </div>
    </div>
    <div class="btn btn-primary ms-2 action-button" (click)="openEventAddEditView('New', null)">
      <i class="fa-light fa-plus"></i> Add New Event
    </div>
  </div>
</div>

<div class="d-flex align-items-center justify-content-between">
  <div class="filter-container">
    <p-checkbox label="Show Day Name" class="filter-checkbox" [binary]="true" [(ngModel)]="showDayName" (onChange)="showHideDayName()"></p-checkbox>
  </div>


  <div class="lenged-container">
    <span *ngFor="let status of statuses" class="lenged-item"
      [ngStyle]="{ 'background-color': status.bgColor, 'color': status.color}">{{status.name}}</span>
  </div>
</div>


<div [ngSwitch]="calenderView" style="
height: calc(100vh - 203px);
overflow: auto; border: 1px solid #ccc;">
  <!-- (dayClicked)="dayClicked($event.day)" -->
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (eventClicked)="openEventView('Clicked', $event.event)"
    [cellTemplate]="monthViewCustomCellTemplate" [openDayEventsTemplate]="openDayEventsTemplate"
    [excludeDays]="excludeDays">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [weekStartsOn]="0" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" (eventClicked)="openEventView('Clicked', $event.event)" [excludeDays]="excludeDays"
    [dayStartHour]="9" [dayEndHour]="22" [eventTemplate]="weekViewCustomCellTemplate">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    [dayStartHour]="9" [dayEndHour]="22" [eventActionsTemplate]="dayViewCustomCellTemplate"
    [eventTitleTemplate]="dayViewCustomCellTemplate">
  </mwl-calendar-day-view>
</div>

<ng-template #openDayEventsTemplate>
</ng-template>

<ng-template #monthViewCustomCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span *ngIf="showDayName" class="cal-day-number {{isCurrentDay(day.date) ? 'isSameDay' : ''}}">
      {{ day.date.toLocaleDateString(locale, { weekday: 'short', day: 'numeric' }) }}
    </span>
    <span *ngIf="!showDayName" class="cal-day-number {{isCurrentDay(day.date) ? 'isSameDay' : ''}}">
      {{ day.date.toLocaleDateString(locale, { day: 'numeric' }) }}
    </span>
  </div>
  <div *ngFor="let event of day.events" class="event-container-view"
    [ngStyle]="{ 'background-color': event?.dbValue?.statusBgColor, 'color': event?.dbValue?.statusColor }">
    <div class="event-type-indicator" [ngStyle]="{ 'background-color': event?.dbValue?.backColor }"
      pTooltip="{{event?.dbValue?.eventType}}" tooltipPosition="top"></div>
    <div class="event-container-view-container">
      <span pTooltip="{{event?.dbValue?.organizer?.split(';')[1] }}" tooltipPosition="top">{{event.title}}</span>

      <div class="event-container-view-btn-container" [ngStyle]="{ 'background-color': event?.dbValue?.statusBgColor }">
        <a class="event-container-view-btn mr-1" [ngStyle]="{'color': event?.dbValue?.backColor}">
          <i (click)="openEventView('View', event)" class="fa-sharp fa-light fa-circle-info"></i>
        </a>
        <a class="event-container-view-btn mr-1" [ngStyle]="{'color': event?.dbValue?.backColor}">
          <i (click)="openEventAddEditView('Edit', event)" class="fa-sharp fa-light fa-calendar-pen"></i>
        </a>
        <a class="event-container-view-btn" [ngStyle]="{'color': event?.dbValue?.backColor}">
          <i (click)="deleteEvent(event)" class="fa-sharp fa-light fa-trash-alt"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- <small style="margin: 5px">There are {{ day.events.length }} events on this day</small> -->
  <div class="cal-cell-bottom">
  </div>
</ng-template>

<ng-template #weekViewCustomCellTemplate let-weekEvent="weekEvent" let-locale="locale">
  <div class="event-container-view"
    [ngStyle]="{ 'background-color': weekEvent.event?.dbValue?.statusBgColor, 'color': weekEvent.event?.dbValue?.statusColor }">
    <div class="event-type-indicator" [ngStyle]="{ 'background-color': weekEvent.event?.dbValue?.backColor }"
      pTooltip="{{weekEvent.event?.dbValue?.eventType}}" tooltipPosition="top"></div>
    <div class="event-container-view-container">
      <span pTooltip="{{weekEvent.event?.dbValue?.organizer?.split(';')[1]}}" tooltipPosition="top">{{weekEvent.event?.title}}</span>

      <div class="event-container-view-btn-container"
        [ngStyle]="{ 'background-color': weekEvent.event?.dbValue?.statusBgColor }">
        <a class="event-container-view-btn mr-1" [ngStyle]="{'color': weekEvent.event?.dbValue?.statusColor}">
          <i (click)="openEventView('View', weekEvent.event)" class="fa-sharp fa-light fa-circle-info"></i>
        </a>
        <a class="event-container-view-btn mr-1" [ngStyle]="{'color': weekEvent.event?.dbValue?.backColor}">
          <i (click)="openEventAddEditView('Edit', weekEvent.event)" class="fa-sharp fa-light fa-calendar-pen"></i>
        </a>
        <a class="event-container-view-btn" [ngStyle]="{'color': weekEvent.event?.dbValue?.backColor}">
          <i (click)="deleteEvent(weekEvent.event)" class="fa-sharp fa-light fa-trash-alt"></i>
        </a>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #dayViewCustomCellTemplate let-event="event" let-locale="locale">
  <div class="event-container-view dayView">
    <div class="event-type-indicator" [ngStyle]="{ 'background-color': event?.dbValue?.backColor }"
      pTooltip="{{event?.dbValue?.eventType}}" tooltipPosition="top"></div>
    <div class="event-container-view-container">
      <span>{{event?.title}}</span>

      <div class="event-container-view-btn-container dayView"
        [ngStyle]="{ 'background-color': event?.dbValue?.statusBgColor }">
        <a class="event-container-view-btn mr-1" [ngStyle]="{'color': event?.dbValue?.backColor}">
          <i (click)="openEventView('View', event)" class="fa-sharp fa-light fa-circle-info"></i>
        </a>
        <a class="event-container-view-btn mr-1" [ngStyle]="{'color': event?.dbValue?.backColor}">
          <i (click)="openEventAddEditView('Edit', event)" class="fa-sharp fa-light fa-calendar-pen"></i>
        </a>
        <a class="event-container-view-btn" [ngStyle]="{'color': event?.dbValue?.backColor}">
          <i (click)="deleteEvent(event)" class="fa-sharp fa-light fa-trash-alt"></i>
        </a>
      </div>
    </div>
  </div>
</ng-template>





<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>
