import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[phoneValidator]',
  providers: [NgModel]
})
export class PhoneValidatorDirective implements OnInit {

  private errorMessage: HTMLElement;

  constructor(private el: ElementRef, private control: NgModel) {
    this.createErrorMessage();
  }

  ngOnInit(): void {
    this.control.valueChanges?.subscribe(() => {
      this.validate();
    });
  }

  private createErrorMessage() {
    this.errorMessage = document.createElement('div');
    this.errorMessage.className = 'validation-error text-danger';
    this.errorMessage.style.display = 'none';
    this.el.nativeElement.parentElement.appendChild(this.errorMessage);
  }

  private validate() {
    const value = this.el.nativeElement.value;
    const pattern = /^\+?(\d{1,4}[\s-]?)?(\(\d{1,4}\)[\s-]?)?\d{1,4}[\s-]?\d{1,4}[\s-]?\d{1,4}$/;
    const errors: any = {};

    if (value && !pattern.test(value)) {
      errors['pattern'] = true;
      this.showError('* Please enter a valid phone number in one of the following formats:\n03400000000\n0340-0000000\n+923400000000\n+92340-0000000');
    } else {
      this.hideError();
    }

    this.control.control?.setErrors(Object.keys(errors).length ? errors : null);
  }

  private showError(message: string) {
    this.errorMessage.textContent = message;
    this.errorMessage.style.display = 'table';
  }

  private hideError() {
    this.errorMessage.style.display = 'none';
  }

  @HostListener('blur') onBlur() {
    this.validate();
  }
}
