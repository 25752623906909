import { Component, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.css'],
})
export class CircleProgressComponent {
  @Input() size: number | 36;
  @Input() progress: number | 0;
  @Input() fontSize: number | 10;
  @Input() width: string | 'thin' | 'medium' | 'thick' = 'thin'; // Default value is 'thin'

  ngAfterViewInit() {
    $('.progress').each(function () {
      var value = $(this).attr('data-value');
      var left = $(this).find('.progress-left .progress-bar');
      var right = $(this).find('.progress-right .progress-bar');

      // Set dynamic color based on progress value
      if (value <= 25) {
        left.addClass('border-success'); // Red for low progress
        right.addClass('border-success');
      } else if (value <= 50) {
        left.addClass('border-warning'); // Yellow for medium progress
        right.addClass('border-warning');
      } else if (value <= 75) {
        left.addClass('border-info'); // Blue for good progress
        right.addClass('border-info');
      } else {
        left.addClass('border-pink'); // Green for high progress
        right.addClass('border-pink');
      }

      // Update the rotation of the progress bars
      if (value > 0) {
        if (value <= 50) {
          right.css(
            'transform',
            'rotate(' + percentageToDegrees(value) + 'deg)'
          );
        } else {
          right.css('transform', 'rotate(180deg)');
          left.css(
            'transform',
            'rotate(' + percentageToDegrees(value - 50) + 'deg)'
          );
        }
      }
    });

    function percentageToDegrees(percentage) {
      return (percentage / 100) * 360;
    }
  }
}
