<div class="d-flex align-items-center justify-content-between mb-2" style="background: #00a8ff08;padding: 5px 10px;">
    <div class="btn-wrapper">
      <a (click)="viewCase()" class="btn btn-otline-dark action-button-outline align-items-center ml-2 bg-white"><i
          class="fa-light fa-square-envelope"></i> View Engagements</a>
      <a (click)="fileManagement()" class="btn btn-otline-dark action-button-outline align-items-center ml-2 bg-white" *ngIf="type !== 'ClientDocument'"><i
          class="fa-light fa-folders"></i> Client Documents</a>
          <ng-container *ngIf="selectedCase">
          <a (click)="manageAssignee(selectedCase)" class="btn btn-otline-dark action-button-outline align-items-center ml-2 bg-white"><i
              class="fa-regular fa-user"></i>
              Manage Assignee {{ selectedCase.caseAssigneesJson === null ? '⚠️' : '✅' }}</a>
          <a (click)="addBillingSettings(selectedCase.id)" class="btn btn-otline-dark action-button-outline align-items-center ml-2 bg-white"><i
              class="fa-light fa-money-bill-1"></i> Billing Settings</a>
              <a (click)="viewClientLocation()" class="btn btn-otline-dark action-button-outline align-items-center ml-2 bg-white"><i
                class="fa fa-map-marker"></i> Client Location</a>
          </ng-container>
    </div>
    <div class="align-self-center">
      <span class="font-weight-600">Current Engagement:</span>
      <p-dropdown [options]="cases" [(ngModel)]="selectedCase" placeholder="Select an Engagement" optionLabel="caseName"
        class="ml-2" (onChange)="onCaseSelect($event)" appendTo="body">
        <ng-template let-eng pTemplate="item">
          <span [ngStyle]="{'font-weight': eng.active ? '600' : 'normal'}">
            {{ eng.caseName }} - {{ eng.active ? 'Current' : '(Closed)' }}
          </span>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
