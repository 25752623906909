import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform
{
  transform(startTime: string|Date|null , endTime: string|Date|null): string
  {
    let validateDates: boolean = false;
    let minDate: Date = new Date(0);
    const startVar = new Date(startTime);
    const endVar = new Date(endTime);
    if (startVar && endVar)
    {
      if (startVar > minDate && endVar > minDate)
      {
        validateDates = true;
      }
    }

    if (validateDates)
    {
      const start = moment(startTime);
      const end = moment(endTime);

      // Calculate the difference in hours and minutes
      const duration = moment.duration(end.diff(start));
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      // Format the output as "Total Difference : HH:MM"
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    }
    return ` - `;

  }
}
