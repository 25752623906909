<div class="modal-body">
    <div class="dep-scroll">
      <div class="permission-list">
        <div *ngFor="let task of taskTemplate; let i = index" class="permission-card">
          <div class="permission-header d-flex justify-content-between align-items-center">
            <h3>{{ task.title }}</h3>
            <!-- Delete icon -->
            <i class="fa fa-trash text-danger" (click)="deleteTask(i)" style="cursor: pointer;" *ngIf="!isSystemTemplate"></i>
          </div>
          <div class="permission-body">
            <ng-container *ngIf="isSystemTemplate">
              <p>{{ task.description }}</p>
              <p>{{ task.acceptanceCriteria }}</p>
            </ng-container>
            <ng-container *ngIf="!isSystemTemplate">
              <p [innerHTML]="task.description"></p>
              <p [innerHTML]="task.acceptanceCriteria"></p>
            </ng-container>
          </div>
        </div>
      </div>
     </div>
  </div>
  <div class="modal-footer p-3 d-flex justify-content-end">
    <button type="button" class="btn btn-sm btn-secondary me-2" (click)="modalClose()">Close</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="saveSetting()">Save</button>
  </div>