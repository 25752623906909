import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { Clients } from 'src/app/Models/Clients';
import { CaseAssigneRates, ClientCase } from 'src/app/Models/Case';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { Team } from 'src/app/Models/Teams';

@Component({
  selector: 'app-manage-assignee',
  templateUrl: './manage-assignee.component.html',
  styleUrls: ['./manage-assignee.component.css'],
})
export class ManageAssigneeComponent {
  availableUsers: User[] = [];
  assignedUsers: User[] = [];
  selectedAvailableUserId: number = 0;
  selectedAssignedUserId: number = 0;

  case: ClientCase = new ClientCase();
  clientInfo: Clients = new Clients();
  caseAssigneRates: CaseAssigneRates = new CaseAssigneRates();
  tempUserList: User[] = [];

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {
    debugger;
    this.case = this.dialogConfig.data.case;
    this.clientInfo = this.dialogConfig.data.clientInfo;
    if (this.case.caseAssigneesJson) {
      var caseAssign = parseJSONToLowerCaseFirstChar(
        this.case.caseAssigneesJson
      );
      this.caseAssigneRates.caseAssignees = caseAssign;
    }
    if (
      !this.caseAssigneRates.caseAssignees ||
      this.caseAssigneRates.caseAssignees?.length === 0
    ) {
      this.caseAssigneRates.caseAssignees = [];
      this.tempUserList = [];
    }
    this.getAllTeams();
  }

  getAllTeams() {
    this.apiService.getAllTeams().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.teams = resp.data;

          const team = new Team();
          team.id = 0;
          team.name = 'All Teams';

          this.teams.unshift(team);
        }
        this.loadUsers();
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  loadUsers() {
    const userPromise = this.getUsersByTeam();

    userPromise.then((users) => {
      this.availableUsers = users;
      this.filterCaseAssignees();
    });
  }

  filterCaseAssignees() {
    if (this.caseAssigneRates.caseAssignees) {
      const caseAssigneeIds = new Set(
        this.caseAssigneRates.caseAssignees.map((assignee) => assignee.id)
      );

      // Filter out users from availableUsers that are also in caseAssignees
      this.availableUsers = this.availableUsers.filter(
        (user) => !caseAssigneeIds.has(user.id)
      );
    }
  }

  getUsersByTeam(): Promise<User[]> {
    return new Promise<User[]>((resolve, reject) => {
      this.apiService.getUsersByTeamId(this.selectedTeamId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            resolve(resp.data);
          } else {
            reject(new Error('Failed to get users: ' + resp.status));
          }
        },
        error: (error) => {
          console.error('error:', error);
          reject(error); // Reject the promise on error
        },
      });
    });
  }

  assignUser(_id: number = 0) {
    if (_id === 0 && !this.selectedAvailableUserId) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select a user first.',
        life: 3000,
      });

      return;
    }
    const userToAssign = this.availableUsers.find(
      (x) => x.id === (_id === 0 ? this.selectedAvailableUserId : _id)
    );
    userToAssign.restrictLocation = true;
    if (userToAssign) {
      this.caseAssigneRates.caseAssignees = [
        ...this.caseAssigneRates.caseAssignees,
        userToAssign,
      ];
      this.availableUsers = this.availableUsers.filter(
        (x) => x.id !== (_id === 0 ? this.selectedAvailableUserId : _id)
      );
      this.selectedAvailableUserId = null;
    }
  }

  assignAllUsers() {
    this.caseAssigneRates.caseAssignees = [
      ...this.caseAssigneRates.caseAssignees,
      ...this.availableUsers,
    ];
    this.availableUsers = [];
    this.selectedAvailableUserId = null;
  }

  unAssignUser(_id: number = 0) {
    if (_id === 0 && !this.selectedAssignedUserId) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select a user first.',
        life: 3000,
      });

      return;
    }
    const userToUnAssign = this.caseAssigneRates.caseAssignees.find(
      (x) => x.id === (_id === 0 ? this.selectedAssignedUserId : _id)
    );
    var id = userToUnAssign.id.toString();
    if (userToUnAssign.id > 0) {
      this.apiService.checkCaseUserTaskMapping(this.case.id, id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            if (resp.data.length > 0) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail:
                  'This user cannot be unassigned because there is already a task assigned to them.',
                life: 3000,
              });
            } else {
              if (userToUnAssign) {
                this.availableUsers = [...this.availableUsers, userToUnAssign];
                this.caseAssigneRates.caseAssignees =
                  this.caseAssigneRates.caseAssignees.filter(
                    (x) =>
                      x.id !== (_id === 0 ? this.selectedAssignedUserId : _id)
                  );
                this.selectedAssignedUserId = null;
              }
            }
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  unAssignAllUsers() {
    var ids = this.caseAssigneRates.caseAssignees
      .map((assignee) => assignee.id)
      .join(',');
    this.apiService.checkCaseUserTaskMapping(this.case.id, ids).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          if (resp.data.length > 0) {
            const caseAssigneeIds = new Set(
              resp.data.map((assignee) => assignee.userId)
            );

            // Filter out users from availableUsers that are also in caseAssignees
            var list = this.caseAssigneRates.caseAssignees.filter(
              (user) => !caseAssigneeIds.has(user.id)
            );
            this.availableUsers = [...this.availableUsers, ...list];
            this.caseAssigneRates.caseAssignees =
              this.caseAssigneRates.caseAssignees.filter((user) =>
                caseAssigneeIds.has(user.id)
              );
            var names = resp.data
              .map((assignee) => assignee.userName)
              .join(',');
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail:
                names +
                ' cannot be unassigned because a task is already assigned.',
              life: 3000,
            });
          } else {
            this.availableUsers = [
              ...this.availableUsers,
              ...this.caseAssigneRates.caseAssignees,
            ];
            this.caseAssigneRates.caseAssignees = [];
            this.selectedAssignedUserId = null;
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  addUser() {
    // this.caseAssigneRates.caseAssignees.push(new CaseAssignees());
  }

  teams: Team[] = [];
  selectedTeamId: number = 0;

  onTeamSelect(event: any) {
    this.selectedTeamId = Number(event.value);
    this.loadUsers();

    // if (!this.selectedTeams) this.selectedTeams = [];
    // let user = event.value;
    // user.selected = true;
    // this.selectedTeams.push(user);
    // this.selectedTeamId = 0;
  }

  validateAssignees() {
    const userIds = new Set<number>(); // Set to track unique user IDs
    const rates = new Map<number, number>(); // Map to store userId and their rates

    for (let service of this.caseAssigneRates.caseAssignees) {
      if (this.case.billableTypeId !== 1) {
        // service.ratesPerHour = 0;
      } else {
        if (!service.ratesPerHour || service.ratesPerHour <= 0) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Please enter rates per hour against user.',
            life: 3000,
          });
          return false;
        }
      }
      if(!service.restrictLocation){
        service.restrictLocation = false;
      }
      if (userIds.has(service.id)) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Assignees cannot be duplicated.',
          life: 3000,
        });
        return false; // If duplicate user ID found, return false
      }
      userIds.add(service.id);
      if (rates.has(service.id)) {
        if (rates.get(service.id) !== service.ratesPerHour) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Assignees cannot have different rates per hour.',
            life: 3000,
          });
          return false; // If the same user has different rates, return false
        }
      } else {
        rates.set(service.id, service.ratesPerHour); // Store the userId and rate
      }
    }
    debugger;
    this.caseAssigneRates.caseAssigneesJson = JSON.stringify(
      this.caseAssigneRates.caseAssignees
    );
    this.caseAssigneRates.clientId = this.clientInfo.id;
    this.caseAssigneRates.caseId = this.case.id;
    return true; // Validation passed
  }

  onSubmit() {
    if (this.validateAssignees()) {
      this.apiService
        .addUpdateCaseAssigneRates(this.caseAssigneRates)
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: resp.message,
                life: 3000,
              });
              this.dialogRef.close({ success: true });
              //this.getCase();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
    }
  }

  closeModal(): void {
    this.dialogRef.close({ success: false });
  }
}
