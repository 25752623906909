import { Component } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/Models/Users';
import { ActivatedRoute } from '@angular/router';
import { DealsFilter, Deals, DealsData } from 'src/app/Models/Deals';
import { Remarks } from 'src/app/Models/Remarks';
import { DealStatus } from 'src/app/Models/Status';
import { Result } from 'src/app/Models/Result';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DealviewComponent } from '../dealview/dealview.component';
import { AdddealsComponent } from '../adddeals/adddeals.component';
import { SharedService } from 'src/app/services/shared.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { GlobalVariables } from 'src/environments/environment';
declare var swal: any;

@Component({
  selector: 'app-manage-deals',
  templateUrl: './manage-deals.component.html',
  styleUrls: ['./manage-deals.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ManageDealsComponent {
  public hideCol: Boolean = true;
  public statusTypeId: number = 3; // Incase of deal component
  public slideState = 'right';
  public dealsFilter: DealsFilter = new DealsFilter();
  public deals: Deals = new Deals();
  public logs: Remarks = new Remarks();
  public allDeals: Deals[] = [];
  public dealStatusList: DealStatus[] = [];
  public usersList: User[] = [];
  public allLogs: Remarks[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];
  public totalCount: number = 0;
  public currentPage: number = 1;
  public totalPages: number = 0;
  public pageSize: number = 10;
  public displayedPages: number[] = [];
  public statusId: number;
  public pageHeading: string;

  //#region refrence storing variables for model
  public addLogModal: any;
  //#endregion

  dropdownSettingsUsers = {
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: 'There is no item availabale to show',
    singleSelection: false,
    enableCheckAll: true,
    clearSearchFilter: true,
  };
  addDealComponentHeader: string;

  constructor(
    public modalService: DialogService,
    private apiService: ApiService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private calendar: NgbCalendar,
    private sharedService: SharedService,
    private messageService: MessageService
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.loadData();
    });

    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
    this.getAllUsers();
  }

  ngOnInit(): void {}

  public loadData(): void {
    this.dealsFilter.statusId = this.statusId;
    this.dealsFilter.projectId = GlobalVariables.selectedProjectId;
    this.dealsFilter.pageNumber = this.currentPage;
    this.dealsFilter.pageSize = this.pageSize;
    this.getDealstatus();
    this.getAllDeals();
    this.sharedService.triggerChildLoaded();
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onStatusFilterChange(id: number) {
    this.dealsFilter.statusId = id;
    this.statusId = id;
    this.getAllDeals();
  }

  public onSearchRequest(filters: DealsFilter) {
    this.toggleFilter();
    this.dealsFilter = filters;
    this.dealsFilter.statusId = this.statusId;
    this.dealsFilter.projectId = GlobalVariables.selectedProjectId;
    this.getAllDeals();
  }

  public addNewDeal(item: Deals | null) {
    this.deals = new Deals();
    this.deals.projectId = GlobalVariables.selectedProjectId;
    this.addDealComponentHeader = 'Add Deal';
    if (item) {
      this.addDealComponentHeader = 'Edit Deal';
      this.deals = item;
      this.deals.statusId = item.statusId;
      this.deals.userIdsString = item.userIdsString;
      this.deals.userIds = item.userIds;
      if (item.userIds && item.userIds.length > 0) {
        const array = this.usersList.filter((item) =>
          this.deals.userIds.includes(item.id)
        );
        this.selectedUers = array;
      } else {
        this.selectedUers = [];
      }
    }
    const modalRef = this.modalService.open(AdddealsComponent, {
      modal: true,
      showHeader: true,
      header: this.addDealComponentHeader,
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        deal: this.deals,
        dealStatusList: this.dealStatusList,
        projectId: GlobalVariables.selectedProjectId,
        selectedUers: this.selectedUers,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllDeals();
    });
  }

  public viewDeal(item: Deals) {
    const modalRef = this.modalService.open(DealviewComponent, {
      modal: true,
      showHeader: false,
      width: '80%',
      contentStyle: {
        overflow: 'hidden',
        padding: '0px 0.8rem 2rem 0.8rem',
        borderRadius: '4px',
      },
      //baseZIndex: 10000,
      maximizable: true,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        data: item,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getAllDeals();
    });
  }

  public onSubmitLog() {
    this.logs.dateCreated = new Date();
    this.logs.userId = this.authService.currentUserValue.id;
    this.logs.isActive = true;
    this.apiService.addUpdateRemarks(this.logs).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.getAllDeals();
          this.messageService.add({
            severity: 'success',
            summary: resp.status,
            detail: resp.message,
            life: 3000,
          });
          this.logs = new Remarks();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
      complete: () => {},
    });
  }

  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addUpdateDeals(this.deals).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.dealsFilter.organizationId = 1;
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });

            this.getAllDeals();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public validateAndSetData() {
    if (!this.selectedUers || this.selectedUers.length === 0) {
      this.toastr.warning('Please select at least one user.');
      return false;
    }

    if (!this.deals.statusId || this.deals.statusId === 0) {
      this.toastr.warning('Please select status');
      return false;
    }

    if (!this.deals.dealOwner) {
      this.toastr.warning('Please fill concern name of this deal owner name');
      return false;
    }

    if (!this.deals.name) {
      this.toastr.warning('Please fill the company name');
      return false;
    }
    this.deals.userIds = this.selectedUers.map((x) => x.id);
    this.deals.createdByUserId = this.authService.currentUserValue.id;
    this.deals.updatedByUserId = this.authService.currentUserValue.id;
    this.deals.dateCreated = new Date();
    return true;
  }

  //// This is the new pattern to write subscribtion of angular methods
  public getDealstatus() {
    this.apiService
      .getDealStatus(this.dealsFilter.projectId, this.statusTypeId)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.dealStatusList = resp.data;
          } else {
            this.dealStatusList = resp.data;
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
        complete: () => {},
      });
  }

  public getAllDeals() {
    this.isLoading = true;
    this.apiService.getDeals(this.dealsFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.allDeals = [];
        if (resp.status === 'success') {
          let data = new DealsData();
          data = resp.data;
          this.totalCount = data.totalCount;
          this.allDeals = data.deals;
          this.allDeals.forEach((l) => {
            if (l.assignees) {
              try {
                l.firstAssignee = l.assignees.split(',')[0];
                const UserObjects = l.assignees.split(',');
                l.userIds = UserObjects.map((user) =>
                  parseInt(user.split(';')[0])
                );
              } catch (e) {
                console.error(e);
              }
            }
          });
          this.generateDisplayedPages();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.usersList = resp.data;
        } else {
          this.usersList = resp.data;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public GetRemarksByDealId(id: number) {
    this.apiService.getRemarksByDealId(id).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.allLogs = resp.data;
        } else {
          this.allLogs = resp.data;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public selectUsers(e: any) {
    const data = e;
    this.selectedUers.push(data);
  }

  onItemDeSelectUsers(item: any) {
    this.selectedUers?.forEach((value: any, index: any) => {
      if (value == item.id) this.selectedUers?.splice(index, 1);
    });
  }

  onSelectAllUsers(items: any) {
    this.selectedUers = items;
  }

  onDeSelectAllIUsers(items: any) {
    this.selectedUers = items;
  }

  selectUsersFilter(e: any) {
    const data = e;
    this.selectedUersFilter.push(data);
  }

  onItemDeSelectUsersFilter(item: any) {
    this.selectedUersFilter?.forEach((value: any, index: any) => {
      if (value == item.id) this.selectedUersFilter?.splice(index, 1);
    });
  }

  onSelectAllUsersFilter(items: any) {
    this.selectedUersFilter = items;
  }

  onDeSelectAllIUsersFilter(items: any) {
    this.selectedUersFilter = items;
  }
  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getAllDeals();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 5; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.getAllDeals();
  }
}
