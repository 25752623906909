import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[emailValidator]',
  providers: [NgModel]
})
export class EmailValidatorDirective implements OnInit {
  private errorMessage: HTMLElement;

  constructor(private el: ElementRef, private control: NgModel) {
    this.createErrorMessage();
  }

  ngOnInit(): void {
    this.control.valueChanges?.subscribe(() => {
      this.validate();
    });
  }

  private createErrorMessage() {
    this.errorMessage = document.createElement('div');
    this.errorMessage.className = 'validation-error text-danger';
    this.errorMessage.style.display = 'none';
    this.el.nativeElement.parentElement.appendChild(this.errorMessage);
  }

  private validate() {
    const value = this.el.nativeElement.value;
    const pattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
    const errors: any = {};

    if (!value) {
      errors['required'] = true;
      this.showError('*Email is required.');
    } else if (!pattern.test(value)) {
      errors['pattern'] = true;
      this.showError('*Follow the correct format (e.g., user@example.com).');
    } else {
      this.hideError();
    }

    this.control.control?.setErrors(Object.keys(errors).length ? errors : null);
  }

  private showError(message: string) {
    this.errorMessage.textContent = message;
    this.errorMessage.style.display = 'table';
  }

  private hideError() {
    this.errorMessage.style.display = 'none';
  }

  @HostListener('blur') onBlur() {
    this.validate();
  }
}
