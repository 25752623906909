import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/Models/Result';
import { Services } from 'src/app/Models/Services';
import { TasksTemp, TasksTemplate } from 'src/app/Models/SystemTemplates';
import { Task } from 'src/app/Models/Task';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-task-add-template',
  templateUrl: './task-add-template.component.html',
  styleUrl: './task-add-template.component.css',
})
export class TaskAddTemplateComponent {
  public task: Task = new Task();
  taskTemp: TasksTemplate = new TasksTemplate();
  selectedTask: TasksTemp = new TasksTemp();
  isSystemTemplate: boolean;
  systemTemplate: any = [];
  servicesDbList: Services[] = [];
  selectedTemplate: any = null;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.task = this.dialogConfig.data.task;
  }
  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getTemplate();
    this.getServices();
  }
  getTemplate() {
    this.apiService.getSystemTemplates().subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          this.systemTemplate = resp.data;
           this.systemTemplate = this.systemTemplate.filter(x=>x.orgId === this.authService.currentUserValue.organizationId);
          this.selectedTemplate = this.systemTemplate[0];

          // this.loadHrmsEmployees(this.selectedLocation.ID);
        }
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An unknown error occurred',
          life: 3000,
        });
      },
    });
  }
  public modalClose() {
    this.dialogRef.close();
  }
  filterByTemplate() {
    console.log(this.selectedTemplate);
  }
  public getServices(): void {
    this.apiService.getServices().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.servicesDbList = resp.data;
          const selectedService = this.servicesDbList.find(
            (x) => x.id === this.task.serviceId
          );

          if (selectedService) {
            this.task.categoryId = selectedService.categoryId;
          } else {
            this.task.categoryId = null; // Or handle it accordingly if no match is found
          }
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  onSubmit() {
    debugger;
   this.taskTemp = new TasksTemplate();
   this.selectedTask = new TasksTemp();
   this.selectedTask.Title = this.task.title;
   this.selectedTask.Description = this.task.description;
   this.selectedTask.TaskStatusId = this.task.taskStatusId;
   this.selectedTask.TaskPriorityId = this.task.taskPriorityId;
   this.selectedTask.AcceptanceCriteria = this.task.acceptanceCriteria;
   this.selectedTask.CategoryId = this.task.categoryId;
   this.selectedTask.ServiceId = this.task.serviceId;
   this.taskTemp.taskData = JSON.stringify(this.selectedTask);
   this.taskTemp.systemTemplateId = this.selectedTemplate.id;
   this.taskTemp.createdBy = this.authService.currentUserValue.id;
   this.apiService.addSingleTaskToTemplate(this.taskTemp).subscribe({
    next: (resp: Result) => {
      this.taskTemp = new TasksTemplate();
      if (resp.status === 'success') {
        this.modalClose();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: resp.message,
          life: 3000,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: resp.message,
          life: 3000,
        });
      }
    },
  });
  }
}
