import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[inputValidator]',
  providers: [NgModel]
})
export class InputFieldValidatorDirective implements OnInit {
  @Input() min: number = 3;
  @Input() max: number = 255;
  @Input() required: boolean = false;
  private errorMessageContainer: HTMLElement;

  constructor(private el: ElementRef, private control: NgModel) {
    this.createErrorMessageContainer();
  }

  ngOnInit(): void {
    this.control.valueChanges?.subscribe(() => {
      this.validate();
    });
  }

  private createErrorMessageContainer() {
    this.errorMessageContainer = document.createElement('div');
    this.errorMessageContainer.className = 'validation-error text-danger';
    this.errorMessageContainer.style.display = 'none';
    this.el.nativeElement.parentElement.appendChild(this.errorMessageContainer);
  }

  private validate() {
    const value = this.el.nativeElement.value;
    const minLength = this.min || 3;
    const maxLength = this.max || 60;
    const required = this.required || false;
    const errors: any = {};

    this.errorMessageContainer.innerHTML = ''; // Clear previous messages

    if (!value && required) {
      errors['required'] = true;
      // this.appendError('*required.');
    } else if (value && value.length < minLength) {
      errors['minlength'] = true;
      this.appendError(`Minimum required length must be at least ${minLength} characters long.`);
    } else if (value.length > maxLength) {
      errors['maxlength'] = true;
      this.appendError(`It looks like you have exceeded the character limit.`);
    } else {
      this.hideError();
    }

    this.control.control?.setErrors(Object.keys(errors).length ? errors : null);
  }

  private appendError(message: string) {
    const errorDiv = document.createElement('div');
    errorDiv.textContent = message;
    this.errorMessageContainer.appendChild(errorDiv);
    this.errorMessageContainer.style.display = 'table';
  }

  private hideError() {
    this.errorMessageContainer.style.display = 'none';
  }

  @HostListener('blur') onBlur() {
    this.validate();
  }
}
