import { Component } from '@angular/core';
import { TimeSheetEntry, TimeSheetFilters } from 'src/app/Models/Task';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { padZero, parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.css'],
})
export class TimeSheetComponent {
  timeSheetData: TimeSheetEntry[] = [];
  startDate: string;
  endDate: string;
  weekTotalHours: number = 0;
  usersList: User[] = [];
  selectedAssignees: User;
  selectedUserId: number = 0;
  expandedRows = {};
  isLoading = true;

  sheetTypes: any = [
    { Id: 1, type: 'Daily', seleced: false },
    { Id: 2, type: 'Weekly', seleced: true },
    { Id: 3, type: 'Monthly', seleced: false },
    { Id: 4, type: 'Custom', seleced: false },
  ];

  selectedSheetType = { Id: 2, type: 'Weekly', seleced: true };

  constructor(
    private apiService: ApiService,
    private authService:AuthService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.setCurrentWeekDates();
    this.getAllUsers();
  }

  setCurrentWeekDates(): void {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1; // First day is the day of the month - the day of the week + 1 (for Monday)
    const last = first + 6; // Last day is the first day + 6

    const firstDay = new Date(today.setDate(first));
    const lastDay = new Date(today.setDate(last));

    this.startDate = firstDay.toISOString().split('T')[0];
    this.endDate = lastDay.toISOString().split('T')[0];

    this.getTimeSheetData();
  }

  applyFilter() {
    this.getTimeSheetData();
  }

  // Method to move to the previous week
  previousWeek(): void {
    const start = new Date(this.startDate);
    start.setDate(start.getDate() - 7);
    this.startDate = start.toISOString().split('T')[0];

    const end = new Date(this.endDate);
    end.setDate(end.getDate() - 7);
    this.endDate = end.toISOString().split('T')[0];

    this.getTimeSheetData();
  }

  // Method to move to the next week
  nextWeek(): void {
    const start = new Date(this.startDate);
    start.setDate(start.getDate() + 7);
    this.startDate = start.toISOString().split('T')[0];

    const end = new Date(this.endDate);
    end.setDate(end.getDate() + 7);
    this.endDate = end.toISOString().split('T')[0];

    this.getTimeSheetData();
  }

  getTimeSheetData(): void {
    const filters: TimeSheetFilters = new TimeSheetFilters();
    filters.StartDate = new Date(this.startDate);
    filters.EndDate = new Date(this.endDate);
    filters.UserId = this.selectedUserId;

    this.apiService.getTimeSheetData(filters).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.processTimeSheetData(resp.data).then((data) => {
            this.timeSheetData = data;
            this.timeSheetData.forEach((x) => {
              // Assuming taskdetails is an object with properties to be parsed to lowercase
              x.taskDetails = parseJSONToLowerCaseFirstChar(x.taskDetails);
              if(!this.authService.hasPermission('time-tracking-user')){
                x.taskDetails = x.taskDetails.filter(x=> x.assigneeName === this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.lastName );
              }
            });
            this.calculateOverallTotalHours(data);
          });
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching time sheet data:', error);
      },
    });
  }

  processTimeSheetData(rawData: TimeSheetEntry[]): Promise<TimeSheetEntry[]> {
    const groupedTasks = new Map<number, TimeSheetEntry>();

    return new Promise((resolve, reject) => {
      try {
        rawData.forEach((entry) => {
          const taskId = entry.taskId;
          const date = entry.date;

          if (!groupedTasks.has(taskId)) {
            groupedTasks.set(taskId, {
              ...entry,
              totalHours: 0,
              actualHoursByDate: {},
            });
          }

          // Update groupedTasks (currently synchronous processing)
          if (!groupedTasks.get(taskId).actualHoursByDate[date]) {
            groupedTasks.get(taskId).actualHoursByDate[date] = 0;
          }
          groupedTasks.get(taskId).actualHoursByDate[date] += entry.actualHours;

          groupedTasks.get(taskId).totalHours += entry.actualHours || 0;
        });

        resolve(Array.from(groupedTasks.values()));
      } catch (error) {
        reject(error);
      }
    });
  }

  calculateOverallTotalHours(data: TimeSheetEntry[]): void {
    this.weekTotalHours = data.reduce((accumulator, currentEntry) => {
      // Add totalHours from each entry to the accumulator
      return accumulator + currentEntry.totalHours;
    }, 0);
  }

  getDaysRange(startDate: string, endDate: string): string[] {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = [];
    let current = start;

    while (current <= end) {
      days.push(current.toISOString().split('T')[0]);
      current.setDate(current.getDate() + 1);
    }

    return days;
  }

  getActualHours(task: TimeSheetEntry, date: string): number {
    const entry = this.timeSheetData.find(
      (e: TimeSheetEntry) => e.taskId === task.taskId && e.date === date
    );
    return entry ? Math.round(entry.actualHours * 100) / 100 : 0;
  }

  getTotalHours(task: TimeSheetEntry): number {
    return Math.round((task.totalHours || 0) * 100) / 100;
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          if(this.authService.hasPermission('time-tracking-user'))
            {
              this.usersList = [{ id: 0, name: 'All Users' }, ...resp.data];
              this.selectedAssignees = this.usersList[0];
            }
            else{
              this.usersList = resp.data.filter(x => x.id === this.authService.currentUserValue.id);
              this.selectedAssignees = this.usersList[0];
            }


        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  onUserSelect($event: any) {
    this.selectedUserId = $event.value.id;
  }

  getTotalEstimatedHours(): number {
    return this.timeSheetData.reduce(
      (total, task) => total + task.estimatedHours,
      0
    );
  }

  getTotalActualHoursByDate(day): number {
    return this.timeSheetData.reduce((total, task) => {
      return total + (task.actualHoursByDate[day] || 0);
    }, 0);
  }

  getGrandTotalHours(): number {
    return this.timeSheetData.reduce(
      (total, task) => total + this.getTotalHours(task),
      0
    );
  }
  expandAll() {
    this.expandedRows = this.timeSheetData.reduce(
      (acc, p) => (acc[p.taskId] = true) && acc,
      {}
    );
  }

  collapseAll() {
    this.expandedRows = {};
  }
}
