// mention-plugin.ts
import { getMentionsPlugin } from 'prosemirror-mentions';
import { Plugin } from 'prosemirror-state';
import { ApiService } from '../services/api.service';
import { Result } from '../Models/Result';

/**
 * IMPORTANT: outer div's "suggestion-item-list" class is mandatory. The plugin uses this class for querying.
 * IMPORTANT: inner div's "suggestion-item" class is mandatory too for the same reasons
 */
var getMentionSuggestionsHTML = (items) =>
  '<div class="suggestion-item-list">' +
  items
    .map((i) => '<div class="suggestion-item">' + i.name + '</div>')
    .join('') +
  '</div>';

/**
 * IMPORTANT: outer div's "suggestion-item-list" class is mandatory. The plugin uses this class for querying.
 * IMPORTANT: inner div's "suggestion-item" class is mandatory too for the same reasons
 */
var getTagSuggestionsHTML = (items) =>
  '<div class="suggestion-item-list">' +
  items
    .map((i) => '<div class="suggestion-item">' + i.tag + '</div>')
    .join('') +
  '</div>';

export const mentionPlugin = (apiService: ApiService): Plugin => {
  return getMentionsPlugin({
    getSuggestions: async (type, text, done, state) => {
      setTimeout(async () => {
        if (type === 'mention' && text) {
          try {
            await apiService.getAllUsers(1).subscribe({
              next: (resp: Result) => {
                if (resp.status === 'success') {
                  done(resp.data.slice(0, 10));
                }
              },
              error: (error) => {
                // Handle error
                console.error('error:', error);
              },
            });
          } catch (error) {
            console.error('Error fetching users:', error);
            done([]);
          }
        } else if (type === 'tag' && text) {
          done([
            {
              tag: 'WikiLeaks',
            },
            {
              tag: 'NetNeutrality',
            },
          ]);
        } else {
          done([
            {
              tag: 'WikiLeaks',
            },
            {
              tag: 'NetNeutrality',
            },
          ]);
        }
      }, 0);
    },
    getSuggestionsHTML: (items, type) => {
      if (type === 'mention') {
        return getMentionSuggestionsHTML(items);
      } else if (type === 'tag') {
        return getTagSuggestionsHTML(items);
      } else {
        return null;
      }
    },
  });
};
