<div>
  <div class="modal-head-container" *ngIf="role.isSystemRole === false">
    <div type="button" class="border-0 btn btn-info btn-sm action-button mb-2" (click)="onSubmit()">Save changes</div>
    <div type="button" class="border-0 btn btn-info btn-sm action-button ml-5 mb-2" (click)="selectAllNodes()">Select
      All</div>
    <div type="button" class="border-0 btn btn-info btn-sm action-button ml-2 mb-2" (click)="unselectAllNodes()">
      Unselect All</div>
  </div>

  <p-tree *ngIf="files.length > 0" [value]="files" selectionMode="checkbox" [(selection)]="selectedFiles"
    class="w-full md:w-30rem" (onNodeSelect)="onNodeSelect($event)" (onNodeUnselect)="onNodeUnselect($event)">

    <ng-template let-node pTemplate="default">
      <i [class]="node.data.className"> </i>
      <span> {{ node.label }}</span>
    </ng-template>
  </p-tree>
</div>
