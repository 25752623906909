import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { CaseStatistics } from 'src/app/Models/CostAndHoursMonthlyTrend';
import {
  TaskCost,
  CostingFilters,
  TaskCostBreakout,
} from 'src/app/Models/TaskCost';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  padZero,
  parseJSONToLowerCaseFirstChar,
  printDiv,
} from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-cost-details',
  templateUrl: './cost-details.component.html',
  styleUrls: ['./cost-details.component.css'],
  providers: [CurrencyPipe],
})
export class CostDetailsComponent {
  costingData: TaskCost[] = [];
  costingBreakoutItems: TaskCostBreakout[] = [];
  filters: CostingFilters = new CostingFilters();
  startDate: any | null;
  endDate: any | null;
  isBillable: any = '';
  expandedRows = {};
  isLoading = true;
  filterVisible = false;
  caseCount: number = 0; //zero represents null in api level which will return all data;
  caseWiseData: CaseStatistics[] = [];
  currentYear: number = new Date().getFullYear();
  dataTypeId: number = 1; // Example typeId, this can be set dynamically
  reportTypes: { id: number; label: string }[] = [
    { id: 1, label: 'Task Wise' },
    { id: 2, label: 'Case Wise' },
  ];
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private currencyPipe: CurrencyPipe
  ) {
    this.resetFilters();
  }

  ngOnInit(): void {
    this.getCostingData();
  }

  getCostingData(): void {
    this.isLoading = true;
    this.apiService.getCostingData(this.filters).subscribe({
      next: (resp: { status: string; data: TaskCost[] }) => {
        if (resp.status === 'success') {
          this.costingData = resp.data;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching costing data:', error);
      },
    });
  }

  getCostingBreakout(taskId: number): void {
    const task = this.costingData.find((item) => item.taskId === taskId);
    task.isLoadingBreakout = true;

    this.apiService.getCostingBreakout(taskId).subscribe({
      next: (resp: { status: string; data: TaskCostBreakout[] }) => {
        if (resp.status === 'success') {
          this.costingBreakoutItems = resp.data;

          if (task) {
            task.breakOut = this.costingBreakoutItems;
            task.taskWarnings = this.getTaskIssues(task);

            task.isLoadingBreakout = false;
          }

          //
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching costing data:', error);
      },
    });
  }

  getTaskMessages(task: TaskCost, cellType: string): any {
    const message = { type: '', details: '' };
    const totalHoursExceeded = task.totalHours > task.estimatedHours;
    const totalCostExceeded = task.totalCost > task.estimatedCost;

    if (cellType === 'hours') {
      if (totalHoursExceeded) {
        message.type = 'warn';
        message.details = `Total hours (${task.totalHours.toFixed(
          2
        )}) exceed estimated hours (${task.estimatedHours.toFixed(2)})`;
      } else {
        message.type = 'info';
        message.details = `Estimated hours (${task.estimatedHours.toFixed(2)})`;
      }
    }

    if (cellType === 'cost') {
      if (totalCostExceeded) {
        message.type = 'warn';
        message.details = `Total cost (${this.currencyPipe.transform(
          Number(task.totalCost),
          'Rs '
        )}) exceeds estimated cost (${this.currencyPipe.transform(
          task.estimatedCost,
          'Rs '
        )})`;
      } else {
        if (totalHoursExceeded && task.totalCost === 0 && task.isBillable) {
          message.type = 'warn';
          message.details = `This task has a configuration issue: Total hours (${task.totalHours.toFixed(
            2
          )}) exceeded the estimated hours (${task.estimatedHours.toFixed(
            2
          )}), and the total cost is zero for a billable task.`;
        } else {
          message.type = 'info';
          message.details = `Estimated cost (${this.currencyPipe.transform(
            task.estimatedCost,
            'Rs '
          )})`;
        }
      }
    }

    return message;
  }

  getTaskIssues(task: TaskCost) {
    const issues = [];
    const hoursIssue = this.getTaskMessages(task, 'hours');
    const costIssue = this.getTaskMessages(task, 'cost');

    if (hoursIssue) {
      issues.push(hoursIssue);
    }

    if (costIssue) {
      issues.push(costIssue);
    }

    return issues;
  }

  getCaseStatisticsByOrgId(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .getCaseStatisticsByOrgId(this.caseCount, this.currentYear)
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.caseWiseData = resp.data;
              resolve();
            } else {
              // Handle cases where the response status is not 'success'
              reject(new Error('Failed to get totals: ' + resp.status));
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
            reject(error); // Reject the promise on error
          },
        });
    });
  }

  dataTypeChange(event: SelectButtonChangeEvent) {
    this.dataTypeId = Number(event.value);

    if (this.dataTypeId === 1) {
      this.getCostingData();
    } else {
      this.getCaseStatisticsByOrgId();
    }
  }

  applyFilter() {
    if (this.startDate) {
      this.filters.startDate = new Date(
        this.startDate.year,
        this.startDate.month - 1,
        this.startDate.day
      );
    }
    if (this.endDate) {
      this.filters.endDate = new Date(
        this.endDate.year,
        this.endDate.month - 1,
        this.endDate.day
      );
    }

    this.filters.isBillable =
      this.isBillable === '' ? null : this.isBillable === 'true';

    this.getCostingData();
  }

  resetFilters() {
    const today = new Date();
    this.filters.startDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );

    this.filters.endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23, 59, 59
    );

    this.filters.billableTypeId = 0;
    this.filters.clientName = '';
    this.filters.taskTitle = '';
    this.filters.isBillable = null;
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }

  toggleExpand(task: TaskCost): void {
    this.collapseAll(task);
    task.expanded = !task.expanded;
    if (task.expanded && !task.breakOut) {
      this.getCostingBreakout(task.taskId);
    }
  }

  expandAll() {}

  collapseAll(task: TaskCost) {
    this.costingData.forEach((item) => {
      if (item != task) item.expanded = false;
    });
  }

  print() {
    const id =
      this.dataTypeId === 1 ? 'cost-detail-table' : 'case-wise-detail-table';
    printDiv(id);
  }
  exportAsExcel(tableData: any[], sheetName: string, fileName: string): void {
    // Create a worksheet from the array of objects
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableData);
  
    // Create a workbook
    const workbook: XLSX.WorkBook = {
      Sheets: { [sheetName]: worksheet },
      SheetNames: [sheetName]
    };
  
    // Export the workbook
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }
  // exportCostDetails(){
  //   if (this.dataTypeId === 1) {
  //     this.getCostingData();
  //   } else {
  //     this.getCaseStatisticsByOrgId();
  //   }
  //   if(this.dataTypeId === 1 ){
  //     const costDetails = [];
  //     debugger;
  //     this.costingData.forEach(task => {
  //       // Add the main task row
  //       costDetails.push({
  //         'Task Title': `${this.padZero(task.taskId, 3)} - ${task.taskTitle}`,
  //         'Client Name': task.clientName,
  //         'Engagement Name': task.caseName,
  //         'Billable Type': task.billableType,
  //         'Is Billable': task.isBillable ? 'Yes' : 'No',
  //         'Total Hours': task.totalHours.toFixed(2),
  //         'Total Cost': task.totalCost.toFixed(2),
  //         'User Name': '',  // Leave blank for main task row
  //         'User Hours': '', // Leave blank for main task row
  //         'User Cost': ''   // Leave blank for main task row
  //       });
    
  //       // Add rows for each breakOut entry related to the task
  //       if (task.breakOut && task.breakOut.length > 0) {
  //         task.breakOut.forEach(item => {
  //           costDetails.push({
  //             'Task Title': '', // Leave blank for breakOut rows
  //             'Client Name': '',
  //             'Engagement Name': '',
  //             'Billable Type': '',
  //             'Is Billable': '',
  //             'Total Hours': '',
  //             'Total Cost': '',
  //             'User Name': item.userName,
  //             'User Hours': item.totalHours.toFixed(2) + ' H',
  //             'User Cost': item.totalCost.toFixed(2) + ' Rs'
  //           });
  //         });
  //       }
  //     });

  //     this.exportAsExcel(costDetails, 'Cost_Details', 'Cost_Details_Report');
  //   }
  //   else{
  //     const caseWiseDetails = this.caseWiseData.map((item, index) => ({
  //       '#': index + 1,
  //       'Engagement Name': item.caseName,
  //       'Client Name': item.clientName,
  //       'Total Hours': item.hoursSpent.toFixed(2),
  //       'Total Cost': item.totalCost.toFixed(2),
  //       'Billable Hours': item.billableHours.toFixed(2),
  //       'Non Billable Hours': item.nonBillableHours.toFixed(2)
  //     }));
    
  //     this.exportAsExcel(caseWiseDetails, 'Case_Wise_Details', 'Case_Wise_Details_Report');
  //   }
  
  // }
}
