export interface INotificationThreshold {
  name: string;
  value: number;
}

export class NotificationThreshold {
  GetThresholds(): INotificationThreshold[] {
    const thresholds: INotificationThreshold[] = [];

    thresholds.push({ name: 'Never', value: 0 });

    for (let i = 25; i <= 90; i += 5) {
      thresholds.push({ name: `${i}%`, value: i });
    }

    return thresholds;
  }
}
