<div class="modal-body p-3">
    <div class="d-flex flex-column align-items-start ">
        <h6 class="mb-2">
            <i class="fas fa-info text-primary me-1"></i> Select & Add Task To Customize Template
        </h6>
        <p>By choosing the template you are adding this task to the template.</p>
    </div>
    <hr>
    <div class="form-group">

        <label for="templateSelect" class="fw-600 me-2">Template Type:</label>
        <p-dropdown id="templateSelect" [options]="systemTemplate" [(ngModel)]="selectedTemplate" optionLabel="name"
            placeholder="Select a template" (onChange)="filterByTemplate()"  appendTo="body">
        </p-dropdown>
    </div>


    <hr class="mb-4">


    <div class="text-right">
        <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">
            Add To Template</button>
        <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
</div>