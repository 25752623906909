import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-noactivity',
  template: ` <div
    style="
  display: flex;
  width: 100%;
  height: calc(100vh - 350px);
  justify-content: center;
  align-items: center;
  flex-direction: column;"
  >
    <i
      class="fa-duotone fa-file-invoice"
      style="
  font-size: 10rem;
  opacity: 0.1;"
    ></i
    ><span
      class="mt-3"
      style="text-align: center;font-size: 1rem;font-weight: 500;"
      >{{message}}</span
    >
  </div>`,
  styleUrls: ['./noactivity.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoactivityComponent {
  @Input() message: string = 'No activity has been made yet';
}
