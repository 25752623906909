<ng-container *ngIf="sharedService.isProjectChangeInProc">
  <app-project-loading-indicator />
</ng-container>

<ng-container *ngIf="!sharedService.isProjectChangeInProc">
  <div [@slide]="slideState" class="filter-container">
    <app-leads-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
  </div>

  <div class="d-flex justify-content-between align-items-center">
    <h5>
      Manage Leads
    </h5>
  </div>

  <div class="search-container">
    <div data-toggle="buttons">
      <label class="btn btn-outline-secondary btn-sm form-check-inline" style="border-color: #ccc">
        <input type="radio" name="options" id="option1" (click)="onStatusFilterChange(0)" checked> All
      </label>

      <label *ngFor="let status of leadStatusList"
        class="btn btn-outline-secondary btn-sm form-check-inline btn-status-check"
        [style]="leadsFilter.statusId === status.id ? 'background-color:' +  status.color + ';color: white' : 'border-color: #e7e7e7'">
        <input type="radio" name="options" id="option1" (click)="onStatusFilterChange(status.id)"> {{status.name}}
      </label>
    </div>


    <div class="d-flex align-items-center justify-content-end">
      <button class="btn btn-primary action-button me-2" (click)="addNewLead(null)"><i class="fa-light fa-plus"></i>
        New</button>
      <button class="btn btn-sm btn-outline-dark action-button-outline" (click)="toggleFilter()"><i
          class="fa-light fa-filter-list"></i>
        Search</button>
    </div>
  </div>

  <div class="card-body">

    <div class="table-responsive lead-table" style="overflow-y: auto; height: calc(100vh - 235px);" *ngIf="
                        allleads != null && allleads.length > 0;
                      else !isLoading ? noData : loader
                    ">
      <table class="table table-hover">
        <thead>
          <tr>
            <th style="width: 20px;">#</th>
            <th style="width: 200px;">Lead Name</th>
            <th>Status</th>
            <th style="width: 85px;">Last Updated</th>
            <th style="width: 85px;">Date Created</th>
            <th>Assignee</th>
            <th style="width: 130px;">Contact Person</th>
            <th style="width: 130px;">Concern Person</th>
            <th style="width: 130px;">Phone No</th>
            <th style="width: 130px;">Mobile No</th>
            <th style="width: 150px;">Email</th>
            <th>Lead Owner</th>
            <th>Updated By</th>
            <!-- <th>Due Date</th> -->
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of allleads;let rowIndex = index">
            <td>
              <div class="">{{ item.rowNumber }}</div>
            </td>
            <td class="leadname-td">
              <div class="d-flex align-items-center leadname-container">

                <div class="leadname">{{ item.name }}</div>
                <div class="leadname-btn-group">
                  <button (click)="viewLead(item)" title="View Lead" class="action-button-tbl-sm"><i
                      class="fa-regular fa-eye"></i>
                  </button>
                  <button (click)="addNewLead(item)" title="Edit Lead" class="action-button-tbl-sm"><i
                      class="fa-solid fa-pencil"></i>
                  </button>
                  <button (click)="openEventAddEditView(item.id)" class="action-button-tbl-sm"
                    title="Schedule an Event"><i class="fa-regular fa-calendar"></i>
                  </button>
                </div>
              </div>
            </td>
            <td>
              <div class="badge" [style]="item.color ? 'background-color:'+  item.color : 'background-color:white'">
                <span>{{item.leadStatus}}</span>
              </div>
            </td>
            <td>
              <div class="ellipsis" style="width: 85px;">{{ item.dateUpdated | date }}</div>
            </td>
            <td>
              <div class="ellipsis" style="width: 85px;">{{ item.dateCreated | date }}</div>
            </td>
            <td [title]="item?.firstAssignee">
              <div class="d-flex align-items-center ellipsis">
                <img [src]="item?.firstAssignee?.split(';')[2]" class="assignee-img" />
                <span>{{ item?.firstAssignee?.split(';')[1] }}</span>
              </div>
            </td>

            <!-- <td>{{ item.designation }}</td> -->


            <!-- <td>{{ item.comments }}</td> -->
            <td>
              <div class="ellipsis" style="width: 130px;text-transform: capitalize;">
                {{ item.contactPerson }}
              </div>
            </td>
            <td>
              <div class="ellipsis" style="width: 130px;text-transform: capitalize;">
                {{ item.concernPerson }}
              </div>
            </td>
            <td>
              <div class="ellipsis" style="width: 130px;">{{ !item.phoneNumber ?
                '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - ' :
                item.phoneNumber }}</div>
            </td>
            <!-- <td>{{ item.dueDate | date }}</td> -->
            <td>
              <div class="ellipsis" style="width: 130px;">{{ !item.mobileNumber ?
                '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - ' :
                item.mobileNumber }}</div>
            </td>
            <td>
              <div class="ellipsis" style="width: 150px;">{{ !item.email ? '&nbsp;&nbsp;&nbsp; - ' : item.email }}</div>
            </td>

            <!-- <td>{{ item.status ? item.status : 'NA' }}</td> -->
            <td>
              {{ item.createdBy }}
            </td>

            <td>
              {{ item.updatedBy ? item.updatedBy : 'NA' }}
            </td>
            <td>
              <div class="btn-group">
                <button type="button" class="btn btn-secondary dropdown-toggle btn-sm" style="height: 25px;"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Action
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" (click)="deleteLeadById(item.id)" title="Delete Lead"><i
                      class="fa-regular fa-trash"></i>
                    &nbsp;
                    Delete</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="addDealModal(item)" style="
                background-color: beige;
            "><i class="fa-regular fa-handshake"></i>
                    &nbsp;
                    Convert to Deal</a>
                  <div class="dropdown-divider"></div>

                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination DIV -->
    <div class="pagination-container">
      <div class="d-flex align-items-center">
        <span class="mr-3">Page {{ currentPage }} of {{ calculateTotalPages(totalCount, pageSize) }} of
          {{totalCount}}</span>
        <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
        width: 68px;
        text-align: center;
        font-size: 12px;">
          <option class="dropdown-item d-flex align-items-center" value="10">10</option>
          <option class="dropdown-item d-flex align-items-center" value="20">20</option>
          <option class="dropdown-item d-flex align-items-center" value="50">50</option>
          <option class="dropdown-item d-flex align-items-center" value="100">100</option>
        </select>
      </div>



      <div style="max-width: 500px;">
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
          <i class="fa-sharp fa-solid fa-angles-left"></i></button>
        <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
        <ng-container *ngFor="let page of displayedPages">
          <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
            [class.active]="page === currentPage">
            {{ page }}
          </button>
        </ng-container>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angle-right"></i></button>
        <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
          [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
            class="fa-sharp fa-solid fa-angles-right"></i></button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <app-project-loading-indicator />
</ng-template>

<ng-template #noData>
  <app-nodatafound message="No lead has been created yet." height="calc(100vh - 315px)"></app-nodatafound>
</ng-template>
