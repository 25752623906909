export class Event {
  id: number;
  eventTypeId: number;
  eventType: string;
  startDateTime: string;
  endDateTime: string;
  title: string;
  description: string;
  reminderTime: string;
  leadId: number;
  createdByUserId: number;
  createdBy: string;
  organizerUserId: number;
  organizer: string;
  status: string;
  backColor: string;
  statusColor: string;
  statusBgColor: string;
  statusId: number;
  isDeleted: boolean;
  locationName?: any;
  locationAddress?: any;
  additionalFieldsJson: string;
  additionalFields?: EventAdditionalField[];
  projectId: number;
}

export class EventAdditionalField {
  id: number;
  eventId: number;
  fieldName: string;
  fieldValue: string;
  isHighlighted: boolean;
  isDeleted: boolean;
}

export class EventFilter {
  // constructor(_projectId) {
  //   this.projectId = Number(_projectId);
  // }
  query: string = '';
  statusId: number = 0;
  eventTypeId: number = 0;
  fromDate: string;
  toDate: string;
  userIds: number[] = [];
  projectId: number = 0;
}
