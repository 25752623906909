import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, filter } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
  startOfDay,
  endOfDay,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { CookieService } from 'ngx-cookie-service';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventTitleFormatter } from 'src/app/provider/event-title-formatter.provider';
import { EventViewComponent } from '../eventview/eventview.component';
import { AddEventComponent } from '../addevent/addevent.component';
import { SharedService } from 'src/app/services/shared.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomCalendarEvent } from 'src/app/interfaces/CustomCalendarEvent';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventFilter } from 'src/app/Models/Event';
import {
  getCurrentMonthStartEndDates,
  getCurrentWeekStartEndDates,
  isCurrentDay,
} from 'src/app/utils/utils';
import { Status } from 'src/app/Models/Status';
import { GlobalVariables } from 'src/environments/environment';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { User } from 'src/app/Models/Users';
import 'src/app/extensions/DateExtensions';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-manageevents',
  templateUrl: './manageevents.component.html',
  styleUrls: ['./manageevents.component.css'],
  styles: [
    `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
  ],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: EventTitleFormatter,
    },
  ],
})
export class ManageEventsComponent {
  @ViewChild('modalContent', { static: true }) modalContent:
    | TemplateRef<any>
    | undefined;

  usersList: User[] = [];

  eventFilters: EventFilter = null;

  calenderView: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  excludeDays = []; //[0, 6];
  showDayName: boolean = false;

  modalData:
    | {
        action: string;
        event: CalendarEvent;
      }
    | undefined;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();
  events: CustomCalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  statuses: Status[] = [];

  constructor(
    public modalService: NgbModal,
    private apiService: ApiService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
  ) {
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
      this.getEvents();
      this.getAllUsers();
    });
  }

  ngOnInit() {
    this.setCookieData();
    this.getStatusByStatusTypeId(4);
    this.getEvents();
    this.getAllUsers();
    this.refresh.next();
  }

  public getEvents() {
    this.eventFilters = new EventFilter();
    this.eventFilters.projectId = GlobalVariables.selectedProjectId;

    if (this.calenderView === CalendarView.Day) {
      //Day View

      // this.eventFilters.fromDate = this.viewDate;
      // this.eventFilters.toDate = this.viewDate;

      //currentDate.setDate(currentDate.getDate() + 1);

      this.eventFilters.fromDate = this.viewDate.toApiFormattedString();
      this.eventFilters.toDate = this.viewDate.toApiFormattedString();
    } else if (this.calenderView === CalendarView.Week) {
      //Week View

      const dates = getCurrentWeekStartEndDates(this.viewDate);

      this.eventFilters.fromDate = dates[0].toApiFormattedString();
      this.eventFilters.toDate = dates[1].toApiFormattedString();
    } else if (this.calenderView === CalendarView.Month) {
      //Month View

      const dates = getCurrentMonthStartEndDates(this.viewDate);

      this.eventFilters.fromDate = dates[0].toApiFormattedString();
      this.eventFilters.toDate = dates[1].toApiFormattedString();
    }
    this.events = [];
    this.apiService.getEvents(this.eventFilters).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          //
          this.events = [];
          resp.data?.forEach((e) => {
            this.events.push({
              start: new Date(e.startDateTime),
              end: new Date(e.endDateTime),
              title: e.title,
              color: {
                primary: '#dbdbdb',
                secondary: e.statusBgColor,
                secondaryText: e.statusColor,
              },
              cssClass:
                this.calenderView === CalendarView.Day ? 'dayView-cell' : '',
              actions: this.actions,
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
              dbValue: e,
            });
          });

          this.sharedService.triggerChildLoaded();
          this.refresh.next();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public getStatusByStatusTypeId(statusTypeId: number): void {
    this.apiService.getStatusByStatusTypeId(statusTypeId).subscribe({
      next: (resp: Result) => {
        this.statuses = [];
        if (resp.status === 'success') {
          this.statuses = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  openEventView(action: string, event: CalendarEvent): void {
    const modalRef = this.dialogService.open(EventViewComponent, {
      ariaLabelledBy: 'modal-basic-title',
      modal: true,
      header: 'Event Details',
      showHeader: true,
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        data: event,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
  }

  openEventAddEditView(action: string, event: CalendarEvent): void {
    const modalRef = this.dialogService.open(AddEventComponent, {
      modal: true,
      header: action + ' Event',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        mode: action,
        data: event,
        users: this.usersList,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
    modalRef.onClose.subscribe((result) => {
      if (result) {
        this.getEvents();
      }
    });
  }

  handleEvent(action: string, event: CalendarEvent): void {
    const modalRef = this.dialogService.open(AddEventComponent, {
      modal: true,
      showHeader: false,
      width: '80%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        mode: action,
        data: event,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors['red'],
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        dbValue: null,
      },
    ];
  }

  deleteEvent(eventToDelete: CustomCalendarEvent) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this event?',
      accept: () => {
        this.apiService
          .deleteEvent(Number(eventToDelete.dbValue.id))
          .subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: resp.status,
                  detail: resp.message,
                  life: 3000,
                });
                this.getEvents();
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: resp.status,
                  detail: resp.message,
                  life: 3000,
                });
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
            },
          });
      },
      reject: () => {},
    });
  }

  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.usersList = resp.data;
        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  setView(view: CalendarView) {
    this.calenderView = view;
    this.getEvents();
  }

  dateChange() {
    this.activeDayIsOpen = false;
    this.getEvents();
  }

  showHideDayName() {
    this.cookieService.set('showDayInEventCard', this.showDayName.toString());
  }

  setCookieData() {
    const showDayNameCookie = this.cookieService.get('showDayInEventCard');
    if (showDayNameCookie !== '') {
      this.showDayName = /^true$/i.test(showDayNameCookie);
    }
  }

  isCurrentDay(date: Date) {
    return isCurrentDay(date);
  }
}
