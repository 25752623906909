import { Task } from "./Task";

export class BulkTaskTemplate {
    id!: number;
    clientId!: number;
    caseId!: number;
    createdBy!: number;
    startDate!: Date;
    endDate!: Date;
    isCreatedInBulk!: boolean;
    taskList: Task[] = [];
    templateData!: string;
    templateType!: string;
    name!: string;
  }
  