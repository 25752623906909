<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">{{cancelButtonText}}</button>
  <button type="button" class="btn btn-danger" (click)="onClose()">{{confirmButtonText}}</button>
</div>
