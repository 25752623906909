import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CheckInRequestDetail } from 'src/app/Models/CheckInRequestDetail';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

declare var $: any;

@Component({
  selector: 'app-attendance-confirmation',
  templateUrl: './attendance-confirmation.component.html',
  styleUrls: ['./attendance-confirmation.component.css'],
})
export class AttendanceConfirmationComponent {
  @Input() message: string =
    "The task has been started. Would you like to mark your attendance for the day as well?";
  @Output() onConfirmed: EventEmitter<{ type: string; confirm: boolean }> =
    new EventEmitter();

  @Input() attendanceType: string = '';
  markAttendanceDetails: CheckInRequestDetail;
  constructor(
    public authService: AuthService,
    private messageService: MessageService,
    public apiService: ApiService
  ) {
  }
  confirmMarkAttendance() {
      this.onConfirmed.emit({
        type: this.attendanceType,
        confirm: true,
      });
      if(navigator.geolocation && navigator.geolocation.getCurrentPosition.length !==0)
        {
      this.hideModal();
        }

  }
  onClose() {
    this.onConfirmed.emit({
      type: this.attendanceType,
      confirm: false,
    }); // Emit false if closed

    this.hideModal();
  }

  showModal(type: string) {
    this.attendanceType = type;
    $('#attendanceConfirmationModal').modal('show');
  }

  hideModal() {
    $('#attendanceConfirmationModal').modal('hide');
  }
  // requestLocation(){
  //   this.confirmMarkAttendance();
  // }
}
