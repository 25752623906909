import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Result } from 'src/app/Models/Result';
import { ServiceCategories, Services } from 'src/app/Models/Services';
import { SystemTemplates, TasksTemp, TasksTemplate } from 'src/app/Models/SystemTemplates';
import { Task, TasksFilter } from 'src/app/Models/Task';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-tasks-to-template',
  templateUrl: './add-tasks-to-template.component.html',
  styleUrl: './add-tasks-to-template.component.css',
})
export class AddTasksToTemplateComponent {
  taskFilter: TasksFilter = new TasksFilter();
  totalPages: number = 0;
  completeTaskList: Task[] = [];
  tasks: Task[] = [];
  bulkTasks: TasksTemplate = new TasksTemplate();
  totalCount: number = 0;
  currentPage = 1;
  pageSize = 50;
  isLoading = false;
  systemTemp: SystemTemplates = new SystemTemplates();
  taskTemplateList: Task[] = [];
  displayedPages: number[] = [];
  selectedServices: any = null;
  serviceCategoryDbList: ServiceCategories[] = [];
  servicesDbList: Services[] = [];
  selectAll: boolean = false;
  selectedTask: TasksTemp[] = [];

  constructor(
    private dialogRef: DynamicDialogRef,
    private apiService: ApiService,
    private messageService: MessageService,
    private authService: AuthService,
    public dialogConfig: DynamicDialogConfig,
    private confirmationService: ConfirmationService
  ) {
    this.systemTemp = this.dialogConfig.data.bulkTasks;
  }
  ngOnInit(): void {
    this.getServices();
    this.getTasks();
    this.getServicesCategory();
  }
  getTasks() {
    this.isLoading = true;
    this.taskFilter.pageNumber = this.currentPage;
    this.taskFilter.pageSize = this.pageSize;
    this.apiService.getAllTasks(this.taskFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.tasks = [];
        if (resp.status === 'success') {
          this.tasks = resp.data;
          if (this.tasks?.length > 0) {
            this.totalCount = this.tasks[0].totalRecords;
            const jsonTemplate = JSON.parse(this.systemTemp.jsonTemplate);

            // Extract the taskId list from the parsed JSON. Assuming it's an array of objects with `taskId` field.
            const taskIdsFromTemplate = jsonTemplate.map(
              (item: any) => item.id
            );

            // Filter tasks and set selected = true for matching taskIds.
            // this.tasks = this.tasks.map((task) => {
            //   if (taskIdsFromTemplate.includes(task.id)) {
            //     task.selected = true;
            //   }
            //   return task;
            // });
            this.tasks.forEach((task) => {
              // Find the service related to the task
              const service = this.servicesDbList.find(
                (service) => service.id === task.serviceId
              );

              if (service) {
                // Assign service name to the task
                task.serviceName = service.serviceName;

                // Find the category for the service
                const category = this.serviceCategoryDbList.find(
                  (cat) => cat.id === service.categoryId
                );
                task.categoryId = service.categoryId;
                if (category) {
                  // Assign category name to the task
                  task.categoryName = category.categoryName;
                }
              }
              this.completeTaskList = this.tasks;
            });
            this.generateDisplayedPages();
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
  }
  generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }

  onPageSizeChange(): void {
    this.currentPage = 1;
    this.getTasks();
  }
  public getServicesCategory() {
    this.apiService.getServicesCategory().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          // Adding the "All" option with id = 0
          const allOption = { id: 0, categoryName: 'All' };

          // Set the list with the "All" option at the beginning
          this.serviceCategoryDbList = [allOption, ...resp.data];

          // Select the "All" option by default
          this.selectedServices = this.serviceCategoryDbList[0];
        } else {
          this.serviceCategoryDbList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  filterByService() {
    // var totalTaskList = parseJSONToLowerCaseFirstChar(this.selectedTemplate.jsonTemplate);
    if (this.selectedServices.id === 0) {
      this.tasks = this.completeTaskList;
    } else {
      this.tasks = this.completeTaskList.filter(
        (x) => x.categoryId === this.selectedServices.id
      );
    }
    this.tasks.forEach((task) => {
      const category = this.serviceCategoryDbList.find(
        (cat) => cat.id === task.categoryId
      );
      if (category) {
        task.categoryName = category.categoryName;
      }
      const service = this.servicesDbList.find(
        (cat) => cat.id === task.serviceId
      );
      if (service) {
        task.serviceName = service.serviceName;
      }
    });
  }
  toggleAllCheckboxes() {
    this.tasks.forEach((user) => {
      user.selected = this.selectAll;
    });
  }

  updateSelectAllCheckbox() {
    this.selectAll = this.tasks.every((user) => user.selected);
  }
  modalClose() {
    this.dialogRef.close();
  }
  public getServices(): void {
    this.apiService.getServices().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.servicesDbList = resp.data;

          // this.tempServicesList = this.servicesDbList.map(() => {
          //   const serviceList = new ServicesList();
          //   serviceList.servicesList = [...this.servicesDbList];
          //   return serviceList;
          // });
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  applyFilter(): void {
    this.taskFilter.pageNumber = 1;
    this.getTasks();
  }
  calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getTasks();
  }
  saveSetting() {
    if (this.tasks.filter((x) => x.selected === true).length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You have to select atleast 1 tasks to save it to ur template.',
        life: 3000,
      });
      return;
    }
    this.bulkTasks = new TasksTemplate();
    this.bulkTasks.systemTemplateId = this.systemTemp.id;
    this.bulkTasks.createdBy = this.authService.currentUserValue.id;
    this.bulkTasks.isActive = true;
     this.selectedTask = this.tasks
      .filter((x) => x.selected === true) // Filter only the selected tasks
      .map((task) => ({ // Assuming task.id is the taskId
        Title: task.title,
        Description: task.description,
        TaskStatusId: task.taskStatusId,
        TaskPriorityId: task.taskPriorityId,
        AcceptanceCriteria: task.acceptanceCriteria,
        CategoryId: task.categoryId, // Assuming task.categoryId is the categoryId
        ServiceId:task.serviceId  
      }));
      if (!this.systemTemp.taskList) {
        this.systemTemp.taskList = [];
      }
      this.systemTemp.taskList.push(...this.selectedTask);
    // Convert the resulting array to JSON
    var jsonTasks = JSON.stringify(this.systemTemp.taskList);
    this.bulkTasks.taskData = jsonTasks;
    this.apiService.addTasksTemplate(this.bulkTasks).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.tasks = [];
        if (resp.status === 'success') {
          this.modalClose();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: resp.message,
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
  }
}
