<router-outlet></router-outlet>
<p-confirmDialog [position]="'top'" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<p-toast></p-toast>

<ng-container *ngIf="showNotificationPromptMsg">
  <ng-container *ngIf="notificationPermission === 'default' || notificationPermission === 'prompt'">
    <div class="notification-message">
      <span>We'd like to send you notifications for important updates. Please enable notifications for a better
        experience.</span>
      <button class="notification-button" (click)="requestNotificationPermission()">Enable Notifications</button>
      <button class="cancel-button" (click)="cancelNotification()">Cancel</button>
    </div>
  </ng-container>

  <ng-container *ngIf="notificationPermission === 'denied'">
    <div class="notification-message">
      <span *ngIf="isChromeBrowser()">Notifications are Blocked: To enable notifications in Chrome, click on the padlock
        icon in the address bar,
        go
        to
        Site settings, and change the Notifications setting to 'Allow.'</span>
      <span *ngIf="isFirefoxBrowser()">Notifications are Blocked: To enable notifications in Firefox, click on the
        padlock icon in the address bar,
        go
        to
        Permissions, and set Notifications to 'Allow.'</span>
      <span *ngIf="isSafariBrowser()">Notifications are Blocked: To enable notifications in Safari, open Safari
        Preferences, go to Websites, select
        Notifications, and choose 'Allow' for our website.</span>
      <span *ngIf="isEdgeBrowser()">Notifications are Blocked: To enable notifications in Microsoft Edge, click on the
        padlock icon in the address
        bar,
        go
        to Site permissions, and change Notifications to 'Allow.'</span>

      <button class="cancel-button" (click)="cancelNotification()">Close</button>
    </div>
  </ng-container>
</ng-container>
