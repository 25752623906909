import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Event, EventAdditionalField } from 'src/app/Models/Event';
import { LeadsData, LeadsFilter } from 'src/app/Models/Leads';
import { Result } from 'src/app/Models/Result';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { Status } from 'src/app/Models/Status';
import { User } from 'src/app/Models/Users';
import { AutoCompleteCompleteEvent } from 'src/app/interfaces/AutoCompleteCompleteEvent ';

@Component({
  selector: 'app-addevent',
  templateUrl: './addevent.component.html',
  styleUrls: ['./addevent.component.css'],
})
export class AddEventComponent {
  @Input() mode: string | undefined;
  @Input() data: any | undefined;
  @Input() projectId: any | undefined;
  event: Event = new Event();
  selectedLead: any;

  eventId: number = 0;

  usersList: User[] = [];
  selectedUser: User;
  filteredUsers: User[] | undefined;

  leadFormatter_search = (obj: any) =>
    obj.name +
    (!obj.contactPerson || isNaN(obj.contactPerson)
      ? ''
      : +' - ' + obj.contactPerson) +
    ' - ' +
    obj.leadStatus;
  leadFormatter_selected = (obj: any) =>
    obj.name +
    (!obj.contactPerson || isNaN(obj.contactPerson)
      ? ''
      : +' - ' + obj.contactPerson);

  additionalFields: EventAdditionalField[] = [
    {
      id: 0,
      eventId: 0,
      fieldName: null,
      fieldValue: null,
      isHighlighted: false,
      isDeleted: false,
    },
  ];
  statuses: Status[] = [];
  eventTypes = [
    { id: 1, type: 'Meeting' },
    { id: 2, type: 'Demo' },
    { id: 3, type: 'Visit' },
    { id: 4, type: 'Webinar' },
    { id: 5, type: 'Follow-Up' },
  ];
  isDataSaving: boolean;
  leadId: number = 0;
  private userInfo: any = null;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {
    this.projectId = this.dialogConfig.data.projectId;
    this.data = this.dialogConfig.data.data;
    this.mode = this.dialogConfig.data.mode;
    this.leadId = this.dialogConfig.data.leadId;
    this.usersList = this.dialogConfig.data.users;
    this.getStatusByStatusTypeId(4);
    this.userInfo = this.authService.currentUserValue;
    this.selectedUser = this.userInfo;
  }

  ngOnInit(): void {
    if (this.data) {
      this.event = this.data.dbValue;

      if (this.event.id > 0) {
        this.eventId = this.event.id;
      }

      if (this.event.organizerUserId) {
        const obj = this.usersList.find(
          (user) => user.id === this.event.organizerUserId
        );
        this.selectedUser = obj;
      }

      if (this.event.additionalFieldsJson) {
        this.additionalFields =
          this.event.additionalFieldsJson ||
          this.event.additionalFieldsJson !== ''
            ? parseJSONToLowerCaseFirstChar(this.event.additionalFieldsJson)
            : [];

        this.additionalFields?.forEach((x) => (x.isDeleted = false));
      }

      this.leadId = this.event.leadId;
      if (this.event.leadId) {
        this.getLeadById(this.event.leadId);
      }
    } else {
      // this.event.startDateTime = new Date().toLocaleString();
      // this.event.endDateTime = new Date().toLocaleString();
      // this.event.reminderTime = new Date().toLocaleString();
    }
    if (this.leadId > 0) {
      this.getLeadById(this.leadId);
    } else {
      this.leadId = 0;
    }
  }

  searchUsers(event: AutoCompleteCompleteEvent) {
    let query = event.query;

    const array = this.usersList.filter((item) =>
      item?.name?.toLowerCase()?.includes(query?.toLowerCase())
    );

    this.filteredUsers = array;
  }

  searchLead = (text$: Observable<string>) =>
    text$.pipe(
      // Wait for 200ms after each keystroke
      debounceTime(200),
      // Ignore if the term is the same as before
      distinctUntilChanged(),
      // Switch to a new Observable of API results
      switchMap((term) => this.getLeads(term))
    );

  public getLeads(term): Observable<any[]> {
    const filters = new LeadsFilter();
    filters.query = term;
    filters.projectId = this.projectId;

    return this.apiService.getLeads(filters).pipe(
      map((resp) => (resp.status === 'success' ? resp.data : [])),
      catchError((error) => {
        this.toastr.warning(error.message);
        return of([]);
      })
    );
  }

  public getLeadById(id): void {
    const leadsFilter = new LeadsFilter();
    leadsFilter.id = id;
    leadsFilter.projectId = this.projectId;

    this.apiService.getLeadById(leadsFilter).subscribe({
      next: (resp: Result) => {
        //this.selectedLead = [];
        if (resp.status === 'success') {
          this.selectedLead = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  public getStatusByStatusTypeId(statusTypeId: number): void {
    this.apiService.getStatusByStatusTypeId(statusTypeId).subscribe({
      next: (resp: Result) => {
        this.statuses = [];
        if (resp.status === 'success') {
          this.statuses = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  addField() {
    this.additionalFields.push({
      id: 0,
      eventId: 0,
      fieldName: null,
      fieldValue: null,
      isHighlighted: false,
      isDeleted: false,
    });
  }

  deleteField(index) {
    this.additionalFields[index].isDeleted = true;
  }

  public validateAndSetData() {
    if (this.eventId === 0) {
      this.event.statusId = 15;
    }

    if (!this.event.title || this.event.title === '') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please input title',
        life: 3000,
      });
      return false;
    }

    if (!this.event.eventTypeId || this.event.eventTypeId === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select event type',
        life: 3000,
      });
      return false;
    }

    if (!this.event.startDateTime) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select start date',
        life: 3000,
      });
      return false;
    }

    if (!this.event.endDateTime) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select end date',
        life: 3000,
      });
      return false;
    }

    if (this.event.leadId > 0) {
      if (!this.selectedLead || this.selectedLead.length === 0) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Please select at least one lead',
          life: 3000,
        });
        return false;
      }
    }

    if (this.event.leadId === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select at least one lead',
        life: 3000,
      });
      return false;
    }

    if (!this.event.eventTypeId) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select event type',
        life: 3000,
      });
      return false;
    }

    if (!this.event.statusId) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select status',
        life: 3000,
      });
      return false;
    }

    if (!this.event.reminderTime) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select reminder Date',
        life: 3000,
      });
      return false;
    }

    const startDate = new Date(this.event.startDateTime);
    const endDate = new Date(this.event.endDateTime);

    if (startDate >= endDate) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'End date should be greater than start date',
        life: 3000,
      });
      return false;
    }

    if (!this.selectedUser || !this.selectedUser?.id) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select an event organizer',
        life: 3000,
      });
      return false;
    }

    this.event.additionalFields = this.additionalFields.filter(
      (x) => !x.isDeleted && x.fieldName !== null && x.fieldValue !== null
    );

    this.event.leadId = this.selectedLead?.id;
    this.event.createdByUserId = this.authService.currentUserValue.id;
    this.event.projectId = this.projectId;
    this.event.organizerUserId = this.selectedUser.id;
    //console.log('Adding | Saving EVENTS', this.event.leadId);

    return true;
  }

  saveEvent(): void {
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.insertUpdateEvent(this.event).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });
            this.close(true);
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          this.isDataSaving = false;
          console.error('error:', error);
        },
        complete: () => {
          console.info('called successfully insertUpdateEvent');
        },
      });
    }
  }

  close(callBackRefresh?: Boolean): void {
    if (callBackRefresh) {
      this.dialogRef.close(callBackRefresh);
    }
    this.dialogRef.close(false);
  }
}
