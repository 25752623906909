import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elapsedTimePipe',
})
export class ElapsedTimePipe implements PipeTransform {
  transform(value: number): string {
    // Prevent negative values by clamping at 0
    value = Math.max(0, value);

    let milliseconds = value % 1000;
    let seconds = Math.floor((value / 1000) % 60);
    let minutes = Math.floor((value / (1000 * 60)) % 60);
    let hours = Math.floor((value / (1000 * 60 * 60)) % 24);
    let days = Math.floor(value / (1000 * 60 * 60 * 24));

    let formattedTime = '';
    if (days > 0) {
      formattedTime += days + 'd ';
    }
    formattedTime +=
      this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);

    return formattedTime;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
