import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentSetupComponent } from '../document-setup/document-setup/document-setup.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Result } from 'src/app/Models/Result';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService,MessageService } from 'primeng/api';
import { TreeNode } from 'primeng/api';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import {
  ClientDocuments,
  ClientsFilterDocuments,
} from 'src/app/Models/ClientDocuments';

@Component({
  selector: 'app-client-document-list',
  templateUrl: './client-document-list.component.html',
  styleUrls: ['./client-document-list.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ClientDocumentListComponent {
  files!: TreeNode[];
  public clientFilterDocuments: ClientsFilterDocuments =
    new ClientsFilterDocuments();
  public clientDocuments: ClientDocuments[] = [];
  public clientDetailDocuments: ClientDocuments = new ClientDocuments();
  public isLoading = false;
  clientId: number;
  caseId: number;
  clientInfo: any;
  selectedFile!: any;
  subCategoryId: number;
  path: any;
  showpath: any;
  mainCategory: any;
  subCategory: any;
  message:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private apiService: ApiService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef
  ) {}
  ngOnInit(): void {
    this.subCategoryId = null;
    this.clientDetailDocuments = null;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.clientId = +params['clientId'];
      if (this.clientId > 0) {
        this.apiService.getClientById(this.clientId).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.clientInfo = resp.data;
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      }
    });
    this.getCategories();

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
  transformData(data: any[]): TreeNode[] {
    return data.map((category: any) => ({
      label: category.categoryName,
      data: category,
      children: JSON.parse(category.documentSubCategoryJson).map(
        (sub: any) => ({
          label: sub.SubCategory,
          data: sub,
        })
      ),
    }));
  }
  public getCategories(): void {
    this.apiService.getDocumentcategory().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.files = this.transformData(resp.data);
          this.selectedFile = 'sdasdasd';


        } else {

        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  onNodeSelect(event: any) {
    this.selectedNode = event.node.label;
    if(event.node.parent){
      const slectedParent = event.node.parent.label;
      this.mainCategory = slectedParent;
      this.path = slectedParent + '/' + this.selectedNode;
      this.showpath = slectedParent + ' >> ' + this.selectedNode;
      this.subCategory = this.selectedNode;

      this.subCategoryId = event.node.data.Id;
      this.getClientDocuments();
    }


    this.clientDetailDocuments = null;
    // Perform any actions here, such as fetching data based on selected node
    // For example:
    // this.fetchAdditionalData(selectedNode);
  }
  onCaseIdSelected(caseobj: any) {
    this.caseId = Number(caseobj.id);
    if(caseobj.active === true){
      this.message = false;
    }
    else if(caseobj.active === false){
      this.message = true;
    }
    this.getClientDocuments();
  }
  public addNewDocument(item: any) {
    // this.documentSetupHeader = 'Client Setup';
    const modalRef = this.dialogService.open(DocumentSetupComponent, {
      modal: true,
      showHeader: true,
      header: 'Client Document Setup',
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        client: this.clientInfo,
        path: this.showpath,
        mainCategory: this.mainCategory,
        subCategory: this.subCategory,
        subCategoryId: this.subCategoryId,
        caseId: this.caseId,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getClientDocuments();
      // this.client = new Clients();
      // this.getClients();
    });
  }
  public getClientDocuments(): void {
    this.isLoading = true;
    this.clientFilterDocuments.clientId = this.clientId;
    this.clientFilterDocuments.subCategoryId = this.subCategoryId;
    this.clientFilterDocuments.caseId = this.caseId;
    this.apiService
      .getAllClientDocuments(this.clientFilterDocuments)
      .subscribe({
        next: (resp: Result) => {
          this.isLoading = false;
          if (resp.status === 'success') {

            this.clientDocuments = resp.data;
            if(this.clientDocuments)
              {
                this.clientDocuments.forEach(x=>
                  x.fileSize = parseFloat((x.fileSize / 1024).toFixed(4))
                )
              }
          } else {

          }
        },
        error: (error) => {
          console.error('error:', error);
        },
        complete: () => {},
      });
  }
  showdetails(item: any) {
    this.clientDetailDocuments = item;
  }
  selectedNode:any;
  toggleNode(node) {
    node.expanded = !node.expanded;


  }
  deleteDocument(item: any) {
    var id = item.id;
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        message: 'Do you want to delete this document?',
        accept: () => {
          this.apiService.deleteClientDocumentById(Number(id)).subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: resp.message,
                  life: 3000,
                });
                this.clientDetailDocuments = null;
                this.getClientDocuments();
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: resp.message,
                  life: 3000,
                });
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
            },
          });
        },
        reject: () => {},
      });
    }
  }
