<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-dashboard"></i> Tasks Overview</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Month:</div>
      <p-calendar [(ngModel)]="selectedDate" view="month" dateFormat="MM, yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'170px'" />
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>

  <ng-container *ngIf="sharedService.isProjectChangeInProc">
    <app-project-loading-indicator />
  </ng-container>

  <ng-container *ngIf="!sharedService.isProjectChangeInProc">
    <div [@slide]="slideState" class="filter-container">
      <app-client-filters (onHideRequest)="onHideFilterRequest()" (onSearchRequest)="onSearchRequest($event)" />
    </div>

    <!-- Analytical Dashboard -->
    <div class="row mb-2">
      <div class="col-md-6">
        <div class="alert box-charts m-2 mb-4 row" style="margin-left: 2px !important; margin-right: 2px !important;">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h5 class="card-title card-title-dash card-heading">
              <i class="fas fa-chart-line mr-2"></i> Analytical Graphs
            </h5>

            <div class="d-flex align-items-center justify-content-end">
              <div class="form-group mr-2 mb-0">
                <label for="statusId">From Date</label>
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="fromDate" ngbDatepicker
                    #d1="ngbDatepicker" style="width: 105px;" />
                  <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d1.toggle()"
                    type="button"><i class="fa-solid fa-calendar"></i></button>
                </div>
              </div>
              <div class="form-group mr-2 mb-0">
                <label for="statusId">To Date</label>
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="toDate" ngbDatepicker
                    #d2="ngbDatepicker" style="width: 105px;" />
                  <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d2.toggle()"
                    type="button"><i class="fa-solid fa-calendar"></i></button>
                </div>
              </div>
              <a (click)="calculateStatistics()" class="btn btn-outline-dark action-button-outline mr-2 search-btn"><i
                  class="fa-light fa-search"></i></a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="box-charts" style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 300px;">
              <h5 class="card-title card-title-dash card-heading mb-3">Total Tasks</h5>
              <span style="font-size: x-large;font-weight: bold;">{{totalTasks}}</span>
            </div>
          </div>
          <div class="col-md-8">
            <div class="box-charts" style="height: 300px;">
              <div class="p-24">
                <p-chart type="line" [data]="taskChartData" height="250px" width="100%"></p-chart>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="height:160px">
          <div class="col-md-12">
            <div class="statistics-details d-flex align-items-center justify-content-between">
              <div class="box-shape">
                <p class="statistics-title">To Do</p>
                <h3 class="rate-percentage">
                  <span class="text-medium">
                    {{notStartedTasks}}</span>
                </h3>
                <!-- <div class="d-flex">
                  <small class="text-muted">This week</small>
                  <div class="ms-auto">225</div>
                </div> -->
              </div>
              <div class="box-shape">
                <p class="statistics-title">In Progress</p>
                <h3 class="rate-percentage">
                  <span class="text-medium">
                    {{inProgressTasks}}</span>
                </h3>
              </div>
              <div class="box-shape">
                <p class="statistics-title">Completed</p>
                <h3 class="rate-percentage">
                  <span class="text-medium">
                    {{completedTasks}}</span>
                </h3>
              </div>
              <div class="box-shape">
                <p class="statistics-title">On Hold</p>
                <h3 class="rate-percentage">
                  <span class="text-medium">
                    {{onHoldTasks}}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="box-charts p-3">
          <h5 class="card-title card-title-dash card-heading d-flex justify-content-between align-items-center mb-2">
            {{"Recent Activities" | translate}}</h5>
          <a (click)="goToRecentActivities()" *ngIf="taskActivities !== null && taskActivities.length > 0"
            class="btn btn-outline-dark action-button-outline me-0">
            {{ "View More" | translate }}</a>
          <div class="list-group"
            style="height: calc(100vh - 320px);overflow-y: auto; display: flex; justify-content: center;">
            <ng-container *ngIf="taskActivities !== null && taskActivities.length > 0;  else  noTaskData">
              <div class="activity-item" *ngFor="let m of taskActivities">
                <span class="activity-time">{{m.activityDate | date:'medium'}}</span>
                <div>
                  <i class="activity-icon fas fa-check-circle"></i> {{m.activityDescription | translate}}
                </div>
              </div>
            </ng-container>
            <ng-template #noTaskData>
              <app-nodatafound message="{{'No recent activities have been performed.' | translate }}"></app-nodatafound>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-rounded mb-5">
          <div>
            <h5 class="card-title card-title-dash card-heading align-items-center mb-2">
              <i class="fa-regular fa-chart-user mr-1"></i>
              Top Performer
            </h5>
          </div>
          <div class=card-body>
            <div class=row>
              <div class=col-lg-12>
                <div class="d-flex justify-content-between align-items-center mb-3">
                </div>
                <div class=mt-3>

                  <ng-container *ngIf="topPerformers && topPerformers.length > 0; else noPerformer"></ng-container>
                  <div *ngFor="let performer of topPerformers"
                    class="wrapper d-flex align-items-center justify-content-between py-2 border-bottom">
                    <div class=d-flex [pTooltip]="tooltipContentTasks">
                      <img [src]="performer.profilePictureUrl" class="img-sm rounded top-performer-img" alt="profile" />
                      <div class="wrapper ms-3">
                        <p class="ms-1 mb-1 fw-bold">{{ performer.userName }}</p>
                        <small class="text-muted mb-0 position-relative">Tasks Achievement Rate: <b
                            [ngClass]="{'text-danger': performer.successRatePercentage < 20, 'text-success': performer.successRatePercentage > 20}">{{
                            performer.successRatePercentage}}%</b>
                        </small>
                      </div>
                    </div>
                    <img *ngIf="performer.successRatePercentage > 50" title="Rewarded for achievement above 50%"
                      src="../assets/img/trophy.png" height="24px" />
                    <ng-template #tooltipContentTasks let-data="performer">
                      <div class="flex align-items-center">
                        <p class="performer-tooltip">
                          Tasks Productivity: {{ performer.tasksSuccessRate }} % <br>
                          Completed Tasks: {{ performer.completedTasksCount }}
                        </p>
                      </div>
                    </ng-template>
                  </div>

                  <ng-template #noPerformer>
                    <li class="list-group-item text-center m-4">
                      <i class="far fa-user fa-2x text-muted"></i>
                      <p class="mt-2">Not calculated yet.</p>
                    </li>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- Task Table -->
        <div class="card mb-4">
          <div class="box-charts pl-24 pr-24">
            <div class="card-header d-flex justify-content-between align-items-center mb-2">
              <h5 class="card-title card-title-dash card-heading">{{"Recent Tasks" | translate}}</h5>
              <a (click)="goToTaskList()" *ngIf="tasks !== null && tasks.length > 0;"
                class="btn btn-outline-dark action-button-outline me-0">
                {{ "View More" | translate }}</a>
            </div>
            <div class="card-body" style="overflow: auto;">
              <table class="table table-striped" *ngIf="tasks !== null && tasks.length > 0; else noTaskRecentData">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 20px; padding: 0.75rem;"> #</th>
                    <th>{{"Title" | translate}}</th>
                    <th>{{"Client Name" | translate}}</th>
                    <th>{{"Priority" | translate}}</th>
                    <th>{{"Assignee" | translate}}</th>
                    <th>{{"Due Date" | translate}}</th>
                    <th>{{"Status" | translate}}</th>
                    <!-- <th>{{"Actions" | translate}}</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let task of tasks;let rowIndex = index">
                    <td style="vertical-align: sub;">
                      <div class="">{{ rowIndex + 1 }}</div>
                    </td>
                    <td class="leadname-td">
                      <div class="d-flex align-items-center leadname-container">
                        <div class="leadname m-0">
                          {{ task.title + ' - ' + padZero(task.id,3) }}
                          <p class="task-title-date m-0">Created At: {{ task.dateCreated | date }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="ellipsis" style="max-width: 200px; font-weight: 500">{{ !task.clientName ?
                        '-':task.clientName |
                        translate }}</div>
                    </td>
                    <!-- <td>{{ task.title | translate }}</td> -->
                    <!-- <td>{{ task.caseName | translate }}</td> -->
                    <td>
                      <span class="badge rounded-pill" [style.color]="task.priorityForeColor"
                        [style.backgroundColor]="task.priorityBgColor">{{ task.priorityName | translate }}</span>
                    </td>
                    <td>
                      <div class="likers-group">
                        <ng-container *ngFor="let item of task.taskAssignee.slice(0, 3) ;  let i = index">
                          <img [src]="item.ProfilePictureUrl" [alt]="item.Name" [title]="item.Name" role="button">
                        </ng-container>
                      </div>
                    </td>
                    <td>{{ task.dueDate | date | translate }}</td>
                    <td> <span class="badge rounded-pill" [style.color]="task.taskStatusForeColor"
                        [style.backgroundColor]="task.taskStatusBgColor">{{ task.status | translate }}</span></td>
                    <!-- <td>
                      <button class="btn btn-icon btn-sm btn-soft-secondary mr-2">{{"Edit" | translate}}</button>
                      <button class="btn btn-icon btn-sm btn-soft-danger">{{"Delete" | translate}}</button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <ng-template #noTaskRecentData>
                <app-nodatafound message="{{'No recent tasks has been created.' | translate }}"
                  height="calc(50vh - 200px)"></app-nodatafound>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="{{'message.No client has been created yet.' | translate }}"
      height="calc(100vh - 285px)"></app-nodatafound>
  </ng-template>

</div>
