import { Component } from '@angular/core';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/Models/Users';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  public leeds: Result = new Result();
  public email: string = '';
  public password: string = '';
  public FCMToken: string = '';
  public devieType: string = '';
  deviceInfo = null;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private authService: AuthService,
    private messageService: MessageService,
    private deviceService: DeviceDetectorService,
    private messagingService: MessagingService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.FCMToken = localStorage.getItem('FCMToken');
    this.devieType =
      this.deviceInfo?.browser +
      '(' +
      this.deviceInfo?.browser_version +
      ') ' +
      this.deviceInfo?.deviceType +
      ' ' +
      this.deviceInfo?.os +
      ' ' +
      this.deviceInfo?.os_version;

    // if (!this.FCMToken) {
    //   this.messagingService.init();
    // }
  }

  setToken(bearerToken: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Assume you get the Bearer token after login
      // Set the authentication token in the ApiService
      this.authService
        .setAuthToken(bearerToken)
        .then((result) => {
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  }

  performLogin(): void {
    this.FCMToken = localStorage.getItem('FCMToken');

    // if (!this.FCMToken) {
    //   this.messagingService.init();
    // }

    console.log('FCM Token at login', this.FCMToken);

    const user = {
      username: this.email, //"muhammadrafay",//this.email,//
      password: this.password, //"12",//this.password,//
      FCMToken: this.FCMToken, //"12",//this.password,//
      WebId: this.devieType, //"12",//this.password,//
    };
    //console.log('user', user);//

    this.apiService.login(user).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          var user = <User>resp.data;
          //set user

          setTimeout(() => {
            this.messagingService.init();
          }, 5000);
          user.roleInfo = JSON.parse(user.role);
          this.authService.setCurrentSubject(user);
          this.setToken(resp.data.token)
            .then((result) => {
              this.router.navigate(['/']);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: resp.status,
            detail: resp.message,
            life: 3000,
          });
        }
        // Handle successful login response
        // Access user properties as needed
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
}
