<div *ngIf="isLoading">
    <div class="modal-body text-center d-flex justify-content-center align-items-center flex-column" style="height: 265px;">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="mt-3">Getting Task data, please wait...</div>
    </div>
</div>

<!-- Bulk task template Section -->
<div *ngIf="!isLoading">
    <div class="p-3">
        <div class="d-flex flex-column align-items-start ">
            <h6 class="mb-2">
                <i class="fas fa-info text-primary me-1"></i> Select & Create Customize Task Template
            </h6>
            <p>By choosing the tasks and adding them to your template, you can create tasks in bulk using this template.</p>
        </div>

        <!-- Description for Start Date and End Date -->
        <div class="row ">
            <div class="col-md-2">
                <div class="form-group">
                    <label class="control-label">From Date</label>
                    <p-calendar [(ngModel)]="taskFilter.fromDate" name="startDate"
                        placeholder="Select Start Date" [icon]="'fa-regular fa-calendar'" [showIcon]="true"
                        appendTo="body" [showButtonBar]="true"></p-calendar>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label class="control-label">To Date</label>
                    <p-calendar [(ngModel)]="taskFilter.toDate" name="endDate" placeholder="Select End Date"
                        [icon]="'fa-regular fa-calendar'" [showIcon]="true" appendTo="body"
                        [showButtonBar]="true"></p-calendar>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="control-label">Services:</label>
                    <p-dropdown id="serviceSelect" [options]="serviceCategoryDbList"
                        [(ngModel)]="selectedServices" optionLabel="categoryName" placeholder="Select a service"
                        (onChange)="filterByService()">
                    </p-dropdown>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="statusId">task Title</label>
                    <input type="text" [(ngModel)]="taskFilter.query" (keydown.enter)="applyFilter()" 
                        class="form-control" placeholder="Task Name">
                </div>
            </div>
            <div class="col-md-2 d-flex align-items-end mb-3">
                <button class="btn btn-primary action-button w-100" (click)="applyFilter()">
                    <i class="fas fa-search me-1"></i>Search
                </button>
            </div>
        </div>

        <hr>

        <!-- Task Template and Services Table -->
        <div class="d-flex align-items-center">
            <ng-container *ngIf="!isLoading; else userLoader">
                <div class="table-responsive" style="height: 470px; width: 100%; border: 2px solid #ccc; border-radius: 4px; overflow-y: scroll;">
                    <table class="table table-hover">
                        <thead class="sticky-header">
                            <tr>
                                <th>
                                    <p-checkbox [(ngModel)]="selectAll" [binary]="true" (onChange)="toggleAllCheckboxes()"></p-checkbox>
                                </th>
                                <th>Title</th>
                                <!-- <th style="width:30px; text-align: left;">Description</th>
                                <th style="text-align: left;">Acceptance Criteria</th> -->
                                <th>Category</th>
                                <th>Service</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of tasks">
                                <td>
                                    <p-checkbox [(ngModel)]="user.selected" [binary]="true" [value]="user.selected"></p-checkbox>
                                </td>
                                <td>{{ user.title }}</td>
                                <!-- <td><div [innerHTML]="user.description"></div></td>
                                <td><div [innerHTML]="user.acceptanceCriteria"></div></td> -->
                                <td>{{ user.categoryName }}</td>
                                <td>{{ user.serviceName }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
            </ng-container>
         
            <ng-template #userLoader>
                <div class="p-3">
                    <app-project-loading-indicator [height]="'270px'" [width]="'600px'" [message]="'Fetching templates from task track'"></app-project-loading-indicator>
                </div>
            </ng-template>
        </div>
        <div class="pagination-container">
            <div class="d-flex align-items-center">
              <span class="mr-3">{{"Page" | translate}} {{ currentPage }} {{"of" | translate}} {{
                calculateTotalPages(totalCount, pageSize) }} {{"of" | translate}}
                {{totalCount}}</span>
              <select id="pageSize" class="form-select" [(ngModel)]="pageSize" (change)="onPageSizeChange()" style="
              width: 68px;
              text-align: center;
              font-size: 12px;">
                <option class="dropdown-item d-flex align-items-center" value="10">10</option>
                <option class="dropdown-item d-flex align-items-center" value="20">20</option>
                <option class="dropdown-item d-flex align-items-center" value="50">50</option>
                <option class="dropdown-item d-flex align-items-center" value="100">100</option>
              </select>
            </div>
      
      
      
            <div style="max-width: 500px;">
              <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(1)" [disabled]="currentPage === 1">
                <i class="fa-sharp fa-solid fa-angles-left"></i></button>
              <button class="btn btn-sm btn-link text-dark mr-2" (click)="onPageChange(currentPage - 1)"
                [disabled]="currentPage === 1"><i class="fa-sharp fa-solid fa-angle-left"></i></button>
              <ng-container *ngFor="let page of displayedPages">
                <button class="btn btn-sm" (click)="onPageChange(page)" [class.btn-danger]="page === currentPage"
                  [class.active]="page === currentPage">
                  {{ page }}
                </button>
              </ng-container>
              <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(currentPage + 1)"
                [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
                  class="fa-sharp fa-solid fa-angle-right"></i></button>
              <button class="btn btn-sm btn-link text-dark" (click)="onPageChange(totalPages)"
                [disabled]="currentPage === calculateTotalPages(totalCount, pageSize)"><i
                  class="fa-sharp fa-solid fa-angles-right"></i></button>
            </div>
            </div>
    </div>

    <div class="modal-footer p-3 d-flex justify-content-end">
        <button type="button" class="btn btn-sm btn-secondary me-2" (click)="modalClose()">Close</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="saveSetting()">Save Settings</button>
    </div>
</div>
