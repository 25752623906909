const IndustryTypes = [
  {
    id: 1,
    type: "Technology (IT, Software, Hardware, Telecommunications)"
  },
  {
    id: 2,
    type: "Finance (Banking, Insurance, Investment)"
  },
  {
    id: 3,
    type: "Healthcare (Hospitals, Pharmaceuticals, Medical Devices)"
  },
  {
    id: 4,
    type: "Retail (E-commerce, Brick-and-Mortar Stores)"
  },
  {
    id: 5,
    type: "Manufacturing (Industrial Products, Consumer Goods)"
  },
  {
    id: 6,
    type: "Education (Schools, Universities, Training Providers)"
  },
  {
    id: 7,
    type: "Government (Public Agencies, Non-Profit Organizations)"
  },
  {
    id: 8,
    type: "Media and Entertainment (TV, Radio, Publishing)"
  },
  {
    id: 9,
    type: "Hospitality (Hotels, Restaurants, Travel Agencies)"
  },
  {
    id: 10,
    type: "Professional Services (Law firms, Accounting firms, Consulting firms)"
  }
]

export default IndustryTypes;
