import { Component, Renderer2, ViewChild } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { ClientProfileComponent } from '../client-profile/client-profile.component';
import { Result } from 'src/app/Models/Result';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SharedService } from 'src/app/services/shared.service';
import { AddEventComponent } from '../../events/addevent/addevent.component';
import { GlobalVariables } from 'src/environments/environment';
//import { ClientSetupComponent } from '../client-setup/client-setup.component';
import { Clients, ClientsFilter } from 'src/app/Models/Clients';
import { Country, States, City } from 'src/app/Models/Country';
import { TranslateService } from '@ngx-translate/core';
import { padZero } from 'src/app/utils/utils';
import { User } from 'src/app/Models/Users';
import {
  Task,
  TasksFilter,
  TaskStatus,
  TopPerformants,
} from 'src/app/Models/Task';
import { TaskActivities } from 'src/app/Models/TaskActivities';
import { LeadsConversions } from 'src/app/Models/LeadsConversions';
import { TabView } from 'primeng/tabview';
declare var swal: any;
@Component({
  selector: 'app-manage-tasks',
  templateUrl: './manage-tasks.component.html',
  styleUrls: ['./manage-tasks.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class ManageTasksComponent {
  selectedDate: Date = new Date();
  @ViewChild('tabView') tabView: TabView;
  public statusTypeId: number = 2; // Incase of lead component
  slideState = 'right';
  public clientsFilter: ClientsFilter = new ClientsFilter();
  public task: Task = new Task();
  public taskStaus: TaskStatus = new TaskStatus();
  public taskFilter: TasksFilter = new TasksFilter();

  public taskStatusList: TaskStatus[] = [];
  public taskActivities: TaskActivities[] = [];
  public recentTaskActivitiesList: TaskActivities[] = [];
  topPerformers: TopPerformants[] = [];
  public tasks: Task[] = [];
  //public clients: Clients[] = [];
  public totalCount: number = 0;
  public currentPage = 1;
  public pageSize = 50;
  public countriesList: Country[] = [];
  public statesList: States[] = [];
  public citiesList: City[] = [];
  public displayedPages: number[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public totalPages: number = 0;
  public pageHeading: string;
  //#region refrence storing variables for model
  public addLogModal: any;
  modelHeader: string;
  clientType: string;
  //#endregion

  //Task Related
  // tasks: any[] = [];
  totalTasks: number = 0;
  inProgressTasks: number = 0;
  completedTasks: number = 0;
  onHoldTasks: number = 0;
  notStartedTasks: number = 0;
  taskStatusChartData: any;
  taskChartData: any;
  tasksConversions: LeadsConversions[] = [];
  activeIndex: number = 0;
  userInfo: User = new User();
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public sharedService: SharedService,
    private translate: TranslateService,
    private renderer: Renderer2
  ) {
    this.userInfo = this.authService.currentUserValue;
    this.sharedService.onProjectChange.subscribe((projectId) => {
      // Fetch data based on the new projectId
      GlobalVariables.selectedProjectId = projectId;
      this.applyFilter();
    });
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  ngOnInit(): void {
    this.applyFilter();
  }

  applyFilter() {
    this.loadData().then((t) => {
      //console.log(t);
    });
  }

  loadData(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.clientsFilter.pageNumber = this.currentPage;
      this.clientsFilter.pageSize = this.pageSize;
      this.loadTopPerformers();
      this.calculateStatistics();
      this.prepareCharts();
      this.getRecentActivies();
      this.GetRecentTasks();
      this.sharedService.triggerChildLoaded();

      resolve(true);
    });
  }

  calculateStatistics(): void {
    if (this.fromDate) {
      this.taskStaus.startDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
    }
    if (this.toDate) {
      this.taskStaus.endDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day + 1
      );
    }

    this.apiService.getTaskDashboardData(this.taskStaus).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          var data = resp.data;
          this.totalTasks = data[1];
          this.notStartedTasks = data[0]
            .filter((x) => x.status === 'Not Started')
            .map((x) => x.taskStatusCount);
          if (this.notStartedTasks <= 0) {
            this.notStartedTasks = 0;
          }

          this.inProgressTasks = data[0]
            .filter((x) => x.status === 'In Progress')
            .map((x) => x.taskStatusCount);
          if (this.inProgressTasks <= 0) {
            this.inProgressTasks = 0;
          }
          this.completedTasks = data[0]
            .filter((x) => x.status === 'Completed')
            .map((x) => x.taskStatusCount);
          if (this.completedTasks <= 0) {
            this.completedTasks = 0;
          }
          this.onHoldTasks = data[0]
            .filter((x) => x.status === 'On Hold')
            .map((x) => x.taskStatusCount);
          if (this.onHoldTasks <= 0) {
            this.onHoldTasks = 0;
          }
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  prepareCharts(): void {
    this.apiService.getTasksConversions().subscribe({
      next: (resp: Result) => {
        this.tasksConversions = [];
        if (resp.status === 'success') {
          this.tasksConversions = resp.data;
          if (this.tasksConversions.length > 0) {
            const documentStyle = getComputedStyle(document.documentElement);

            let canvas = this.renderer.createElement('canvas');

            const gradient = canvas
              .getContext('2d')
              .createLinearGradient(244, 102, 255, 100);
            gradient.addColorStop(0, 'rgba(244, 102, 255, 0.2)');
            gradient.addColorStop(1, 'rgba(244, 102, 255, 0)');

            const gradient2 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient2.addColorStop(0, 'rgba(20, 205, 255, 0.2)');
            gradient2.addColorStop(1, 'rgba(20, 205, 255, 0)');

            const gradient3 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient3.addColorStop(0, 'rgba(255, 105, 20, 0.2)');
            gradient3.addColorStop(1, 'rgba(255, 105, 20, 0)');
            const gradient1 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient1.addColorStop(0, 'rgba(0, 153, 0, 0.2)');
            gradient1.addColorStop(1, 'rgba(0, 153, 0, 0)');
            const MonthNames = [
              ...new Set(
                this.tasksConversions.map((l: LeadsConversions) => l.monthName)
              ),
            ];

            this.taskChartData = {
              labels: MonthNames,
              datasets: [
                {
                  type: 'bar',
                  label: 'Completed',
                  fill: true,
                  backgroundColor: gradient1,
                  data: this.tasksConversions
                    .filter((entry: any) => entry.label === 'Completed')
                    .map((entry: any) => entry.data),
                  borderColor: 'rgb(0, 153, 51)',
                  borderWidth: 0,
                },
                {
                  type: 'bar',
                  label: 'To Do',
                  backgroundColor: gradient,
                  data: this.tasksConversions
                    .filter((entry: any) => entry.label === 'Not Started')
                    .map((entry: any) => entry.data),
                  borderColor: 'rgba(255, 22, 139)',
                  borderWidth: 0,
                },
                {
                  type: 'bar',
                  label: 'In Progress',
                  backgroundColor: gradient2,
                  data: this.tasksConversions
                    .filter((entry: any) => entry.label === 'In Progress')
                    .map((entry: any) => entry.data),
                  borderColor: 'rgba(0, 205, 255)',
                  borderWidth: 0,
                },
                {
                  type: 'bar',
                  label: 'On Hold',
                  backgroundColor: gradient3,
                  data: this.tasksConversions
                    .filter((entry: any) => entry.label === 'On Hold')
                    .map((entry: any) => entry.data),
                  borderColor: 'rgba(255, 105, 20)',
                  borderWidth: 0,
                },
              ],
            };
          }
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onSearchRequest(filters: ClientsFilter) {
    this.toggleFilter();
    this.clientsFilter = filters;
    this.currentPage = this.clientsFilter.pageNumber;
    // this.getTasks();
  }

  openEventAddEditView(leadId: number): void {
    const modalRef = this.dialogService.open(AddEventComponent, {
      modal: true,
      header: this.translate.instant('New Event'),
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        projectId: GlobalVariables.selectedProjectId,
        mode: 'New',
        leadId: leadId,
      },
    });
  }

  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    // this.getTasks();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    // this.getTasks();
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  public getRecentActivies(): void {
    this.apiService.getAllTaskActivities().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.taskActivities = resp.data;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
  }
  public GetRecentTasks(): void {
    this.apiService.GetRecentTasksByUserId().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.tasks = resp.data;
          this.tasks.forEach((x) => (x.taskAssignee = JSON.parse(x.assignee)));
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
  }
  goToTaskList() {
    this.router.navigate(['/tasks-list']);
  }
  goToRecentActivities() {
    this.router.navigate(['/tasks-activities']);
  }
  loadTopPerformers() {
    this.topPerformers = [];

    this.apiService.getTopPerformants().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.topPerformers = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
}
