<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-users"></i> {{ "Users" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <span class="me-2 fw-600">Search:</span>
        <input type="text" class="form-control" placeholder="type name or email" [(ngModel)]="searchKeyword"
          (input)="searchUsers()">
      </div>

      <button [hasPermission]="{ access: 'addUpdateTasks', action: 'disable' }"
        class="btn btn-primary action-button text-nowrap ms-5" (click)="addNewUser()"><i class="fa-light fa-plus"></i>
        {{ "Add New User" | translate }}</button>
    </div>
  </div>


  <div class="table-responsive" style="height: calc(100vh - 200px);"
    *ngIf="filteredUsers != null && filteredUsers.length > 0; else !isLoading ? noData : loader">
    <table class="table table-hover">
      <thead>
        <tr class="sticky-header">
        <tr>
          <th scope="col">User</th>
          <th scope="col" class="text-center">Role</th>
          <th scope="col">Email</th>
          <th scope="col">Phone Number</th>
          <th scope="col">User Type</th>
          <th>HRMS Imported</th>
          <th scope="col">Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of filteredUsers; let i = index">
          <td class="d-flex align-items-center position-relative">
            <img
              [src]="user.profilePictureUrl ?? 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'"
              alt="Profile Picture" class="img-thumbnail user-pic">
            <img *ngIf="user.hrmsEmpId && user.hrmsEmpId > 0"
              src="https://play-lh.googleusercontent.com/AFyfgKHcsjblrfKNbP3h4iNmr0GWDFhaGKnvN8Q6xZalLWysX14iMw-yYnuip_pxYhs"
              style="height: 18px;position: absolute;right: 6px;top: 5px;background: white;padding: 2px;">
            <div class="ml-2">
              <p class="mb-0">{{ padZero(user.id, 3) }}</p>
              <a class="mb-0 fw-600 username" (click)="viewUserProfile(user)" tooltipPosition="top"
                title="View Client Profile">{{ user.name }}</a>
              <p class="user-creation-date">{{user.hrmsEmpId && user.hrmsEmpId > 0 ? 'IMPORTED' : 'CREATED:'}} {{
                user.dateCreated | date }}</p>
            </div>
          </td>
          <td class="text-center user-role"><b>{{ user.roleName }}</b></td>
          <td>{{ user.emailAddress }}</td>
          <td>{{ user.phoneNo || 'N/A' }}</td>
          <td>{{ user.userType }}</td>
          <td class="text-center">
            <span class="badge status bg fs-12px"
              [class]="user.hrmsEmpId && user.hrmsEmpId > 0 ? 'not-started' : 'inactive'">{{
              user.hrmsEmpId && user.hrmsEmpId > 0 ? 'YES' : 'NO' }}</span>
          </td>
          <td><span class="bg status badge fs-12px" [class]="user.isActive ? 'active' : 'inactive'">{{ user.isActive ?
              'Active' : 'Inactive' }}</span></td>
          <td>
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ "Options" | translate }}
              </button>
              <div class="dropdown-menu">
                <button class="dropdown-item table-action-btn mb-2"
                  [hasPermission]="{ access: 'addUpdateTasks', action: 'hide' }" (click)="editUser(user)">
                  <i class="fa-regular fa-pencil mr-1"></i>
                  {{"Edit User Information" | translate}}</button>
                <button class="dropdown-item table-action-btn mb-2" (click)="disableUser(user)">
                  <i class="fa-regular fa-user-slash mr-1"></i>
                  {{"Disable User" | translate}}</button>
                <button class="dropdown-item table-action-btn"
                  [hasPermission]="{ access: 'deleteUser', action: 'hide' }" (click)="deleteUser(user)">
                  <i class="fa-regular fa-trash mr-1"></i>
                  {{"Delete User" | translate}}</button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator height="calc(100vh - 205px)" />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="{{'No user created yet.' | translate }}" height="calc(100vh - 205px)"></app-nodatafound>
  </ng-template>

</div>
<!-- <div class="card-body">
    <div class="row">
      <div *ngFor="let user of filteredUsers" class="col-md-3 mb-4">
        <div class="card pt-1">
          <img
            src="{{ user.profilePictureUrl ?? 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'}}"
            class="card-img-top mx-auto d-block user-pic" alt="User Profile Picture">
          <div class="card-body text-center">
            <h5 class="card-title">{{ user.name }}</h5>
            <p class="card-text">{{ user.userName }}</p>
            <p class="card-text">User Type: <b>{{ user.userType }}</b></p>
            <button class="btn btn-primary action-button mr-2" (click)="editUser(user)">Edit User Info</button>
            <button class="btn btn-danger action-button-danger" (click)="deleteUser(user)">Delete User</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
