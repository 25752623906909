<div class="container">

  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-dashboard"></i> Tasks Overview</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Month:</div>
      <p-calendar [(ngModel)]="selectedDate" view="month" dateFormat="MM, yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'170px'" />
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>
  <!-- Overall Task Summary -->
  <div class="row pl-2 pr-2" *ngIf="overview">
    <div class="col p-2">
      <div class="card top-card">
        <div class="card-body">
          <i class="far fa-tasks card-icon total"></i>
          <h5 class="card-title top-card-title">Total Tasks</h5>
          <p class="card-text card-number mb-2">{{overview.summary.totalTasks}}</p>
          <p class="card-text"><span [ngClass]="{
            'status overdue fw-600': overview.summary.totalTasksComparison < 0,
            'status normal fw-600': overview.summary.totalTasksComparison > 0,
            'fw-600': overview.summary.totalTasksComparison === '0'}">
              <i [ngClass]="{
                'fa-solid fa-arrow-down-right': overview.summary.totalTasksComparison < 0,
                'fa-solid fa-arrow-up-right': overview.summary.totalTasksComparison > 0
            }"></i>
              {{ getAbsoluteValue(overview.summary.totalTasksComparison) }}
            </span>{{ getComparisonDirection(overview.summary.totalTasksComparison) }}</p>
        </div>
      </div>
    </div>
    <div class="col p-2">
      <div class="card top-card">
        <div class="card-body">
          <i class="far fa-check-double card-icon completed"></i>
          <h5 class="card-title top-card-title">Completed Tasks</h5>
          <p class="card-text card-number mb-2">{{overview.summary.completedTasks}}</p>
          <p class="card-text"><span [ngClass]="{
            'status overdue fw-600': overview.summary.completedTasksComparison < 0,
            'status normal fw-600': overview.summary.completedTasksComparison > 0,
            'fw-600': overview.summary.completedTasksComparison === '0'
        }">
              <i [ngClass]="{
                'fa-solid fa-arrow-down-right': overview.summary.completedTasksComparison < 0,
                'fa-solid fa-arrow-up-right': overview.summary.completedTasksComparison > 0
            }"></i>
              {{ getAbsoluteValue(overview.summary.completedTasksComparison) }}
            </span>{{ getComparisonDirection(overview.summary.completedTasksComparison) }}</p>
        </div>
      </div>
    </div>
    <div class="col p-2">
      <div class="card top-card">
        <div class="card-body">
          <i class="far fa-clock card-icon pending"></i>
          <h5 class="card-title top-card-title">Pending Tasks</h5>
          <p class="card-text card-number mb-2">{{overview.summary.pendingTasks}}</p>
          <p class="card-text">
            <span [ngClass]="{
              'status normal fw-600': overview.summary.pendingTasksComparison < 0,
              'status overdue fw-600': overview.summary.pendingTasksComparison > 0,
              'fw-600': overview.summary.pendingTasksComparison === '0'
          }">
              <i [ngClass]="{
                  'fa-solid fa-arrow-down-right': overview.summary.pendingTasksComparison < 0,
                  'fa-solid fa-arrow-up-right': overview.summary.pendingTasksComparison > 0
              }"></i>
              {{ getAbsoluteValue(overview.summary.pendingTasksComparison) }}
            </span>
            {{ getComparisonDirection(overview.summary.pendingTasksComparison) }}
          </p>
        </div>
      </div>
    </div>
    <div class="col p-2">
      <div class="card top-card">
        <div class="card-body">
          <i class="far fa-exclamation-triangle card-icon overdue"></i>
          <h5 class="card-title top-card-title">Overdue Tasks</h5>
          <p class="card-text card-number mb-2">{{overview.summary.overdueTasks}}</p>
          <p class="card-text"><span [ngClass]="{
            'status normal fw-600': overview.summary.overdueTasksComparison < 0,
            'status overdue fw-600': overview.summary.overdueTasksComparison > 0,
            'fw-600': overview.summary.overdueTasksComparison === '0'
        }">
              <i [ngClass]="{
                'fa-solid fa-arrow-down-right': overview.summary.overdueTasksComparison < 0,
                'fa-solid fa-arrow-up-right': overview.summary.overdueTasksComparison > 0
            }"></i>
              {{ getAbsoluteValue(overview.summary.overdueTasksComparison) }}
            </span>{{ getComparisonDirection(overview.summary.overdueTasksComparison) }}</p>
        </div>
      </div>
    </div>
    <div class="col p-2">
      <div class="card top-card">
        <div class="card-body">
          <i class="far fa-dollar-sign card-icon overbudget"></i>
          <h5 class="card-title top-card-title">Overbudget Tasks</h5>
          <p class="card-text card-number mb-2">{{overview.summary.overbudgetTasksCount}}</p>
          <p class="card-text"><span [ngClass]="{
            'status normal fw-600': overview.summary.overbudgetTasksCountComparison < 0,
            'status overdue fw-600': overview.summary.overbudgetTasksCountComparison > 0,
            'fw-600': overview.summary.overbudgetTasksCountComparison === '0'
        }">
              <i [ngClass]="{
                'fa-solid fa-arrow-down-right': overview.summary.overbudgetTasksCountComparison < 0,
                'fa-solid fa-arrow-up-right': overview.summary.overbudgetTasksCountComparison > 0
            }"></i>
              {{ getAbsoluteValue(overview.summary.overbudgetTasksCountComparison) }}
            </span>{{ getComparisonDirection(overview.summary.overbudgetTasksCountComparison) }}</p>

        </div>
      </div>
    </div>
    <div class="col p-2">
      <div class="card top-card">
        <div class="card-body">

          <h5 class="card-title top-card-title">Overall Progress</h5>
          <p class="card-icon card-text card-number mb-2">
            <app-circle-progress [progress]="overview.summary.overallTasksProgress" [size]="48" [fontSize]="14"
              [width]="'thin'" />
          </p>
          <!-- <p class="card-text card-number mb-2">
          <p class="card-icon">
            <app-circle-progress [progress]="overview.summary.overallTasksProgress "  [size]="48" [fontSize]="14" [width]="'thin'" />
          </p> -->

          <p class="card-text card-number mb-2"></p>
          <p class="card-text">
          <p class="card-text"><span [ngClass]="{
            'status overdue fw-600': overview.summary.overallTasksProgressComparison < 0,
            'status normal fw-600': overview.summary.overallTasksProgressComparison > 0,
            'fw-600': overview.summary.overallTasksProgressComparison === '0'
        }">
              <i [ngClass]="{
                'fa-solid fa-arrow-down-right': overview.summary.overallTasksProgressComparison < 0,
                'fa-solid fa-arrow-up-right': overview.summary.overallTasksProgressComparison > 0
            }"></i>
              {{ getAbsoluteValue(overview.summary.overallTasksProgressComparison) }}
            </span>{{ getComparisonDirection(overview.summary.overallTasksProgressComparison) }}</p>
        </div>
      </div>
    </div>
  </div>


  <!-- Top Cases by Task Count -->
  <div class="row pl-2 pr-2 mt-3 mb-3">
    <div class="col-md-8">
      <div class="card" style="height: 360px;">
        <div class="card-body">
          <h5 class="card-title card-heading mb-3">Top 5 Engagements</h5>
          <ng-container *ngIf="topClients?.length > 0; else noUser">
          <table class="table table-hover topCasesTable">
            <thead class="thead-light">
              <tr>
                <th>Engagement</th>
                <th class="text-center">Total Tasks</th>
                <th class="text-center">Progress</th>
                <th class="text-center" style="width: 200px;">Planned Dates</th>
                <th class="text-center">Status</th>
                <th class="text-center">Time Spent</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let client of topClients">
                <td>
                  <a class="clientname cursor-pointer" (click)="viewClientProfile(client.id)" tooltipPosition="top"
                    title="View Client Profile">
                    <p class="m-0">
                      {{ client.clientName }}
                    </p>
                    <p class="table-caseName m-0">
                      {{ padZero(client.caseId,3) + ' - ' + client.caseName }}
                    </p>
                  </a>
                </td>
                <td class="text-center">{{ client.totalTasks }}</td>
                <td class="text-center"><app-circle-progress [progress]="client.progress" /></td>
                <td class="text-center">
                  <div class="planned-date">{{ client.plannedDates }}</div>
                </td>
                <td class="text-center">
                  <span class="status on-track bg pill" *ngIf="client.status === 'On Track'">
                    {{ client.status }}
                  </span>
                  <span class="status overdue bg pill" *ngIf="client.status === 'Overdue'">
                    {{ client.status }}
                  </span>
                  <span class="status at-risk bg pill" *ngIf="client.status === 'At Risk'">
                    {{ client.status }}
                  </span>
                  <span class="status completed bg pill" *ngIf="client.status === 'Completed Early'">
                    {{ client.status }}
                  </span>
                 
                </td>
                <td class="text-center">{{ client.timeSpent | number:'1.2-2' }} H</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-template #noUser>
          <div class="no-track">
            <h5>Nothing tracked yet</h5>
          </div>
        </ng-template>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title card-heading mb-3">Workload Engagement Wise</h5>
          <p-chart type="bar" [data]="workloadData" [options]="workloadChartOptions" height="300"></p-chart>
        </div>
      </div>
    </div>
  </div>

  <!-- Monthly Time Spent and Resource Utilization -->
  <div class="row pl-2 pr-2 mt-3">
    <div class="col-md-12 mb-3">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title card-heading mb-3">Monthly Time Spent and Resource Utilization</h5>
          <p-chart type="line" [data]="timeSpentData" [options]="timeSpentChartOptions" height="300"></p-chart>
        </div>
      </div>
    </div>

  </div>
</div>
