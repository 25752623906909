import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageLeadsComponent } from './components/leads/manageleads/manage-leads.component';
import { MasterComponent } from './components/layout/master/master.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './gaurds/auth-guard';
import { ParentErrorComponent } from './components/layout/Error/ParentError/ParentError.component';
import { ManageDealsComponent } from './components/deals/managedeals/manage-deals.component';
import { ManageEventsComponent } from './components/events/manageevents/manageevents.component';
import { OverallAnalyticsComponent } from './components/analytics/overall-analytics/overall-analytics.component';
import { ManageUsersComponent } from './components/users/manage-users/manage-users.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { ProjectsComponent } from './components/projects/manage-projects/projects.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { ClientProfileComponent } from './components/clients/client-profile/client-profile.component';
import { ServicesComponent } from './components/services/manage-services/services.component';
import { ManageTasksComponent } from './components/tasks/manage-tasks/manage-tasks.component';
import { ManageCasesComponent } from './components/clients/client-cases/manage-case/manage-case.component';
import { ClientDocumentListComponent } from './components/clients/client-documents/client-document-list/client-document-list.component';
import { OverviewComponent } from './components/time-tracking/overview/overview.component';
import { MonthlyCalendarComponent } from './components/time-tracking/monthly-calendar/monthly-calendar.component';
import { TimeSheetComponent } from './components/time-tracking/time-sheet/time-sheet.component';
import { CostExplorerComponent } from './components/cost-explorer/cost-explorer/cost-explorer.component';
import { CostDetailsComponent } from './components/cost-explorer/cost-details/cost-details.component';
import { ClientsBudgetsComponent } from './components/cost-explorer/clients-budgets/clients-budgets.component';
import { TaskListComponent } from './components/tasks/task-list/task-list.component';
import { TaskActivityComponent } from './components/tasks/task-activity/task-activity.component';
import { TasksOverviewComponent } from './components/tasks/tasks-overview/tasks-overview.component';
import { PermissionGuard } from './gaurds/permission-gaurd';
import { ManageRolesComponent } from './components/roles-and-permissions/manage-roles/manage-roles.component';
import { UserSetupComponent } from './components/users/user-setup/user-setup.component';
import { ClientLocationsComponent } from './components/clients/client-locations/client-locations.component';
import { ManageTeamsComponent } from './components/users/manage-teams/manage-teams.component';
import { NotAuthorizedComponent } from './components/layout/Error/not-authorized/not-authorized.component';
import { ManageTasksTemplateComponent } from './components/tasks/manage-tasks-template/manage-tasks-template.component';

// Routes that require PermissionGuard
const routesWithPermissionGuard = [
  { path: 'clients', component: ClientListComponent, pageName: 'clients' },
  { path: 'client', component: ClientProfileComponent, pageName: 'client' },
  { path: 'events', component: ManageEventsComponent, pageName: 'events' },
  { path: 'users', component: ManageUsersComponent, pageName: 'users' },
  { path: 'teams', component: ManageTeamsComponent, pageName: 'teams' },
  { path: 'projects', component: ProjectsComponent, pageName: 'projects' },
  { path: 'services', component: ServicesComponent, pageName: 'services' },
  { path: 'tasks', component: ManageTasksComponent, pageName: 'tasks'},
  { path: 'tasks-list', component: TaskListComponent, pageName: 'tasks-list'},
  { path: 'tasks-activities', component: TaskActivityComponent, pageName: 'tasks-activities'},
  { path: 'manage-case', component: ManageCasesComponent, pageName: 'manage-case'},
  { path: 'document', component: ClientDocumentListComponent, pageName: 'document'},
  { path: 'timetracking', component: OverviewComponent, pageName: 'timetracking'},
  { path: 'monthly-cal', component: MonthlyCalendarComponent, pageName: 'monthly-cal'},
  { path: 'timesheet', component: TimeSheetComponent, pageName: 'timesheet'},
  { path: 'cost-explorer', component: CostExplorerComponent, pageName: 'cost-explorer'},
  { path: 'cost-details', component: CostDetailsComponent, pageName: 'cost-details'},
  { path: 'roles', component: ManageRolesComponent, pageName: 'roles'},
  { path: 'addUser', component: UserSetupComponent, pageName: 'addUser' },
  { path: 'manage-client-location', component: ClientLocationsComponent, pageName: 'manage-client-location'},
  { path: 'system-templates', component: ManageTasksTemplateComponent, pageName: 'system-templates'},

];

// Generate guarded routes with canActivate and data attributes
const guardedRoutes = routesWithPermissionGuard.map(route => ({
  path: route.path,
  component: route.component,
  canActivate: [PermissionGuard],
  data: { pageName: route.pageName },
}));

const routes: Routes = [
  {
    path: '',
    component: MasterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OverallAnalyticsComponent,
      },
      {
        path: 'tasks-overview',
        component: TasksOverviewComponent,
        pathMatch: 'full',
      },
      // Additional routes with PermissionGuard
      ...guardedRoutes,
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'invitation',
    component: InvitationComponent,
  },
  {
    path: '**',
    component: NotAuthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
