<div class="modal-body">
    <form class="mt-3">
      <div class="form-group">
        <label for="name"><span class="required">Template Name </span></label>
        <input type="text" class="form-control" id="name" [(ngModel)]="taskTemplate.name" name="name" 
        [min]=2 [max]=50>
      </div>
  
      <div class="form-group">
        <label for="description"><span class="required">Description</span></label>
        <textarea class="form-control" id="description" [(ngModel)]="taskTemplate.templateType" name="description"
          rows="5"  inputValidator
          [min]=3 [max]=255></textarea>
      </div>
  
      <hr class="mb-4">
      
  
      <div class="text-right">
        <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{taskTemplate.id > 0 ?
          'Update Template' : 'Save Template'}}</button>
        <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
      </div>
    </form>
  </div>
  