import { Component, Renderer2 } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Result } from 'src/app/Models/Result';
import { TimeTrackingOverview } from 'src/app/Models/TimeTrackingOverview';
import { ApiService } from 'src/app/services/api.service';
import { randomColors } from 'src/app/utils/utils';
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent {
  overview: TimeTrackingOverview = new TimeTrackingOverview();
  selectedDate: Date = new Date();

  constructor(
    private renderer: Renderer2,
    private apiService: ApiService
  ) {
    this.selectedDate = new Date();
  }

  ngOnInit() {
    this.applyFilter();
  }

  ngAfterViewInit() {}

  loadData(): Promise<TimeTrackingOverview> {
    return new Promise<TimeTrackingOverview>((resolve, reject) => {
      this.apiService
        .getTimeTrackingOverview(
          this.selectedDate.getMonth() + 1,
          this.selectedDate.getFullYear()
        )
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.overview = resp.data;

              if (this.overview) {
                // You can add any additional logic here if needed
              }

              resolve(this.overview); // Resolve the promise when the operation is successful
            } else {
              // Handle cases where the response status is not 'success'
              reject(new Error('Failed to get totals: ' + resp.status));
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
            reject(error); // Reject the promise on error
          },
        });
    });
  }

  applyFilter() {
    this.loadData().then((t) => {
      this.createCharts();
    });
  }

  createCharts() {
    this.loadDailyTimeTrackingChart(this.overview.dailyTimeTracking);
    this.loadWeeklyTimeTrackingChart(this.overview.weeklyTimeTracking);
    this.loadTimeSpentByPriorityChart(this.overview.timeSpentByPriority);
  }

  //#region Daily Time Tracking

  dailyTimeTrackingData: any = null;

  loadDailyTimeTrackingChart(t) {
    let ctx = this.renderer.createElement('canvas');

    const gradient = ctx.getContext('2d').createLinearGradient(100, 0, 50, 150);
    gradient.addColorStop(0, 'rgba(0, 101, 255, 0.2)');
    gradient.addColorStop(1, 'rgba(0, 101, 255, 0)');

    const dates = t.map((t) => new Date(t.date).toLocaleDateString());
    const hours = t.map((t) => t.totalHours.toFixed(0));

    this.dailyTimeTrackingData = {
      labels: dates,
      datasets: [
        {
          label: 'Daily Time Tracking',
          fontFamily: 'Poppins',
          data: hours,
          fill: true,
          pointBorderWidth: 1,
          pointRadius: new Array(hours.length).fill(2),
          pointHoverRadius: new Array(hours.length).fill(4),
          tension: 0.4,
          borderDash: [5, 5],
          borderWidth: 1.5,
          borderColor: 'rgba(0, 101, 255)',
          backgroundColor: gradient,
        },
      ],
    };
  }

  //#endregion

  //#region Weekly Time Tracking

  weeklyTimeTrackingData: any = null;

  loadWeeklyTimeTrackingChart(t) {
    let ctx = this.renderer.createElement('canvas');

    const gradient = ctx.getContext('2d').createLinearGradient(100, 0, 50, 150);
    gradient.addColorStop(0, 'rgba(255, 84, 0, 0.2)');
    gradient.addColorStop(1, 'rgba(255, 84, 0, 0)');

    const dates = t.map((t) => `W${t.week}`);
    const hours = t.map((t) => t.totalHours.toFixed(0));

    this.weeklyTimeTrackingData = {
      labels: dates,
      datasets: [
        {
          label: 'Weekly Time Tracking',
          fontFamily: 'Poppins',
          data: hours,
          fill: true,
          pointBorderWidth: 1,
          pointRadius: new Array(hours.length).fill(2),
          pointHoverRadius: new Array(hours.length).fill(4),
          tension: 0.4,
          borderDash: [5, 5],
          borderWidth: 1.5,
          borderColor: 'rgba(255, 84, 0)',
          backgroundColor: gradient,
        },
      ],
    };
  }

  //#endregion

  //#region Time Spent By Status

  timeSpentByPriorityData: any = null;

  loadTimeSpentByPriorityChart(t: any) {
    if (!t || t.length === 0) {
      this.timeSpentByPriorityData = null;
      return;
    }

    const canvas = this.renderer.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradients = [];
    const hoverColors = [];
    const borderColors = [];

    t.forEach((_, index) => {
      const color = randomColors[index % randomColors.length]; // Cycle through colors if more statuses than colors
      const gradient = ctx.createLinearGradient(0, 0, 300, 0);
      gradient.addColorStop(0, color.start);
      gradient.addColorStop(0.5, color.mid);
      gradient.addColorStop(1, color.end);
      gradients.push(gradient);
      hoverColors.push(color.hover);
      borderColors.push(color.border);
    });

    this.timeSpentByPriorityData = {
      labels: t.map((x) => x.priorityName),
      datasets: [
        {
          data: t.map((x) => x.totalHours),
          backgroundColor: gradients,
          hoverBackgroundColor: hoverColors,
          borderColor: borderColors,
          borderWidth: 2,
        },
      ],
    };
  }

  //#endregion

  //#region Chart Optiions

  chartOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw} hours`;
          },
        },
      },
    },
  };

  chartOptionsDonut: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%', // Adjust the cutout percentage to create the donut effect
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              tooltipItem.label + ': ' + tooltipItem.raw?.toFixed(2) + ' hours'
            );
          },
        },
      },
    },
  };

  //#endregion
}
