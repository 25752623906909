import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Event, EventAdditionalField } from 'src/app/Models/Event';
import { Result } from 'src/app/Models/Result';
import { Leads, LeadsFilter } from 'src/app/Models/Leads';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';
import { LeadviewComponent } from '../../leads/leadview/leadview.component';
@Component({
  selector: 'app-eventview',
  templateUrl: './eventview.component.html',
  styleUrls: ['./eventview.component.css'],
})
export class EventViewComponent {
  @Input() data: any | undefined;
  event: Event = new Event();
  selectedLead: Leads = new Leads();

  projectId: number | undefined;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) {
    this.data = this.dialogConfig.data.data;
    this.event = this.data.dbValue;
    this.projectId = this.dialogConfig.data.projectId;
  }
  ngOnInit(): void {
    if (this.data) {
      this.event = this.data.dbValue;
      this.event.additionalFields = parseJSONToLowerCaseFirstChar(
        this.event.additionalFieldsJson
      );

      try {
        if (Array.isArray(this.event.additionalFields)) {
          this.event.additionalFields.forEach((x) => (x.isDeleted = false));
        } else {
          this.event.additionalFields = [];
        }
      } catch {}

      this.getLeadById(this.event.leadId);
    }
  }

  public getLeadById(id): void {
    const leadsFilter = new LeadsFilter();
    leadsFilter.id = id;
    leadsFilter.projectId = this.projectId;

    this.apiService.getLeadById(leadsFilter).subscribe({
      next: (resp: Result) => {
        this.selectedLead = new Leads();
        if (resp.status === 'success') {
          this.selectedLead = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public viewLead(item: Leads) {
    const modalRef = this.dialogService.open(LeadviewComponent, {
      modal: true,
      showHeader: false,
      header: item.name,
      width: '80%',
      contentStyle: {
        overflow: 'hidden',
        padding: '0px 0.8rem 2rem 0.8rem',
        borderRadius: '4px',
      },
      //baseZIndex: 10000,
      maximizable: true,
      data: {
        item: item,
        projectId: GlobalVariables.selectedProjectId,
      },
    });
  }

  close(): void {
    this.dialogRef.close(true);
  }
}
