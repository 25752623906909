import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSpecialCharsBlocker]'
})
export class SpecialCharsBlockerDirective {

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const initialValue = input.value;

    // Allow only (, ), -, and @
    input.value = initialValue.replace(/[^a-zA-Z0-9()@\-\s]/g, '');


    if (initialValue !== input.value) {
      event.stopPropagation();
    }
  }
}
