import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from './services/messaging.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private isChrome: boolean;
  private isFirefox: boolean;
  private isSafari: boolean;
  private isEdge: boolean;
  private userInfo: any = null;

  public notificationPermission: string = 'default';
  public showNotificationPromptMsg = false;

  constructor(
    public router: Router,
    private messagingService: MessagingService,
    public authService: AuthService,
    private renderer: Renderer2
  ) {
    this.userInfo = this.authService.currentUserValue;
    //this.initNotifications();

    if (window.Notification || Notification.permission === 'granted') {
      if (this.userInfo?.id > 0) {
        this.messagingService.init();
      }
    }
  }

  ngOnInit(): void {
    this.adjustZoomBasedOnResolution();
    window.addEventListener('resize', () => this.adjustZoomBasedOnResolution());
  }

  adjustZoomBasedOnResolution(): void {
    const screenWidth = window.screen.width; //window.innerWidth;
    if (screenWidth >= 1400) {
      this.renderer.setStyle(document.body, 'zoom', '100%');
    } else if (screenWidth >= 1300 && screenWidth <= 1399) {
      this.renderer.setStyle(document.body, 'zoom', '90%');
    } else if (screenWidth >= 1200 && screenWidth <= 1299) {
      this.renderer.setStyle(document.body, 'zoom', '80%');
    } else if (screenWidth >= 768 && screenWidth <= 1199) {
      this.renderer.setStyle(document.body, 'zoom', '80%');
    } else {
      this.renderer.setStyle(document.body, 'zoom', '70%');
    }
  }

  initNotifications() {
    if ('permissions' in navigator) {
      navigator.permissions
        .query({ name: 'notifications' })
        .then((notificationPerm) => {
          //call it here to generate token for updating notifications

          notificationPerm.onchange = () => {
            console.log(
              'User decided to change his settings. New permission: ' +
                notificationPerm.state
            );

            if (this.userInfo?.id > 0) {
              this.notificationPermission = notificationPerm.state;

              if (
                notificationPerm.state === 'prompt' ||
                notificationPerm.state === 'denied'
              ) {
                this.showNotificationPromptMsg = true;
              } else {
                this.showNotificationPromptMsg = false;

                this.messagingService.init();
              }
            }
          };
        });
    }

    this.detectBrowser();
  }

  private detectBrowser(): void {
    const userAgent = window.navigator.userAgent.toLowerCase();

    this.isChrome = /chrome|crios/.test(userAgent);
    this.isFirefox = /firefox/.test(userAgent);
    this.isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent);
    this.isEdge = /edge/.test(userAgent);
    if (this.userInfo?.id > 0) {
      this.getNotificationPermissionState();
    }
  }

  public isChromeBrowser(): boolean {
    return this.isChrome;
  }

  public isFirefoxBrowser(): boolean {
    return this.isFirefox;
  }

  public isSafariBrowser(): boolean {
    return this.isSafari;
  }

  public isEdgeBrowser(): boolean {
    return this.isEdge;
  }

  public getNotificationPermissionState() {
    if (window.Notification) {
      this.notificationPermission = Notification.permission;

      if (
        this.notificationPermission === 'default' ||
        this.notificationPermission === 'denied'
      ) {
        this.showNotificationPromptMsg = true;
      }
    }
  }

  public requestNotificationPermission() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted');
        } else {
          console.warn('Notification permission denied');
        }

        this.notificationPermission = permission;
      });
    }
  }

  public cancelNotification() {
    this.showNotificationPromptMsg = false;
  }
}
