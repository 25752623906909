<div class="modal-body">
  <form class="mt-3">
    <div class="form-group">
      <label for="category" class="d-block"><i class="far fa-globe"></i> Service Category</label>
      <p-dropdown [options]="servicesCategoryList" name="category" optionLabel="categoryName"
        [ngModel]="service.categoryId" (ngModelChange)="onServiceChange($event)" filterBy="categoryName" dataKey="id"
        optionValue="id" placeholder="Select a Category">
      </p-dropdown>
    </div>
    <div class="form-group">
      <label for="name">Name</label>
      <input type="text" class="form-control" id="name" [(ngModel)]="service.serviceName" name="name"
        appSpecialCharsBlocker inputValidator [required]=true [min]=3 [max]=255>
    </div>

    <div class="form-group">
      <label for="description">Description</label>
      <textarea class="form-control" id="description" [(ngModel)]="service.description" name="description" rows="5"
        appSpecialCharsBlocker inputValidator [min]=3 [max]=100></textarea>
    </div>


    <hr class="mb-4">

    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{service.id > 0 ?
        'Update Service' : 'Save Service'}}</button>
      <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>
