export class Remarks {
    id!: number;
    userId!: number;
    leadId!: number;
    dealId!: number;
    name!: string | null;
    emailAddress!: string | null;
    details!: string | null;
    statusId!: number | null;
    status!: string | null;
    leadStatus!: string | null;
    dealStatus!: string | null;
    isActive!: boolean;
    dateCreated!: Date | null;
    isEdit:boolean=false;
}
