import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-loading-indicator',
  templateUrl: './project-loading-indicator.component.html',
  styleUrls: ['./project-loading-indicator.component.css']
})
export class ProjectLoadingIndicatorComponent {
  @Input() message: string = 'Loading Data ...';
  @Input() height: string = 'calc(100vh - 235px)';
  @Input() width: string = '100%';
  @Input() showBtn: boolean = false;
}
