<div class="modal-body">
  <div class="container mt-3">
  <ng-container *ngFor="let item of billableType">
    <div class="form-check card mr-3 mt-2" (click)="onSelectionChange(item)">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <input class="form-check-input radio-input me-3" type="radio" id="{{item.id}}" name="billableType"
            [value]="item.id" [(ngModel)]="selectedBillingTypeId" (change)="onSelectionChange(item)">
          <div class="label-container">
            <label class="mb-1">
              <span class="fw-bold">{{ item.type }}</span>
            </label>
            <p class="text-muted">{{ item.description }}</p>
            <ng-container *ngIf="item.id === 3 && isHours">
              <div class="input-group mt-2">
                <span id="pr-addon" class="currency-box">Rs</span>
                <input type="number" id="ratesPerHour{{item.id}}" class="form-control"
                  style="padding-left: 4rem;border-top-left-radius: 6px;border-bottom-left-radius: 6px;font-weight: 600;"
                  [(ngModel)]="item.ratesPerHour" name="ratesPerHour{{item.id}}">
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="formula">
    <div class="card mt-3">
      <div class="card-body">
        <h6 class="fw-bold">Calculations Rule</h6>
        <p class="text-muted">{{this.formula}}</p>
      </div>
    </div>
  </ng-container>
</div>
</div>
<div class="modal-footer mt-4 pr-2">
  <button type="button" class="border-0 btn btn btn-sm action-button-cancel mr-2 mt-3" style="width: 80px;"
    (click)="closeModal()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()">Save changes</button>
</div>

