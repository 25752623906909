const ClientTypes = [
  {
    id: 1,
    type: "Company"
  },
  {
    id: 2,
    type: "Organization"
  },
  {
    id: 3,
    type: "Non-Profit Organization"
  },
  {
    id: 4,
    type: "Individual"
  }
]

export default ClientTypes;
