interface Date {
  toApiFormattedString(): string;
}

Date.prototype.toApiFormattedString = function () {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    //timeZoneName: 'short',
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(this);
  return formattedDate.replace('24:', '00:').replace(',', '');
};
