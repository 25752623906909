export class Clients {
  id!: number;
  dateCreated!: Date;
  clientName!: string;
  contactName!: string;
  contactEmail!: string;
  contactPhone!: string;
  addressLine1!: string;
  addressLine2!: string;
  cityId!: number;
  stateId!: number;
  zipCode!: string;
  countryId!: number;
  industryType!: string;
  clientType!: string;
  active!: boolean | null;

  rowNumber: number;
  totalRecords: number;
}

export class ClientsFilter
{
  id!: number | null;
  query!: string | null;
  clientType!:string | null;
  fromDate!: Date | null;
  toDate!: Date | null;
  pageNumber!: number;
  pageSize!: number;
}

export class ClientDashboardPerformance
{
  taskCompletionRate!: number | null;
  resourceUtilizationRate!: number | null;
  freeResourceRate!:number | null;
  totalCases!: number | null;
  totalActiveServices!: number | null;
  numberOfAvailableResources!: number | null;
  resourceUtilization!: number | null;
  freeResources!: number | null;
  freeResourcesName!: string | null;
  busyResourcesName!: string | null;
}

export class ClientBudgetLastSetting
{
  billingTypeId!: number | null;
  notificationThreshold!: number | null;
  caseServiceJson!: string | null;
}
