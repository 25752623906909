import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Clients } from 'src/app/Models/Clients';
import { ClientCase, CaseFilters, CaseService } from 'src/app/Models/Case';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { CaseSetupComponent } from '../case-setup/case-setup.component';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';
import { BillingSettingsSetupComponent } from '../billing-settings-setup/billing-settings-setup.component';
import { ManageAssigneeComponent } from '../manage-assignee/manage-assignee.component';
import { AuthService } from 'src/app/services/auth.service';
import { TasksTemplateComponent } from 'src/app/components/tasks/tasks-template/tasks-template.component';

@Component({
  selector: 'app-manage-case',
  templateUrl: './manage-case.component.html',
  styleUrls: ['./manage-case.component.css'],
})
export class ManageCasesComponent {
  clients: Clients[] = [];
  cases: ClientCase[] = [];
  caseFilters: CaseFilters = new CaseFilters();

  // Chart Data
  caseCountByClient: any;
  caseStatusDistribution: any;
  fromDate: any = new Date(new Date().setMonth(new Date().getMonth() - 1));
  toDate: any = new Date();

  // Chart Options
  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
  };
  isLoading: boolean;
  currentPage: number = 1;
  displayedPages: number[];
  pageSize: number = 100;
  totalCount: number;
  totalPages: number;
  clientId: number;
  caseSetupHeader: string;

  clientInfo: any;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private messageService: MessageService,
    private dialogService: DialogService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef
  ) {
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.clientId = +params['clientId'];
      if (this.clientId > 0) {
        this.apiService.getClientById(this.clientId).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.clientInfo = resp.data;
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      }
    });

    this.getCases();
    this.prepareCharts();
  }

  getCases(): void {
    this.isLoading = true;
    this.caseFilters.pageNumber = this.currentPage;
    this.caseFilters.pageSize = this.pageSize;
    this.caseFilters.clientId = this.clientId;
    // if (this.fromDate) {
    //   this.caseFilters.fromDate = new Date(
    //     this.fromDate.year,
    //     this.fromDate.month - 2,
    //     this.fromDate.day
    //   );
    // }
    // if (this.toDate) {
    //   this.caseFilters.toDate = new Date(
    //     this.toDate.year,
    //     this.toDate.month - 1,
    //     this.toDate.day + 1
    //   );
    // }
    this.apiService.getCases(this.caseFilters).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.cases = [];
        if (resp.status === 'success') {
          this.cases = resp.data;
          if (this.cases && this.cases.length > 0) {
            const updatedCases = this.cases.map((caseObj) => {
              caseObj.services = parseJSONToLowerCaseFirstChar(
                caseObj.servicesJson
              );
              return caseObj;
            });
          }
          //For Future In case of pagination
          //this.generateDisplayedPages();
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  prepareCharts(): void {
    // Prepare case count by client chart
    const clientCaseCounts = this.clients.map((client) => {
      const count = this.cases.filter((e) => e.clientId === client.id).length;
      return { clientName: client.clientName, count };
    });

    this.caseCountByClient = {
      labels: clientCaseCounts.map((cec) => cec.clientName),
      datasets: [
        {
          label: 'Engagement per Client',
          data: clientCaseCounts.map((cec) => cec.count),
          backgroundColor: ['#dc3545', '#ffc107', '#28a745'],
        },
      ],
    };

    // Prepare case status distribution chart
    const activeCount = this.cases.filter((e) => e.active).length;
    const inactiveCount = this.cases.length - activeCount;

    this.caseStatusDistribution = {
      labels: ['Active', 'Inactive'],
      datasets: [
        {
          data: [activeCount, inactiveCount],
          backgroundColor: ['#36A2EB', '#FF6384'],
        },
      ],
    };
  }

  updateCase(item: any) {
    this.caseSetupHeader = 'Engagement Info';
    let caseObj = new ClientCase();

    if (item) {
      if (item.active === false) {
        this.caseSetupHeader = 'Engagement Info (closed)';
      } else {
        this.caseSetupHeader = 'Engagement Info';
      }
      caseObj = item;
    }
    const modalRef = this.dialogService.open(CaseSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '60%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getCases();
    });
  }
  getProgressBarClass(progress) {
    if (this.hasPermission() === true) {
      if (progress <= 25) {
        return 'low';
      } else if (progress <= 50) {
        return 'medium';
      } else if (progress <= 90) {
        return 'high';
      } else {
        return 'full';
      }
    } else {
      return null;
    }
  }
  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getCases();
  }

  calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  onPageSizeChange(): void {
    this.currentPage = 1;
    this.getCases();
  }
  addNewCase(item: any) {
    if (this.cases?.filter((e) => e.active).length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'New engagement cannot be opened when there is already an open engagement',
        life: 3000,
      });
    } else {
      this.caseSetupHeader = 'Engagement Info';
      let caseObj = new ClientCase();

      if (item) {
        this.caseSetupHeader = 'Engagement Info';
        caseObj = item;
      }

      const modalRef = this.dialogService.open(CaseSetupComponent, {
        modal: true,
        showHeader: true,
        header: this.caseSetupHeader,
        width: '60%',
        contentStyle: { overflow: 'hidden', padding: '0px' },
        baseZIndex: 10000,
        maximizable: false,
        data: {
          case: caseObj,
          clientInfo: this.clientInfo,
        },
      });
      modalRef.onClose.subscribe(() => {
        this.getCases();
      });
    }
  }
  addBillingSettings(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Billing Settings';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(BillingSettingsSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getCases();
    });
  }
  manageAssignee(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Manage Assignee';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(ManageAssigneeComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getCases();
    });
  }
  hasPermission(): boolean {
    if (this.authService.hasPermission('budget-costing')) {
      return true;
    } else {
      return false;
    }
  }
  addBulkTasks(item: any) {
    let caseObj = new ClientCase();
    if (item) {
      this.caseSetupHeader = 'Task Bulk Creation';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(TasksTemplateComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '60%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe(() => {
      this.getCases();
    });
  }
}
