import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
} from 'rxjs';
import { Clients, ClientsFilter } from 'src/app/Models/Clients';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { ClientCase, CaseService } from 'src/app/Models/Case';
import {
  parseDateFromApi,
  parseJSONToLowerCaseFirstChar,
} from 'src/app/utils/utils';
import { PriorityLevels, TaskStatus, Task } from 'src/app/Models/Task';
import { Tags, TaskTag } from 'src/app/Models/TaskTag';
import { TaskChecklist } from 'src/app/Models/TaskChecklist';
import { TaskDocument } from 'src/app/Models/TaskDocument';
import { DatePipe } from '@angular/common';
import { Editor, Toolbar, schema, toDoc, toHTML } from 'ngx-editor';
import { mentionPlugin } from 'src/app/plugins/MentionsPlugin';
import { AutoCompleteCompleteEvent } from 'src/app/interfaces/AutoCompleteCompleteEvent ';
import { FileSelectEvent, FileUploadEvent } from 'primeng/fileupload';
import { AWSServerCredentials } from 'src/app/Models/AWSServerCredentials';
import { S3 } from 'aws-sdk';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-task-setup',
  templateUrl: './task-setup.component.html',
  styleUrls: ['./task-setup.component.css'],
})
export class TaskSetupComponent implements AfterViewInit {
  @ViewChild('fileUploadInput') fileUpload: FileUpload;
  @ViewChildren('dependencyInput') dependencyInputs!: QueryList<ElementRef>;
  @ViewChild('taskDropdownButton', { static: false })
  taskDropdownButton!: ElementRef;
  task: Task = new Task();

  lists: string[] = ['Onboarding', 'Milestone 2', 'Milestone 3'];
  billableOptions: any[] = [
    { label: 'YES', value: true },
    { label: 'NO', value: false },
  ];
  credentials: AWSServerCredentials = new AWSServerCredentials();
  taskStatuses: TaskStatus[] = [];
  taskPriorties: PriorityLevels[] = [];
  taskDocuments: TaskDocument[] = [];
  taskDocument: TaskDocument = new TaskDocument();
  tags: Tags[] = [];
  assignees: User[] = [];
  filteredAssignees: User[] = undefined;
  selectedFile: any;
  selectedClient: any;
  selectedStatus: any;
  selectedTags: any[];
  selectedAssignees: User[] = [];
  startDate: Date;
  dueDate: Date;
  isDisabled: any = true;
  case: ClientCase[] = [];
  clientcase: ClientCase = new ClientCase();
  selectedCase: ClientCase = new ClientCase();
  services: CaseService[] = [];
  selectedCaseName: string = '';
  isDataSaving: boolean;
  taskId: number;
  billableTypeId: number = 0;
  billableType: string;

  jsonDoc: any;
  jsonDescriptionDoc: any;
  jsonAcceptanceDoc: any;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  isAttachment: boolean = false;
  uploadedFiles: any[] = [];
  isUploading: boolean = false;
  editorDetails: Editor;
  editorDetailsMenu = false;

  editorAcceptance: Editor;
  editorAcceptanceMenu = false;
  toolbar: Toolbar = [
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['bold', 'italic', 'underline', 'strike', 'code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.task = this.dialogConfig.data.task;
    this.taskId = this.task?.id > 0 ? this.task.id : 0;
    if (this.taskId === 0) {
      this.task.isBillable = true;
    }
    if (
      Object.keys(this.task.checklists).length === 0 ||
      this.task.checklists.length === 0
    ) {
      // this.task.checklists = [];
    }

    this.loadContent();
  }

  ngAfterViewInit(): void {
    // this.dependencyInputs.changes.subscribe(() => {
    //   this.focusLastInput();
    // });

    if (this.task.id > 0) {
      if (this.task.startDate) {
        this.task.startDate = new Date(this.task.startDate);
      }
      if (this.task.dueDate) {
        this.task.dueDate = new Date(this.task.dueDate);
      }
    } else {
      this.task.startDate = new Date();
    }
  }

  loadContent(): void {
    this.getTaskStatus();
    this.getPriorityLevels();
    this.getTags().then((resp) => {
      if (this.task.id > 0 && this.task.tagIds && this.task.tagIds.length > 0) {
        this.selectedTags = this.task.tagIds;
      }
    });
    if (this.task.id > 0) {
      this.getClientById(this.task.clientId).then((resp) => {
        this.getCaseById(this.task.caseId).then((resp) => {
          this.selectedCaseName = this.task.caseName;
          this.getCaseAssignees(this.task.caseId).then((resp) => {
            if (this.task.id > 0 && this.task.assigneeIds.length > 0) {
              this.selectedAssignees = this.assignees.filter((assignee) => {
                const matchingTaskAssignee = this.task.taskAssignee.find(
                  (taskAssignee) => taskAssignee.Id === assignee.id
                );
                if (matchingTaskAssignee) {
                  assignee.estimatedHours =
                    matchingTaskAssignee.EstimatedHours ||
                    assignee.estimatedHours;
                  assignee.ratesPerHour =
                    matchingTaskAssignee.RatesPerHour || assignee.ratesPerHour;
                  assignee.selected = true;
                }
                return !!matchingTaskAssignee;
              });
            }
          });
        });
      });
      this.task.checklists = parseJSONToLowerCaseFirstChar(
        this.task.checkListJSON
      );
      if (this.task.documentsJSON !== null) {
        this.task.documents = parseJSONToLowerCaseFirstChar(
          this.task.documentsJSON
        );
      } else {
        this.task.documents = [];
      }

      this.jsonDescriptionDoc = toDoc(this.task?.description);
      this.jsonAcceptanceDoc = toDoc(this.task?.acceptanceCriteria);
    }
  }
  ngOnInit(): void {
    const plugins = [
      mentionPlugin(this.apiService), // Pass ApiService to the plugin
    ];
    const editorOptions = {
      schema,
      plugins,
      keyboardShortcuts: true,
      inputRules: true,
    };

    this.editorDetails = new Editor(editorOptions);
    this.editorAcceptance = new Editor(editorOptions);
  }
  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editorAcceptance.destroy();
  }

  //#region Event Methods
  clientFormatter_search(obj: any) {
    return obj.clientName + ' - ' + obj.id;
  }

  clientFormatter_selected(obj: any) {
    return obj.clientName + ' - ' + obj.id;
  }

  searchClients = (text$: Observable<string>) =>
    text$.pipe(
      // Wait for 200ms after each keystroke
      debounceTime(200),
      // Ignore if the term is the same as before
      distinctUntilChanged(),
      // Switch to a new Observable of API results
      switchMap((term) => this.getClients(term))
    );

  addDependency() {
    if (
      Object.keys(this.task.checklists).length === 0 ||
      this.task.checklists.length === 0
    ) {
      this.task.checklists = [];
    }
    this.task.checklists.push(new TaskChecklist());
  }

  removeDependency(index: number) {
    this.task.checklists.splice(index, 1);
  }

  removeAllDependency() {
    this.task.checklists = [];
  }

  onCaseSelect(event: any) {
    const caseId = event.value;
    const serviceJson = this.case
      .filter((x) => x.id === caseId)
      .map((x) => x.servicesJson)[0];
    this.services = parseJSONToLowerCaseFirstChar(serviceJson);
    this.getCaseAssignees(caseId);
    this.billableTypeId = this.case
      .filter((x) => x.id === caseId)
      .map((x) => x.billableTypeId)[0];
    this.billableType = this.case
      .filter((x) => x.id === caseId)
      .map((x) => x.billableType)[0];
  }

  onClientSelect(event: any) {
    this.selectedClient = event.item;
    this.getCurrentCase(this.selectedClient.id);
  }

  //#endregion Events

  //#region Action Functions
  public validateAndSetData() {
    if (!this.selectedAssignees || this.selectedAssignees.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please add one assignee',
        life: 3000,
      });
      return false;
    }
    // if (!this.selectedTags || this.selectedTags.length === 0) {
    //   this.messageService.add({
    //     severity: 'warn',
    //     summary: 'Warning',
    //     detail: 'Please select at least one user',
    //     life: 3000,
    //   });
    //   return false;
    // }

    if (!this.task.taskStatusId || this.task.taskStatusId === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select status',
        life: 3000,
      });
      return false;
    }

    if (!this.task.title) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the title name',
        life: 3000,
      });
      return false;
    }
    if (this.jsonDescriptionDoc) {
      this.task.description = toHTML(this.jsonDescriptionDoc);
    }
    if (this.jsonAcceptanceDoc) {
      this.task.acceptanceCriteria = toHTML(this.jsonAcceptanceDoc);
    }
    if (!this.selectedClient?.id || this.selectedClient.id === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the correct client name',
        life: 3000,
      });
      return false;
    }
    this.task.clientId = this.selectedClient?.id;
    this.task.assigneeJSON = JSON.stringify(this.selectedAssignees);
    this.task.assigneeIds = this.selectedAssignees.map((x) => x.id);
    this.task.tagIds = this.selectedTags;
    if (this.task.documents.length > 0) {
      this.task.documentsJSON = JSON.stringify(this.task.documents);
    }
    if (this.task.checklists && this.task.checklists.length > 0) {
      this.task.checklists?.forEach((x) => {
        x.userId = this.authService.currentUserValue.id;
      });
      this.task.checkListJSON = JSON.stringify(this.task.checklists);
    }
    if (this.task.checklists.length === 0) {
      this.task.checkListJSON = null;
    }
    this.task.createdBy = this.authService.currentUserValue.id;
    this.task.dateCreated = new Date();
    this.task.startDate = this.getDate(this.task.startDate);
    if(this.task.dueDate){
      this.task.dueDate = this.getDate(this.task.dueDate);
    }
    return true;
  }

  onSubmit() {
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addOrUpdateTask(this.task).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });
            this.modalClose();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          this.isDataSaving = false;
          console.error('error:', error);
        },
        complete: () => {
          console.info('called successfully insertAddUpdateTask');
        },
      });
    }
  }

  getDate(date: any) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
  }

  toggleAssigneesManager(event, assignees) {
    if (this.selectedClient) {
      assignees.toggle(event);
      
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must select a client first',
        life: 3000,
      });
    }
  }

  //#endregion

  //#region  API CALLS / DATA FETCHERS
  editorDetailsFocusToggle(show: boolean) {
    this.editorDetailsMenu = show;
  }

  editorAcceptanceFocusToggle(show: boolean) {
    this.editorAcceptanceMenu = show;
  }

  getClients(term): Observable<any[]> {
    const filters = new ClientsFilter();
    filters.query = term;
    if (!term) {
      return new Observable<any[]>();
    }
    return this.apiService.getMatchingClientNames(filters).pipe(
      map((resp) => (resp.status === 'success' ? resp.data : [])),
      catchError((error) => {
        return of([]);
      })
    );
  }

  getClientById(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getClientById(id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.selectedClient = resp.data;
          } else {
            this.selectedClient = new Clients();
          }
          resolve();
        },
        error: (error) => {
          reject(error);
          // Handle error
        },
      });
    });
  }

  getTaskStatus() {
    this.apiService.getAllTaskStatuses().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.taskStatuses = resp.data;
          if (!this.task?.id) {
            //On first load selectd Status is loaded
            this.task.taskStatusId = this.taskStatuses?.map((x) => x.id)[0];
          }
        } else {
          this.taskStatuses = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  getCurrentCase(clientId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getCurrentCase(clientId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.selectedCase = null;
            this.case = resp.data;
            this.selectedCaseName = this.case[0].caseName;
            this.task.caseId = this.case[0].id;
            const serviceJson = this.case
              .filter((x) => x.id === this.case[0].id)
              .map((x) => x.servicesJson)[0];
            this.services = parseJSONToLowerCaseFirstChar(serviceJson);

            if (!this.task?.id) {
              this.task.serviceId = this.services?.map((x) => x.serviceId)[0];
            }

            this.getCaseAssignees(this.case[0].id);
            this.billableTypeId = this.case
              .filter((x) => x.id === this.case[0].id)
              .map((x) => x.billableTypeId)[0];
            this.billableType = this.case
              .filter((x) => x.id === this.case[0].id)
              .map((x) => x.billableType)[0];
          } else {
            this.selectedCase = new ClientCase();
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }

  getCaseById(caseId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getCaseById(caseId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.clientcase = resp.data;
            this.selectedCaseName = this.clientcase.caseName;
            this.task.caseId = this.clientcase.id;
            const serviceJson = this.clientcase.servicesJson;
            this.services = parseJSONToLowerCaseFirstChar(serviceJson);

            this.getCaseAssignees(this.clientcase.id);
            this.billableTypeId = this.clientcase.billableTypeId;
            this.billableType = this.clientcase.billableType;
          } else {
            this.selectedCase = new ClientCase();
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }

  getTags() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getTags().subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.tags = resp.data;
          } else {
            this.tags = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }

  getPriorityLevels() {
    this.apiService.getPriorityLevels().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.taskPriorties = resp.data;
          if (!this.task?.id) {
            //On first load selectd priority is loaded
            this.task.taskPriorityId = this.taskPriorties?.map((x) => x.id)[1];
          }
        } else {
          this.taskPriorties = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  getAllUsers() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.assignees = resp.data;
          } else {
            this.assignees = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }

  getCaseAssignees(caseId) {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getCaseAssignees(caseId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            if (resp.data.length > 0) {
              this.assignees = resp.data;
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'There are no users assigned to this engagement.',
                life: 3000,
              });
            }
          } else {
            this.assignees = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    });
  }

  filterAssignees(event: AutoCompleteCompleteEvent) {
    let query = event.query.toLowerCase();
    this.filteredAssignees = (this.assignees as any[]).filter(
      (user) =>
        user.name?.toLowerCase().includes(query) &&
        !this.selectedAssignees.some((selected) => selected.id === user.id)
    );
  }

  selectedAssignee: any;

  onAssineeSelect(event: any) {
    event.originalEvent.stopPropagation();

    if (!this.selectedAssignees) this.selectedAssignees = [];

    let user = event.value;
    user.selected = true;

    this.selectedAssignees.push(user);
    this.selectedAssignee = null;
  }

  resetAssinees() {
    this.selectedAssignees = [];
  }

  closeDropdown() {
    this.taskDropdownButton.nativeElement.click();
  }

  //#endregion  API CALLS / DATA FETCHERS

  // handleFileInput(files: FileList, document: TaskDocument) {
  //   document.file = files.item(0);
  //   document.fileLocation = document.file ? document.file.name : '';
  // }

  private focusLastInput() {
    if (this.dependencyInputs.length > 0) {
      const lastInput = this.dependencyInputs.last;
      if (lastInput) {
        lastInput.nativeElement.focus();
      }
    }
  }

  public modalClose() {
    this.dialogRef.close();
  }

  //#region TaskAttachments

  onSelect(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.isUploading = true;
    this.uploadMultipleFiles();
  }

  onRemove(event: any) {
    const index = this.uploadedFiles.findIndex(
      (file) => file.name === event.file.name
    );
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  onRemoveFile(file: TaskDocument) {
    const index = this.task.documents.indexOf(file);
    if (index > -1) {
      this.task.documents.splice(index, 1);
    }
  }

  onRemoveUploadedFile(file: any) {
    const index = this.uploadedFiles.indexOf(file);
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  uploadMultipleFiles(): void {
    this.apiService.getAwsCredentials('Class786--').subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          this.credentials = resp.data;

          const uploadPromises = this.uploadedFiles.map((file) => {
            const taskDocument = new TaskDocument();
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
            taskDocument.documentName = fileNameWithoutExtension;
            const fileExtension = file.name.split('.').pop();
            taskDocument.fileExt = fileExtension;
            taskDocument.size = file.size;

            return this.uploadFile(file).then((url) => {
              taskDocument.fileLocation = url;
              this.task.documents.push(taskDocument);
              return url;
            });
          });

          Promise.all(uploadPromises)
            .then(() => {
              this.uploadedFiles = [];
              this.isUploading = false;
              this.messageService.add({
                severity: 'info',
                summary: 'Files Uploaded',
                detail: 'All files uploaded successfully.',
              });
            })
            .catch((error) => {
              console.error('Error uploading files:', error);
            });
        }
      },
      error: (error) => {
        console.error('Error fetching AWS credentials:', error);
      },
    });
  }

  uploadFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const today = new Date();
      const filePath = `tms/org_${
        this.authService.currentUserValue.organizationId
      }/tasks/taskDocuments/${
        file.name
      }_${today.getDate()}_${today.getMilliseconds()}`;

      const bucket = new S3({
        accessKeyId: this.credentials.accessKey,
        secretAccessKey: this.credentials.secretKey,
        region: this.credentials.region,
      });

      const params = {
        Bucket: this.credentials.bucketName,
        Key: filePath,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
      };

      bucket
        .upload(params)
        .on('httpUploadProgress', (evt) => {
          this.progress = Math.round((100 * evt.loaded) / evt.total);
        })
        .send((err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
    });
  }

  triggerFileUpload() {
    this.fileUpload.choose(); // Trigger the file input click
  }

  //#endregion
}
