<div class="modal-body">
  <p class="form-description mt-3">
    <i class="fa-regular fa-circle-info me-1" style="color: blueviolet;font-size: 16px;"></i>
    Use this form to create a user team for efficient task management. Grouping users allows for easy task assignment.
    Please provide a team name and description.
  </p>

  <form class="forms-sample pt-3" novalidate="">
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="name"><strong>Team Name *</strong></label>
        <input type="text" class="form-control" id="name" name="name" placeholder="Team A" [(ngModel)]="team.name"
          #name="ngModel" required="true" size-sm="6" min="3" max="30">
        <div class="validation-error text-danger" *ngIf="name.invalid && name.touched">
          <div *ngIf="name.errors?.['required']">*required.</div>
          <div *ngIf="name.errors?.['minlength']">Name must be at least 3 characters long.</div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <label for="description"><strong>Description</strong></label>
        <input type="description" class="form-control" name="description"
          placeholder="Provide details about this team, e.g., purpose or members" [(ngModel)]="team.description">
      </div>
    </div>

    <div class="row mb-3">
      <div class="btn-group">
        <button #membersDropdownButton type="button" class="form-action-menu-btn"
          (click)="toggleMemberOverlay(members, $event)">
          <i class="fa-light fa-user-group mr-1"></i> {{ "Team Members" | translate }} {{team.members.length > 0
          ?
          '(' + team.members.length + ')' : ''}}
        </button>

        <p-overlayPanel #members appendTo="body" [dismissable]="false">
          <div style="width: 750px; padding: 5px 10px 5px 10px;">
            <div class="d-flex align-content-center justify-content-between">
              <h5 class="mb-0" style="margin-bottom: 0px; font-size: 18px;">Team Members</h5>
            </div>
            <div class="d-flex align-items-center mt-3 custom-autocomplete"
              style="background: aliceblue;padding: 5px 10px;">
              <i class="fa-regular fa-magnifying-glass mr-2 fw-bold text-secondary"></i>
              <p-autoComplete name="searchMembers" [(ngModel)]="selectedMember" [showEmptyMessage]="true"
                [suggestions]="filteredMembers" field="name" appendTo="body" placeholder="Search Users"
                (completeMethod)="filterMembers($event)" (onSelect)="onMemberSelect($event)"
                (mousedown)="$event.stopPropagation()" [style.width]="'100%'">
                <ng-template let-user pTemplate="item">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                      <img [src]="user.profilePictureUrl" style="width: 18px; height: 18px; border-radius: 50%;" />
                      <div>{{ user.name }}</div>
                    </div>
                    <div>
                      <span class="badge status inactive bg me-2">System Id: {{ padZero(user.id, 3) }}</span>
                      <span class="badge status inactive bg">Hrms Id: {{ user.hrmsEmpId === 0 ? 'N/A' : '#' +
                        user.hrmsEmpId }}</span>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>

            <div *ngIf="team.members.length > 0; else noMember" class="table-responsive table-members-container"
              style="height: 180px;">
              <table class="table table-hover table-members">
                <thead>
                  <tr class="sticky-header">
                    <!-- <th class="text-center" style="width: 35px;">-</th> -->
                    <th style="width: 280px;">Member</th>
                    <th>Job Title (Position)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let m of team.members">
                    <tr>
                      <!-- <td>
                        <p-checkbox name="chkMember" [(ngModel)]="m.selected" [binary]="true" [value]="m.selected" />
                      </td> -->
                      <td>
                        <div class="d-flex align-items-center" style="font-size: 0.875rem;">
                          <img [src]="m.profilePictureUrl"
                            style="width: 18px; height: 18px; border-radius: 50%; margin-right: 7px;">
                          <span>{{m.name}}</span>
                        </div>
                      </td>
                      <td class="job-titles">
                        <select [name]="'job-titles-' + m.id" [(ngModel)]="m.jobTitleId" class="custom-select"
                          style="width: 200px;" [style.borderColor]="m.hasError ? '#f00' : '#c9d2e3'"
                          (change)="onJobTitleChange($event, m)">
                          <option *ngFor="let jt of jobTitles" [ngValue]="jt.id">
                            {{jt.jobTitle}}
                          </option>
                        </select>
                      </td>
                      <td>
                        <div class="d-flex justify-content-end">
                          <button type="button" class=" btn btn-sm btn-danger delete-btn me-2"
                            (click)="removeMemeber(m)">
                            <i class="fa fa-light fa-minus" style="font-size: 9px;"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <ng-template #noMember>
              <div class="d-flex flex-column justify-content-center align-items-center" style="height: 180px;">
                <i class="fa-light fa-user-group mt-3 mb-2"></i>No one added yet
              </div>
            </ng-template>


            <ng-container *ngIf="duplicateMessage && duplicateMessage.length > 0">
              <div class="alert alert-danger p-1 mt-3 mb-1 fs-12px" role="alert">
                <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
                <span>{{duplicateMessage}}
                </span>
              </div>
            </ng-container>

            <div class="d-flex justify-content-end mt-3">
              <button type="button" class="btn btn-sm btn-secondary me-2" (click)="resetMembers()"
                style="font-size: 11px;">
                <i class="fa-regular fa-arrows-rotate"></i> Reset
              </button>
              <button type="button" class="btn btn-sm btn-primary" (click)="closeDropdown()" style="font-size: 11px;">
                <i class="fa-solid fa-check"></i> Done
              </button>
            </div>
          </div>
        </p-overlayPanel>
        <!-- <div class="dropdown-menu custom-dropdown-menu" (click)="$event.stopPropagation()">

        </div> -->

        <ng-container *ngIf="team.members.length > 0">
          <div class="likers-group ms-1">
            <img *ngFor="let u of team.members | slice:0:3; let i=index" [src]="u.profilePictureUrl" alt="{{u.name}}"
              title="{{u.name}}">
          </div>
        </ng-container>
      </div>
    </div>

    <hr class="mb-4">
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{team.id > 0 ?
        'Update Team' : 'Add Team'}}</button>
      <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
  </form>
  <!-- <p class="text-muted">Never submit passwords through Google Forms.</p> -->
</div>
