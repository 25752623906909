<div class="modal-body">
  <form class="forms-sampme">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="statusId">Assigned To:
          </label> <small class="text-muted fs-9px"> This field is to add watchers</small>
          <ng-multiselect-dropdown [settings]="dropdownSettingsUsers" [data]="usersList"
            (onSelect)="selectUsers($event)" (onDeSelect)="onItemDeSelectUsers($event)"
            (onSelectAll)="onSelectAllUsers($event)" (onDeSelectAll)="onDeSelectAllIUsers($event)"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUers">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="statusId">Status :</label>
          <select [ngModelOptions]="{standalone: true}" [(ngModel)]="data.statusId" class="form-control">
            <option *ngFor="let item of dealStatusList" value="{{item.id}}">{{item.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="name">Deal Name:</label>
          <small class="text-muted fs-9px"> (company name / person name to whom making deal)</small>
          <input type="text" class="form-control" id="name" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="data.name">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="dealOwner">Owner Name </label>
          <small class="text-muted fs-9px"> (fill concern name who is dealing with this field)</small>
          <input type="text" class="form-control" id="dealOwner" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="data.dealOwner">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="dealValue">Deal Value</label>
          <small class="text-muted fs-9px"> (write the amount of deal in numbers)</small>
          <input type="number" class="form-control" id="dealValue" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="data.value">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="probability">Probability </label>
          <small class="text-muted fs-9px"> (Deal winning chances)</small>
          <span class="float-right"> {{data.probability*100}}%</span>
          <div>
            <input style="width: 100%;" type="range" min="0" max="1" step="0.01" id="probability"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="data.probability"
              (ngModelChange)="data.probability = $event">
          </div>
        </div>
      </div>
    </div>


    <div class="text-center">
      <button type="button" (click)="onSubmit()" [disabled]="this.isDataSaving"
        class="btn btn-primary btn-sm action-button">Save</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-sm action-button-cancel" (click)="close()">Cancel</button>
    </div>
  </form>
</div>
