import { Component, Renderer2 } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { Result } from 'src/app/Models/Result';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Clients, ClientsFilter } from 'src/app/Models/Clients';
import { Country, States, City } from 'src/app/Models/Country';
import { padZero } from 'src/app/utils/utils';
import { Task, TasksFilter, TaskStatus } from 'src/app/Models/Task';
import { TaskActivities } from 'src/app/Models/TaskActivities';
import { LeadsConversions } from 'src/app/Models/LeadsConversions';

@Component({
  selector: 'app-task-activity',
  templateUrl: './task-activity.component.html',
  styleUrls: ['./task-activity.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class TaskActivityComponent {
  selectedDate: Date = new Date();
  public statusTypeId: number = 2; // Incase of lead component
  slideState = 'right';
  public clientsFilter: ClientsFilter = new ClientsFilter();
  public task: Task = new Task();
  public taskStaus: TaskStatus = new TaskStatus();
  public taskFilter: TasksFilter = new TasksFilter();

  public taskStatusList: TaskStatus[] = [];
  public taskActivities: TaskActivities[] = [];
  public recentTaskActivitiesList: TaskActivities[] = [];

  public tasks: Task[] = [];
  //public clients: Clients[] = [];
  public totalCount: number = 0;
  public currentPage = 1;
  public pageSize = 50;
  public countriesList: Country[] = [];
  public statesList: States[] = [];
  public citiesList: City[] = [];
  public displayedPages: number[] = [];
  public fromDate: any = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ); //new Date;"1-1-2022";
  public toDate: any = new Date(); //"12-31-2022";
  public isLoading = false;
  public totalPages: number = 0;
  public pageHeading: string;
  //#region refrence storing variables for model
  public addLogModal: any;
  modelHeader: string;
  clientType: string;
  //#endregion

  totalTasks: number = 0;
  inProgressTasks: number = 0;
  completedTasks: number = 0;
  onHoldTasks: number = 0;
  notStartedTasks: number = 0;
  taskStatusChartData: any;
  taskChartData: any;
  tasksConversions: LeadsConversions[] = [];

  constructor(
    private apiService: ApiService,
    private calendar: NgbCalendar,
    private messageService: MessageService
  ) {
    this.applyFilter();
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
  }

  ngOnInit(): void {
    this.applyFilter();
  }

  applyFilter() {
    this.taskFilter.fromDate = new Date(this.formatDate(this.selectedDate));
    this.loadData().then((t) => {
      this.generateDisplayedPages();
    });
  }
  formatDate(date: Date): string {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-01`; // Format as YYYY-MM-01
  }
  loadData(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.isLoading = true;
      this.taskFilter.pageNumber = this.currentPage;
      this.taskFilter.pageSize = this.pageSize;
      this.apiService.GetAllRecentTaskActivities(this.taskFilter).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.isLoading = false;
            this.recentTaskActivitiesList = resp.data;
            if (
              this.recentTaskActivitiesList &&
              this.recentTaskActivitiesList.length > 0
            )
              this.totalCount = this.recentTaskActivitiesList[0].totalRecords;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve(true);
        },
      });
    });
  }

  public toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  public onHideFilterRequest() {
    this.toggleFilter();
  }

  public onClientTypeFilterChange(clientType: string) {
    this.clientsFilter.clientType = clientType;
    this.clientType = clientType;
    this.applyFilter();
  }

  public onSearchRequest(filters: TasksFilter) {
    this.toggleFilter();
    this.taskFilter = filters;
    this.currentPage = this.taskFilter.pageNumber;
    this.applyFilter();
  }

  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.applyFilter();
  }
  public calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  public generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }
  public onPageSizeChange(): void {
    this.currentPage = 1;
    this.applyFilter();
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
}
