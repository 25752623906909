import { Component } from '@angular/core';
import { Result } from 'src/app/Models/Result';
import { TaskOverview, TasksSummary } from 'src/app/Models/TaskOverview';
import { ApiService } from 'src/app/services/api.service';
import { padZero } from 'src/app/utils/utils';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-tasks-overview',
  templateUrl: './tasks-overview.component.html',
  styleUrls: ['./tasks-overview.component.css'],
})
export class TasksOverviewComponent {
  overview: any;
  selectedDate: Date = new Date();

  constructor(
    private router: Router,
    private apiService: ApiService
  ) {
    this.selectedDate = new Date();
  }

  ngAfterViewInit() {}

  applyFilter() {
    this.loadData().then((t) => {
      this.createCharts();
    });
  }
  createCharts() {
    this.loadTopCases(this.overview.topClientsCases);
   this.workLoadCaseChart(this.overview.workloadClientCase);
    this.loadTimeSpentByCaseChart(this.overview.timeSpentByResouceCaseWise);
  }


  loadTopCases(t) {
    this.topClients = t;
  }
  workLoadCaseChart(t) {
    //#region workload
    const dataValues = t.map(x => x.resourceCount);

    // Calculate min, max, and thresholds
    const minValue = Math.min(...dataValues);
    const maxValue = Math.max(...dataValues);
    const range = maxValue - minValue;

    // Define thresholds
    const lowThreshold = minValue + range * 0.33; // Low threshold (33rd percentile)
    const highThreshold = minValue + range * 0.67; // High threshold (67th percentile)

    // Create a backgroundColor array based on dynamic thresholds
    const backgroundColors = dataValues.map((value) => {
      if (value <= lowThreshold) return 'rgba(13, 202, 240, 0.4)'; // Low
      else if (value <= highThreshold)
        return 'rgba(75, 192, 192, 0.4)'; // Medium
      else return 'rgba(255, 99, 132, 0.4)'; // High
    });

    // Create a borderColor array based on dynamic thresholds (darker shades)
    const borderColors = dataValues.map((value) => {
      if (value <= lowThreshold)
        return 'rgba(13, 202, 240, 1)'; // Darker shade for Low
      else if (value <= highThreshold)
        return 'rgba(75, 192, 192, 1)'; // Darker shade for Medium
      else return 'rgba(255, 99, 132, 1)'; // Darker shade for High
    });

    console.log(dataValues);

    this.workloadData = {
      labels: t.map(x => this.padZero(x.caseId, 3)), // Ensure the labels are correctly mapped
      datasets: [
        {
          label: 'Resources',
          data: dataValues,
          backgroundColor: backgroundColors, // Use the created color array
          borderColor: borderColors, // Use the created border color array
          borderWidth: 1, // Set border width
        },
      ],
    };

    this.workloadChartOptions = {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
      },
      maintainAspectRatio: false,
      aspectRatio: 0.6,
    };
    //#endregion
  }
  public viewClientProfile(item: any) {
    this.router.navigate(['/client'], {
      queryParams: { id: item }, //Cleint Id
    });
  }
  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  loadTimeSpentByCaseChart(t) {
        //#region timeSpent
        const timeSpentPerDay = t.map(x => x.totalTimeSpentHours);
        const resourcesPerDay = t.map(x => x.numberOfResources);

        // Labels for the days of the month
        const labels = Array.from(
          { length: timeSpentPerDay.length },
          (_, i) => `Day ${i + 1}`
        );

        this.timeSpentData = {
          labels: labels,
          datasets: [
            {
              type: 'line',
              label: 'Time Spent (hours)',
              data: timeSpentPerDay,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              fill: true,
              tension: 0.4, // Adjust this value for smoothing
              yAxisID: 'y1',
            },
            {
              type: 'line',
              label: 'Number of Resources',
              data: resourcesPerDay,
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              borderDash: [5, 5],
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              fill: false,
              tension: 0.4, // Adjust this value for smoothing
              yAxisID: 'y2',
            },
          ],
        };

        this.timeSpentChartOptions = {
          scales: {
            y1: {
              type: 'linear',
              position: 'left',
              title: {
                display: true,
                text: 'Time Spent (hours)',
              },
            },
            y2: {
              type: 'linear',
              position: 'right',
              title: {
                display: true,
                text: 'Number of Resources',
              },
              grid: {
                drawOnChartArea: false, // Only want the grid lines for one axis to show up
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
          },
          elements: {
            line: {
              tension: 0.4, // Smoothness for all lines
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 0.6,
        };

        //#endregion
  }
  totalTasks = 120;
  completedTasks = 75;
  pendingTasks = 45;
  overdueTasks = 10;
  tasksInProgress = 35;
  averageCompletionTime = 3.5;
  totalTimeSpent = 500;

  overdueTasksCount = 10;
  overbudgetTasksCount = 5;

  taskStatusData: any;
  workloadData: any;
  workloadChartOptions: any;
  timeSpentData: any;
  timeSpentChartOptions: any;
  resourceUtilization = 80;
  availableResources = 5;

  topClients: any[] = [];

  ngOnInit() {
    this.applyFilter();
    this.taskStatusData = {
      labels: ['Completed', 'Pending'],
      datasets: [
        {
          data: [75, 45],
          backgroundColor: ['#42A5F5', '#FF6384'],
          hoverBackgroundColor: ['#64B5F6', '#FF6384'],
        },
      ],
    };

    // //#region workload
    // const dataValues = [0,2,4,0,1,3];

    // // Calculate min, max, and thresholds
    // const minValue = Math.min(...dataValues);
    // const maxValue = Math.max(...dataValues);
    // const range = maxValue - minValue;

    // // Define thresholds
    // const lowThreshold = minValue + range * 0.33; // Low threshold (33rd percentile)
    // const highThreshold = minValue + range * 0.67; // High threshold (67th percentile)

    // // Create a backgroundColor array based on dynamic thresholds
    // const backgroundColors = dataValues.map((value) => {
    //   if (value <= lowThreshold) return 'rgba(13, 202, 240, 0.4)'; // Low
    //   else if (value <= highThreshold)
    //     return 'rgba(75, 192, 192, 0.4)'; // Medium
    //   else return 'rgba(255, 99, 132, 0.4)'; // High
    // });

    // // Create a borderColor array based on dynamic thresholds (darker shades)
    // const borderColors = dataValues.map((value) => {
    //   if (value <= lowThreshold)
    //     return 'rgba(13, 202, 240, 1)'; // Darker shade for Low
    //   else if (value <= highThreshold)
    //     return 'rgba(75, 192, 192, 1)'; // Darker shade for Medium
    //   else return 'rgba(255, 99, 132, 1)'; // Darker shade for High
    // });

    // this.workloadData = {
    //   labels: [
    //     'CASE #001',
    //     'CASE #002',
    //     'CASE #003',
    //     'CASE #004',
    //     'CASE #005',
    //     'CASE #006',

    //   ],
    //   datasets: [
    //     {
    //       label: 'Resources',
    //       data: dataValues,
    //       backgroundColor: backgroundColors, // Use the created color array
    //       borderColor: borderColors, // Use the created border color array
    //       borderWidth: 1, // Set border width
    //     },
    //   ],
    // };

    // this.workloadChartOptions = {
    //   indexAxis: 'y',
    //   scales: {
    //     x: {
    //       beginAtZero: true,
    //       ticks: {
    //         stepSize: 1,
    //       },
    //     },
    //   },
    //   plugins: {
    //     legend: {
    //       display: false, // Hide the legend
    //     },
    //   },
    //   maintainAspectRatio: false,
    //   aspectRatio: 0.6,
    // };
    // //#endregion
    // //#region timeSpent
    // const timeSpentPerDay = [
    //   8, 48, 36, 45, 70, 44, 51, 49, 53, 61, 72, 68, 39, 27, 39, 43, 55, 66, 74,
    //   58, 49, 36, 51, 70, 39, 48, 54, 62, 75, 82, 59,
    // ];
    // const resourcesPerDay = [
    //   1, 2, 1, 3, 2, 1, 2, 3, 2, 4, 5, 3, 4, 2, 1, 3, 2, 4, 5, 3, 2, 1, 4, 5, 2,
    //   3, 4, 1, 2, 3, 4,
    // ];

    // // Labels for the days of the month
    // const labels = Array.from(
    //   { length: timeSpentPerDay.length },
    //   (_, i) => `Day ${i + 1}`
    // );

    // this.timeSpentData = {
    //   labels: labels,
    //   datasets: [
    //     {
    //       type: 'line',
    //       label: 'Time Spent (hours)',
    //       data: timeSpentPerDay,
    //       borderColor: 'rgba(75, 192, 192, 1)',
    //       borderWidth: 1,
    //       backgroundColor: 'rgba(75, 192, 192, 0.2)',
    //       fill: true,
    //       tension: 0.4, // Adjust this value for smoothing
    //       yAxisID: 'y1',
    //     },
    //     {
    //       type: 'line',
    //       label: 'Number of Resources',
    //       data: resourcesPerDay,
    //       borderColor: 'rgba(255, 99, 132, 1)',
    //       borderWidth: 1,
    //       borderDash: [5, 5],
    //       backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //       fill: false,
    //       tension: 0.4, // Adjust this value for smoothing
    //       yAxisID: 'y2',
    //     },
    //   ],
    // };

    // this.timeSpentChartOptions = {
    //   scales: {
    //     y1: {
    //       type: 'linear',
    //       position: 'left',
    //       title: {
    //         display: true,
    //         text: 'Time Spent (hours)',
    //       },
    //     },
    //     y2: {
    //       type: 'linear',
    //       position: 'right',
    //       title: {
    //         display: true,
    //         text: 'Number of Resources',
    //       },
    //       grid: {
    //         drawOnChartArea: false, // Only want the grid lines for one axis to show up
    //       },
    //     },
    //   },
    //   plugins: {
    //     legend: {
    //       display: true,
    //     },
    //   },
    //   elements: {
    //     line: {
    //       tension: 0.4, // Smoothness for all lines
    //     },
    //   },
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   aspectRatio: 0.6,
    // };

    // //#endregion
  }
  loadData(): Promise<TaskOverview> {
    return new Promise<TaskOverview>((resolve, reject) => {
      this.apiService.getTaskOverview(
          this.selectedDate.getMonth() + 1,
          this.selectedDate.getFullYear()
        )
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {

              this.overview = resp.data;

              if (this.overview) {
                // You can add any additional logic here if needed
              }

              resolve(this.overview); // Resolve the promise when the operation is successful
            } else {
              // Handle cases where the response status is not 'success'
              reject(new Error('Failed to get totals: ' + resp.status));
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
            reject(error); // Reject the promise on error
          },
        });
    });
  }
  getAbsoluteValue(value: number): string {
    return Math.abs(value) + '%';
  }
  getComparisonDirection(value: number): string {
    if (value >= 0) {
      return 'Increase from last month';
    } else if (value < 0) {
      return 'Decrease from last month';
    }

    return '';
  }
}
