<div class="container">

  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-dashboard"></i> Overview</h4>
    <!-- Filter Section -->
    
      <div class="d-flex align-items-center">
        <div class="page-head-container-label">Year:</div>
        <p-calendar [(ngModel)]="selectedDate" view="year" dateFormat="yy" [readonlyInput]="true" [showIcon]="true"
          [style.width]="'110px'" />
        <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
          (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
      </div>
  </div>

  <!-- MAIN OVERVIEW ITEMS -->
<ng-container *ngIf="overview != null;
  else !isLoading ? noData : loader">

  <div class="row pl-2 pr-2 mt-3" *ngIf="overview">
    <div class="col">
      <div class="card">
        <div class="card-body top-box">
          <i class="fa-solid fa-users fa-2x mb-2 card-icon total"></i>
          <h5 class="card-title">Total Clients</h5>
          <p class="card-text">  {{ hasPermission() ? overview.summary.totalClients : 0 }}</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body top-box">
          <i class="fa-solid fa-chart-bar fa-2x mb-2 card-icon pending"></i>
          <h5 class="card-title">Overall Engagement</h5>
          <p class="card-text">
            {{ hasPermission() ? getAbsoluteValue(overview.summary.overallEngagement) : 0 }} %
            </p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body top-box">
          <i class="fa-solid fa-calendar-times fa-2x mb-2 card-icon overdue"></i>
          <h5 class="card-title">Overdue Engagements</h5>
          <p class="card-text">
            {{ hasPermission() ? overview.summary.overdueEngagements  : 0 }}</p>
        </div>
      </div>
    </div>
    <div class="col" style="display: none;">
      <div class="card">
        <div class="card-body top-box">
          <i class="fa-solid fa-dollar-sign fa-2x mb-2 card-icon completed"></i>
          <h5 class="card-title">Total Revenue</h5>
          <p class="card-text">
            {{ hasPermission() ? (overallSummary.totalRevenue | currency:'Rs ') : 0 }}</p>
        </div>
      </div>
    </div>
    <div class="col" style="display: none;">
      <div class="card">
        <div class="card-body top-box">
          <i class="fa-solid fa-money-bill-wave fa-2x mb-2 card-icon overbudget"></i>
          <h5 class="card-title">Total Expenses</h5>
          <p class="card-text">{{ hasPermission() ? (overallSummary.totalExpenses | currency:'Rs ') : 0 }}
           </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row pl-2 pr-2 mt-3" *ngIf="overview">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-chart-line"></i> Overall Cost & Time Summary
        </div>
        <div class="card-body">
          <div class="statistics-details d-flex align-items-center justify-content-between mb-4">
            <div class="box-shape">
              <p class="statistics-title fw-600">Budget</p>
              <div class="d-flex flex-row">
                <div class="me-5"><span class="text-gray-light font-weight-500 small text-uppercase"> Spent
                  </span>
                  <div class="font-weight-500 font-size-5 text-dark"> {{ hasPermission() ? (overview?.organizationTotals?.actualCost ?? 0 | currency: 'Rs ') : 0 }} 
                    </div>
                </div>
                <div><span class="text-gray-light font-weight-500 small text-uppercase"> Target </span>
                  <div class="font-weight-500 font-size-5 text-dark">
                    {{ hasPermission() ? (overview?.organizationTotals?.estimatedCost ?? 0 | currency: 'Rs ') : 0 }} </div>
                </div>
              </div>

              <div class="d-flex flex-column mt-2">
                <div class="progress">
                  <div role="progressbar" class="progress-bar progress-bar-striped overflow-visible" 
                  [style.width.%]="budgetUtilization > 0 ? budgetUtilization : 0"
                    [class]="getProgressBarClass(budgetUtilization)" [attr.aria-valuenow]="budgetUtilization"
                    style = "background-color: #b19dff !important; color: black;"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ hasPermission() ? (budgetUtilization | number:'1.2-2') : 0 }} %
                </div>
                  <!-- <div role="progressbar" class="progress-bar progress-bar-striped overflow-visible text-light"
                    style="width: 78%; background-color: #b19dff !important;">
                    78% </div> -->

                </div>
              </div>
            </div>

            <div class="box-shape">
              <p class="statistics-title fw-600">Total Cost</p>
              <div class="d-flex flex-row">
                <div class="me-5">
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Current </span>
                  <div class="font-weight-500 font-size-5 text-dark"> 
                    {{ hasPermission() ? (overview?.organizationTotals?.actualCost ?? 0  | currency: 'Rs ' ) : 0 }}
                  </div>
                </div>
                <div>
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Forecast </span>
                  <div class="font-weight-500 font-size-5 text-dark"
                    [pTooltip]="costForecast > overview?.organizationTotals?.estimatedCost ?? 0  ? 'Higher than target' : 'Lower than target'"> Rs <span
                      [ngClass]="{'text-danger': costForecast > overview?.organizationTotals?.estimatedCost ?? 0, 'text-success': costForecast < overview?.organizationTotals?.estimatedCost ?? 0}">{{
                      costForecast > overview?.organizationTotals?.estimatedCost ?? 0 ? '▲' : '▼' }} </span>
                       {{ hasPermission() ? (costForecast |  currency: 'Rs ') : 0 }}</div>
                </div>
              </div>
              <p style="font-size: 11px;margin: 0;position: absolute;bottom: 10px;left: 12px;"><i
                  class="fa-light fa-circle-info mr-1"></i>Forecast is based on
                current spending trends</p>
            </div>

            <div class="box-shape">
              <p class="statistics-title fw-600">Hours Spent</p>
              <div class="d-flex flex-row justify-content-between">
                <div>
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Total </span>
                  <div class="font-weight-500 font-size-5 text-dark"> 
                    {{ hasPermission() ? ((overview.organizationTotals?.billableHours +
                    overview.organizationTotals?.nonBillableHours).toFixed(2)) : 0 }} H </div>
                </div>
                <div><span class="text-gray-light font-weight-500 small text-uppercase"> Billable
                  </span>
                  <div class="font-weight-500 font-size-5 text-dark">{{ hasPermission() ? (overview.organizationTotals?.billableHours?.toFixed(2)) : 0 }}  H </div>
                </div>
                <div>
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Non-Billable </span>
                  <div class="font-weight-500 font-size-5 text-dark"> {{ hasPermission() ? (overview.organizationTotals?.nonBillableHours?.toFixed(2)) : 0 }}  H </div>
                </div>
              </div>
            </div>

            <div class="box-shape me-0 w-50" [pTooltip]="tooltipContent1">
              <ng-template #tooltipContent1 let-data="performer">
                <div class="flex align-items-center">
                  <p class="performer-tooltip">
                    {{ hasPermission() ? (overview.resources.resourceUtilization) : 0 }}
                  </p>
                </div>
              </ng-template>
              <p class="statistics-title fw-600">Resource Utilization</p>
              <h3 class="rate-percentage">
                <span class="text-medium" style="color: #327fa8;">
                  {{ hasPermission() ? (overview.resources.resourceUtilization) : 0 }}
                  </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row pl-2 pr-2 mt-3">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-chart-line"></i> Monthly Actual Cost Vs Estimated Cost
        </div>
        <div class="card-body">
          <p-chart type="line" [data]="monthlyCostData"  [options]="ChartOptions1"  height="200" *ngIf="hasPermission()"></p-chart>
          <ng-container *ngIf="!hasPermission()">
            <div class="no-track">
              <h5>Nothing tracked yet</h5>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Engagement Status Card -->
    <div class="col-md-3" *ngIf="overview">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-tasks"></i> Engagement Status
        </div>
        <div class="card-body">
          <p-chart type="bar" [data]="chartData" [options]="chartOptions" height="200" *ngIf="hasPermission()"></p-chart>
          <ng-container *ngIf="!hasPermission()">
            <div class="no-track">
              <h5>Nothing tracked yet</h5>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Financial Performance Card -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-chart-pie"></i> Financial Performance
          <span style="color:red">(To Be Removed )</span>

        </div>
        <div class="card-body">
          <p-chart type="pie" [data]="{
          labels: financialPerformanceData.labels,
          datasets: [{
            data: financialPerformanceData.data,
            backgroundColor: ['#66BB6A50', '#FFCE5650', '#FF638450']
          }]
        }" height="200"></p-chart>
        </div>
      </div>
    </div>
  </div>

  
  <div class="row pl-2 pr-2 mt-3">
    <div class="col-md-6 mb-3">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-heartbeat"></i> Project Health
          <span style="color:red">(To Be Removed )</span>
        </div>
        <div class="card-body">
          <p>Total Clients: {{ projectHealth.totalClients }}</p>
          <p>Overall Engagement: {{ projectHealth.overallEngagement }} <span>%</span></p>
          <p>Overdue Engagements: {{ projectHealth.overdueEngagements }}</p>
          <p>Total Revenue: ${{ projectHealth.totalRevenue }}</p>
          <p>Total Expenses: ${{ projectHealth.totalExpenses }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-tasks"></i> Resource Utilization / Workload
        </div>
        <div class="card-body">
          <p-chart type="bar" [data]="workloadData1"  [options]="chartOptions"  height="200"></p-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="row pl-2 pr-2 mt-3">
    <div class="col-md-6 mb-3">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-briefcase"></i> Cases Per Service
          <span style="color:red">(To Be Removed )</span>
        </div>
        <div class="card-body">
          <p-chart type="pie" [data]="casesPerServiceData" height="200"></p-chart>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-check-square"></i> Task/Case Completion Rates
          <span style="color:red">(To Be Removed )</span>
        </div>
        <div class="card-body">
          <p-chart type="bar" [data]="completionRatesData" height="200"></p-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="row pl pr-2 mt-3">
    <!-- Compliance Overview Card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-shield-alt"></i> Compliance Overview
          <span style="color:red">(To Be Removed )</span>
        </div>
        <div class="card-body">
          <p-chart type="doughnut" [data]="{
          labels: complianceData.labels,
          datasets: [{
            data: complianceData.data,
            backgroundColor: ['#66BB6A50', '#FF638450']
          }]
        }" height="200"></p-chart>
        </div>
      </div>
    </div>

    <!-- Audit Findings Card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-exclamation-circle"></i> Audit Findings
          <span style="color:red">(To Be Removed )</span>
        </div>
        <div class="card-body">
          <p-chart type="radar" [data]="{
          labels: findingsData.labels,
          datasets: [{
            label: 'Findings',
            data: findingsData.data,
            backgroundColor: 'rgba(255, 206, 86, 0.5)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1
          }]
        }" height="200"></p-chart>
        </div>
      </div>
    </div>

    <!-- Team Workload Card -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <i class="fa-solid fa-users"></i> Team Workload
          <span style="color:red">(To Be Removed )</span>
        </div>
        <div class="card-body">
          <div *ngFor="let member of teamWorkload.teamMembers; let i = index">
            <p>{{ member }}: Completed Tasks {{ teamWorkload.completedTasks[i] }}, Pending Tasks {{
              teamWorkload.pendingTasks[i] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loader>
  <app-project-loading-indicator />
</ng-template>

<ng-template #noData>
  <app-nodatafound message="{{'There are no analytics to show.' | translate }}"
    height="calc(100vh - 285px)"></app-nodatafound>
</ng-template>

</div>
