import { CommonModule } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Services, ServiceCategories } from 'src/app/Models/Services';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-services-setup',
  templateUrl: './services-setup.component.html',
  styleUrls: ['./services-setup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesSetupComponent {
  service: Services = new Services();
  servicesCategoryList: ServiceCategories[] = [];
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.getServicesCategory();
    this.service = this.dialogConfig.data.service;
  }

  ngAfterViewInit() {

  }


  onServiceChange(value: any) {
    this.service.categoryId = value;
  }

  public getServicesCategory() {
    this.apiService.getServicesCategory().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.servicesCategoryList = resp.data;
        } else {
          this.servicesCategoryList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addOrUpdateService(this.service).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });

            this.sharedService.triggerProjectUpdates();
            this.dialogRef.close();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public validateAndSetData() {
    if (!this.service.serviceName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter project name',
        life: 3000,
      });
      return false;
    }
    return true;
  }

  public modalClose() {
    this.dialogRef.close();
  }
}
