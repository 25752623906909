import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { AddPermissionsRequest, Permission } from 'src/app/Models/Permission';
import { Result } from 'src/app/Models/Result';
import { Role } from 'src/app/Models/Role';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { TreeNode } from 'primeng/api';
import { parseJSON } from 'date-fns';

@Component({
  selector: 'app-role-permissions-list',
  templateUrl: './role-permissions-list.component.html',
  styleUrls: ['./role-permissions-list.component.css'],
})
export class RolePermissionsListComponent {
  public permissions: Permission[] = [];
  public selectedPermissions: Permission[] = [];
  files: TreeNode[] = [];
  selectedFiles: any;
  selectedTree: TreeNode[] = [];
  public role: Role = new Role();
  userRole: Role = new Role();
  addPermissionRequest: AddPermissionsRequest;
  ids: string;
  nodeService: any;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.role = this.dialogConfig.data.role;
    this.permissions = this.dialogConfig.data.permissions;
  }
  ngOnInit(): void {
    this.getAllPermissions();
  }
  getAllPermissions() {
    this.apiService.getPermissions().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          // Globally Setting Permissions
          const permissions = resp.data;
          this.authService.setCurrentPermissions(permissions);
          // End Globally Setting Permissions

          if (this.permissions) {
            resp.data.forEach((element: Permission) => {
              this.permissions.forEach((permission: Permission) => {
                if (permission.permissionName === element.permissionName) {
                  element.isChecked = true;
                }
              });
            });
          }

          this.selectedPermissions = resp.data;
          this.files = this.transformData(this.selectedPermissions);
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
    });
  }
  getUserRoleByUserId() {
    this.apiService
      .getUserRoleByUserId(this.authService.currentUserValue.id)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            if (resp.data) {
              this.userRole = JSON.parse(resp.data);
              const permissions = this.userRole.permissions;
              // Globally Setting Permissions
              this.authService.setCurrentPermissions(permissions);
              // End Globally Setting Permissions
            }
          }
        },
        error: (error) => {
          console.error('error:', error);
        },
      });
  }
  public modalClose() {
    this.dialogRef.close();
  }

  // Transform the data into TreeNode structure
  transformData(data: any[]): TreeNode[] {
    const nodeMap = new Map<number, TreeNode>();

    data.forEach((category) => {
      nodeMap.set(category.id, {
        label: category.description,
        data: category,
        children: [],
      });
    });

    const tree: TreeNode[] = [];
    this.selectedFiles = [];
    nodeMap.forEach((node, id) => {
      const parentId = node.data.parentId;

      if (parentId === null || parentId === 0) {
        tree.push(node); // Root node
      } else {
        const parentNode = nodeMap.get(parentId);
        if (parentNode) {
          parentNode.children?.push(node); // Add to parent's children
        }
      }
      if (node.data.isChecked === true) {
        const parentNode = nodeMap.get(parentId);
        if (!this.selectedFiles.includes(node)) {
          this.selectedFiles.push(node); // Add node to selectedFiles if checked
        } // Add node to selectedFiles if checked
      }
    });

    return tree;
  }

  onNodeSelect(event: any) {
    const selectedNode = event.node;
    this.selectParentNodes(selectedNode);
  }

  onNodeUnselect(event: any) {
    const unselectedNode = event.node;
    this.unselectChildNodes(unselectedNode);
  }

  selectParentNodes(node: TreeNode) {
    if (node.parent) {
      const parent = node.parent;
      if (!this.isNodeSelected(parent)) {
        this.selectedFiles.push(parent);
        this.selectParentNodes(parent); // Recursively select parent nodes
      }
    }
  }

  unselectChildNodes(node: TreeNode) {

    if (node.children.length > 0) {
      for (let child of node.children) {
        if (this.isNodeSelected(child)) {
          this.selectedFiles = this.selectedFiles.filter(selected => selected !== child);
          this.unselectChildNodes(child); // Recursively unselect child nodes
        }
      }
    }
    else {
      if (node.parent) {
        const parent = node.parent;
        if (!this.isNodeSelected(parent)) {
          this.selectedFiles.push(parent);
        }
      }
    }

  }

  isNodeSelected(node: TreeNode): boolean {
    return this.selectedFiles.includes(node);
  }
  // Select all nodes in the tree
  selectAllNodes() {
    this.selectedFiles = []; // Clear selection
    this.traverseNodes(this.files, true);
  }

  // Unselect all nodes in the tree
  unselectAllNodes() {
    this.selectedFiles = [];
    this.traverseNodes(this.files, false); // Clear selection
  }

  // Helper method to traverse and set selection
  private traverseNodes(nodes: TreeNode[], select: boolean) {
    nodes.forEach((node) => {
      if (select) {
        this.selectedFiles.push(node); // Add node to selection array
      }

      if (node.children && node.children.length > 0) {
        this.traverseNodes(node.children, select); // Recursively handle children
      }
    });
  }

  onSubmit() {
    this.getAllNodeIds();
    this.addPermissionRequest = new AddPermissionsRequest();
    this.addPermissionRequest.roleId = this.role.id;
    this.addPermissionRequest.ids = this.ids;
    this.apiService.addPermissions(this.addPermissionRequest).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.closeModal();
          this.getUserRoleByUserId();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
    });
  }
  getAllNodeIds() {
    const ids = this.getNodeIds(this.selectedFiles);
    this.ids = null;
    this.ids = JSON.stringify(ids);
  }

  // Define the function to collect IDs (same as above)
  getNodeIds(nodes: TreeNode<any>[]): number[] {
    const ids: number[] = [];

    function traverse(nodes: TreeNode<any>[]) {
      nodes.forEach((node) => {
        if (node.data && node.data.id) {
          ids.push(node.data.id); // Collect ID from the current node

          // if (node.children && node.children.length > 0) {
          //   traverse(node.children); // Recursively collect IDs from children
          // }
        }
      });
    }

    traverse(nodes);
    return ids;
  }
  closeModal(): void {
    this.dialogRef.close({ success: false });
  }
}
