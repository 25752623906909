export class TimeTrackingOverview {
  summary: TimeTrackingSummary = new TimeTrackingSummary();
  clientsCases: TimeTrackingByClientCase[] = [];
  topTasks: TopTask[] = [];
  topUsers: TopUser[] = [];
  timeSpentByPriority: TimeSpentByPriority[] = [];
  dailyTimeTracking: DailyTimeTracking[] = [];
  weeklyTimeTracking: WeeklyTimeTracking[] = [];
  timeTrackingByCaseType: TimeTrackingByCaseType[] = [];
  peakHours: PeakHours[] = [];
}

export class TimeTrackingSummary {
  totalHours: number = 0;
  billableHours: number = 0;
  nonBillableHours: number = 0;
  lastWeekHours: number = 0;
  currentWeekHours: number = 0;
  averageHoursPerTask: number = 0;
}

export class TimeTrackingByClientCase {
  clientName: string = '';
  caseName: string = '';
  totalHours: number = 0;
}

export class TopTask {
  taskTitle: string = '';
  totalHours: number = 0;
}

export class TopUser {
  userName: string = '';
  totalHours: number = 0;
}

export class TimeSpentByPriority {
  priorityName: string = '';
  totalHours: number = 0;
}

export class DailyTimeTracking {
  date: Date | null = null;
  totalHours: number = 0;
}

export class WeeklyTimeTracking {
  week: number = 0;
  totalHours: number = 0;
}

export class TimeTrackingByCaseType {
  caseName: string = '';
  totalHours: number = 0;
}

export class PeakHours {
  calendarDate: Date;
  hour: number;
  totalCheckIns: number;
}
