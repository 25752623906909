import { TaskChecklist } from './TaskChecklist';
import { TaskDocument } from './TaskDocument';

export class Task {
  id?: number;
  title: string = '';
  dateCreated?: Date;
  clientId?: number;
  serviceId?: number;
  caseId?: number;
  description: string = '';
  dueDate?: Date;
  startDate?: Date;
  taskStatusId?: number;
  taskPriorityId?: number;
  estimatedHours?: number;
  completionDate?: Date;
  actualHours?: number;
  acceptanceCriteria?: string;
  assigneeIds: number[] = [];
  documents: TaskDocument[] = [];
  checklists: TaskChecklist[] = [];
  documentsJSON!: string;
  checkListJSON!: string;
  assigneeJSON!: string;
  tagIds: number[] = [];
  tagIdsString: string;
  createdBy: number;
  updatedBy: number;
  caseName: string = '';
  serviceName: string = '';
  status: string = '';
  priorityName: string = '';
  taskStatusForeColor: string = '';
  taskStatusBgColor: string = '';
  priorityForeColor: string = '';
  priorityBgColor: string = '';
  assignee: string = '';
  taskAssignee: TaskAssignee[] = [];
  rowNumber: any;
  totalRecords: number;
  firstAssignee: string;
  clientName: any;
  assigneesArray: string[];
  taskStartTime?: Date;
  isStarted: boolean;
  ratesPerHour: number;
  isBillable: boolean;
  totalHours: number;
  totalCost: number;
  caseStatus:boolean;
  selected: boolean = false;
  categoryId:number;
  categoryName:string;
}

export class TaskStatus {
  id?: number;
  status?: string;
  description?: string;
  taskStatusCount?: number;
  startDate!: Date | null;
  endDate!: Date | null;
  organizationId?: number;
  taskId:number;
  userId:number;
}

export class TaskUserMapping {
  id?: number;
  dateCreated?: Date = new Date();
  taskId?: number;
  userId?: number;
  estimatedHours: number | null;
  ratesPerHour: number | null;
  selected: boolean = false;
}

export class TimeEntry {
  id?: number;
  taskId?: number;
  userId?: number;
  startTime?: Date = new Date();
  endTime?: Date = new Date();
  locationName?: string;
  latitude?: number;
  longitude?: number;
  userName: string;
  profilePictureUrl: string;
  timeDifference: any;
}
export class TimeEntryReq {
  id?: number;
  taskId?: number;
  userId?: number;
  locationName?: string;
  latitude?: number;
  longitude?: number;
  statusId?:number;
}
export class TimeFilter {
  fromDate: string;
  userId?: number;
}

export class TaskActivity {
  id?: number;
  dateCreated?: Date = new Date();
  taskId?: number;
  userId?: number;
  activityTypeId?: number;
  activityDescription?: string;
  activityDate?: Date = new Date();
}

export class TaskComment {
  id?: number;
  dateCreated?: Date;
  commentText?: string;
  file?: File;
  fileLocation?: string;
  taskId?: number;
  userId?: number;
  commenterName?: string;
  commenterPicture?: string;
  active?: boolean = true;
  isDeleted?: boolean = true;
}
export class TaskTag {
  id?: number;
  taskId?: number;
  tagId?: number;
}

export class PriorityLevels {
  id!: number;
  weight!: number;
  priorityName!: string;
  description!: string;
  foreColor!: string;
  bgColor!: string;
}
export class TaskAssignee {
  Id!: number;
  Name?: string;
  ProfilePictureUrl?: string;
  EstimatedHours: number;
  RatesPerHour: number;
}

export class TasksFilter {
  id!: number | null;
  statusId!: number | null;
  query!: string | null;
  fromDate!: Date | null;
  toDate!: Date | null;
  pageNumber!: number;
  pageSize!: number;
  userIds: number[] = [];
}

export class TimeSheetEntry {
  taskId!: number | null;
  title!: string | null;
  clientName!: string | null;
  caseName!: string | null;
  description!: string | null;
  estimatedHours!: number | null;
  actualHours!: number | null;
  date!: string | null;
  actualHoursByDate: { [date: string]: number } = {}; // Initialize actualHoursByDate
  totalHours: number = 0; // Initialize totalHours property
  taskDetails:any;
  expanded: boolean = false;
}

export class TimeSheetFilters {
  taskId!: number | null;
  StartDate!: Date | null;
  EndDate!: Date | null;
  UserId!: number | null;
}

export class TopPerformants {
  userId: number;
  userName: string;
  profilePictureUrl: string;
  tasksSuccessRate: number;
  estimatedHours: number;
  actualHours: number;
  completedTasksCount: number;
  // totalTasksCount: number;
  successRatePercentage: number;
}
export class TimeEntryDetailsMonthWise {
  id?: number;
  taskId?: number;
  userId?: number;
  startTime?: Date = new Date();
  endTime?: Date = new Date();
  timeDifference: any;
  title?:string;
  status: string;
  bgColor:string;
  foreColor:string;
  name:string;
  caseName: string;
  clientName: string;
}

export class AddUpdateTaskChecklist{
  taskId!:number|null;
  checkListJSON!:string|null;
}

export class TaskCheckInState {
  automark: boolean | null;
  clientId: number; // Replace `any` with the appropriate type
}
