export class Services {
  id!: number;
  categoryId!: number;
  serviceName!: string;
  description!: string;
  active?: boolean | null;
  isSystemService: boolean | true;
}

export class ServiceCategories {
  id!: number;
  categoryName!: string;
  description!: string;
  active?: boolean | null;
}

export class ServicesList{
  servicesList:Services[]=[];
}
