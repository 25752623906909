import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Permission } from 'src/app/Models/Permission';
import { Result } from 'src/app/Models/Result';
import { Role } from 'src/app/Models/Role';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent {
  public permissions: Permission[] = [];
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService,
  ) {
    this.permissions = this.dialogConfig.data.permissions;
  }
  public modalClose() {
    this.dialogRef.close();
  }
}
