import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-nodatafound',
  template: ` <div
    class="text-center login-main-right p-5 mt-5 mb-5"
    style="height: {{ height }};display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;"
  >
    <img
      src="/assets/img/warning-circle.png"
      alt="no data image"
      style="max-width: 30px;"
    />
    <p style="text-align: center" class="mt-4">
      {{ message }}
      <br />

      <button *ngIf="showBtn" class="btn btn-primary mt-5" [routerLink]="['/']">
        Go to Home
      </button>
    </p>
  </div>`,
  styleUrls: ['./nodatafound.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NodatafoundComponent {
  @Input() message: string = 'There is no record found.';
  @Input() height: string = 'unset';
  @Input() showBtn: boolean = false;
}
