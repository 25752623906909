<div class="progress {{width}} mx-auto" [attr.data-value]="progress" [style.height]="size+'px'" [style.width]="size+'px'">
  <span class="progress-left">
    <span class="progress-bar {{width}}"></span>
  </span>
  <span class="progress-right">
    <span class="progress-bar {{width}}"></span>
  </span>
  <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center" [style.font-size]="fontSize+'px'">
    <div class="fw-600">{{progress}}<sup class="small">%</sup></div>
  </div>
</div>
