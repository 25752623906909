<div class="modal-body">

  <form class="forms-sampme pt-3" novalidate="">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="firstName"><i class="fa-light fa-user mr-1"></i>First Name</label>
        <input type="text" class="form-control" id="firstName" name="firstName" placeholder="John"
          [(ngModel)]="user.firstName" #firstName="ngModel" required="true" size-sm="6" min="3" max="30">
        <div class="validation-error text-danger" *ngIf="firstName.invalid && firstName.touched">
          <div *ngIf="firstName.errors?.['required']">
            *required.
          </div>
          <div *ngIf="firstName.errors?.['minlength']">
            Name must be at least 3 characters long.
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="lastName"><i class="fa-light fa-user mr-1"></i>Last Name</label>
        <input type="text" class="form-control" id="lastName" name="lastName" placeholder="Doe"
          [(ngModel)]="user.lastName" #lastName="ngModel" required="true" size-sm="6" min="3" max="30">
        <div class="validation-error text-danger" *ngIf="lastName.invalid && lastName.touched">
          <div *ngIf="lastName.errors?.['required']">
            *required.
          </div>
          <div *ngIf="lastName.errors?.['minlength']">
            Name must be at least 3 characters long.
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <label for="email"><i class="fa-light fa-envelope mr-1"></i>Email Address</label>
        <input type="email" class="form-control" name="email" placeholder="user@example.com"
          [(ngModel)]="user.emailAddress" email="true" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          #emailAddress="ngModel" required="true">
        <div class="validation-error text-danger" *ngIf="emailAddress.invalid && emailAddress.touched">
          <div *ngIf="emailAddress.errors?.['required']">
            *Email is required.
          </div>
          <div *ngIf="emailAddress.errors?.['pattern']">
            {{'*Follow the correct format (e.g., user@example.com).'}}
          </div>
          <div *ngIf="emailAddress.errors?.['email']">
            wrong email pattern.
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <label for="phone"><i class="fa-light fa-phone mr-1"></i>Phone Number <span
            class="text-muted">(Optional)</span></label>
        <input type="text" class="form-control" name="phone" [(ngModel)]="user.phoneNo" phoneValidator appOnlyNumbers>
      </div>
    </div>

    <div class="row mb-3">
      <label for="projects"><i class="fa-light fa-project-diagram mr-1"></i>Project(s)</label>
      <p-multiSelect name="projectsselect" [options]="projects" [(ngModel)]="user.projects"
        placeholder="Select Projects" optionLabel="name" display="chip" [disabled]="disableFields" appendTo="body">
      </p-multiSelect>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <label for="userType"><i class="fa-light fa-user-tag mr-1"></i>User Type</label>
        <p-dropdown name="selectedUserType" [options]="userTypes" [(ngModel)]="selectedUserType" optionLabel="type"
          placeholder="Select User Type" [disabled]="disableFields" appendTo="body"></p-dropdown>
      </div>

      <div class="col-md-6">
        <label for="userRole"><i class="fa-light fa-user-shield mr-1"></i>User Roles</label>
        <p-dropdown name="selectedUserRole" [options]="userRoles" [(ngModel)]="selectedUserRole" optionLabel="roleName"
          placeholder="Select User Role" [disabled]="disableFields" appendTo="body"></p-dropdown>
      </div>
    </div>

    <h5 class="mb-2">Profile Picture</h5>

    <div class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center">
        <img *ngIf="currentFile || user.profilePictureUrl; else noPhoto" [src]="preview" class="preview">
        <input type="file" name="fileInput" #fileInput accept="image/*" (change)="selectFile($event)" hidden />

        <ng-template #noPhoto>
          <svg class="preview"
            style="vertical-align: middle;fill: #b3b3b3;overflow: hidden;border: 1px solid #ccc;border-radius: 10px;"
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M819.2 729.088V757.76c0 33.792-27.648 61.44-61.44 61.44H266.24c-33.792 0-61.44-27.648-61.44-61.44v-28.672c0-74.752 87.04-119.808 168.96-155.648 3.072-1.024 5.12-2.048 8.192-4.096 6.144-3.072 13.312-3.072 19.456 1.024C434.176 591.872 472.064 604.16 512 604.16c39.936 0 77.824-12.288 110.592-32.768 6.144-4.096 13.312-4.096 19.456-1.024 3.072 1.024 5.12 2.048 8.192 4.096 81.92 34.816 168.96 79.872 168.96 154.624z" />
            <path d="M359.424 373.76a168.96 152.576 90 1 0 305.152 0 168.96 152.576 90 1 0-305.152 0Z" />
          </svg>
        </ng-template>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-items-center mb-1">
            <button for="fileInput" class="btn btn-sm btn-danger mt-1" (click)="fileInput.click()"
              [disabled]="progress > 0 && progress < 99">{{!currentFile
              ?'Choose Photo' : 'Change Photo'}}</button>

            <button *ngIf="currentFile && progress === 0" class="btn btn-success btn-sm ms-2 mt-1"
              (click)="uploadPhoto()">
              Upload
            </button>
          </div>
          <div>
            <p-progressBar *ngIf="progress > 0" [value]="progress" [style]="{ height: '12px' }"></p-progressBar>
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-4">
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm action-button me-2" (click)="onSubmit()">{{user.id > 0 ?
        'Update User Info' : 'Save User Info'}}</button>
      <button type="button" class="btn btn-sm action-button-cancel" (click)="modalClose()">Cancel</button>
    </div>
  </form>
</div>
