import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Result } from 'src/app/Models/Result';
import { UserType } from 'src/app/Models/UserType';
import { User, Project } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { Injectable } from '@angular/core';
// import * as AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
//  import { Upload } from '@aws-sdk/lib-storage';
// import * as AWS from 'aws-sdk/global';
// import * as AWS from 'aws-sdk';
// import { Injectable } from '@angular/core';
// import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
// import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
// import {PutObjectCommand, S3Client} from '@aws-sdk/client-s3';
// import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { HttpClient } from '@angular/common/http';
import { AWSServerCredentials } from 'src/app/Models/AWSServerCredentials';
import { UserRole } from 'src/app/Models/Role';
// @Injectable({
//   providedIn: 'root',
// })
// import * as S3 from 'aws-sdk/clients/s3';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.css'],
})
export class UserSetupComponent {
  user: User = new User();
  projects: Project[];
  credentials: AWSServerCredentials = new AWSServerCredentials();

  userTypes: UserType[];
  selectedUserType: UserType;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  preview = '';

  imageInfos?: Observable<any>;

  disableFields = false;
  errorMessage: string;

  userRoles: UserRole[];
  selectedUserRole: UserRole;


  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    public sharedService: SharedService
  ) {
    this.user = this.dialogConfig.data.user;
    this.sharedService.onProjectChange.subscribe((projectId) => {
      GlobalVariables.selectedProjectId = projectId;
    });
  }

  ngOnInit(): void {
    this.userTypes = [
      { id: 1, type: 'Organization Admin' },
      { id: 2, type: 'Project Manager' },
      { id: 3, type: 'Standard User' },
    ];
    if (this.user.userTypeId > 0) {
      this.selectedUserType = this.userTypes.find(
        (x) => x.id === this.user.userTypeId
      );
    }

    this.preview = this.user.profilePictureUrl;
    this.user.projects = [];
    this.getUserProjects();
    this.getUserRoles();

    if (this.authService.currentUserValue.userTypeId > 1) {
      if (this.authService.currentUserValue.id === this.user.id) {
        this.disableFields = true;
      }
    }
  }

  getUserProjects(): void {
    this.apiService.getUserProjects().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.projects = resp.data;

          if (this.user.projectIds) {
            const projectIds = this.user.projectIds
              .split(',')
              .map((id) => parseInt(id.trim()));
            this.user.projects = this.projects.filter((userType) =>
              projectIds.includes(userType.id)
            );
          }
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  getUserRoles(): void {
    this.apiService.getAllRoles().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.userRoles = resp.data;

          if (this.user.roleId) {
            this.selectedUserRole = this.userRoles.find(
              (x) => x.id === this.user.roleId
            );

            // this.user.projects = this.projects.filter((userType) =>
            //   projectIds.includes(userType.id)
            // );
          }
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  selectFile(event: any): void {
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (event.target.files.length > 0) {
      this.currentFile = event.target.files[0];

      if (this.currentFile) {
        this.preview = '';
        const reader = new FileReader();

        reader.onload = (e: any) => {
          //console.log(e.target.result);
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }
  uploadFile(params, bucket): Promise<string> {
    return new Promise((resolve, reject) => {
      bucket
        .upload(params)
        .on('httpUploadProgress', (evt) => {
          this.progress = Math.round((100 * evt.loaded) / evt.total);
        })
        .send((err, data) => {
          if (err) {
            console.error('There was an error uploading your file:', err);
            reject(err);
          } else {
            const url = data.Location;
            resolve(url);
          }
        });
    });
  }
  uploadPhoto(): void {
    var st = 'Class786--';
    this.apiService.getAwsCredentials(st).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.credentials = resp.data;
          var uploadpic = null;
          this.progress = 0;
          var today = new Date();
          const awsPath = `tms/org_${this.authService.currentUserValue.organizationId}/images/user-profiles`;
          const filePath = `${awsPath}/${this.currentFile.name}${today
            .getDate()
            .toString()}${today.getMilliseconds().toString()}`;
          const contentType = this.currentFile.type;
          const bucket = new S3({
            accessKeyId: this.credentials.accessKey,
            secretAccessKey: this.credentials.secretKey,
            region: this.credentials.region,
          });
          const params = {
            Bucket: this.credentials.bucketName,
            Key: filePath,
            Body: this.currentFile,
            ACL: 'public-read',
            ContentType: contentType,
          };
          this.uploadFile(params, bucket).then((c) => {
            var url = c;
            this.user.profilePictureUrl = url;
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  public getAwsSignUrl(file: File): void {
    const awsPath = `tms/org_${this.authService.currentUserValue.organizationId}/images/user-profiles`;
    const filePath = `${awsPath}/${file.name}`;

    let encodedPath = encodeURIComponent(filePath);
    let type = encodeURIComponent(file.type);

    this.apiService.getS3SignedUrl(encodedPath, type).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.apiService.uploadToS3(this.currentFile, resp.data).subscribe({
            next: (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event.type === HttpEventType.Response) {
                if (event.status === 200) {
                  const url = `https://d1zoy1lzkhwt04.cloudfront.net/${filePath}`;
                  this.user.profilePictureUrl = url;
                } else {
                }
              }
            },
            error: (error) => {
              // Handle error
              console.error('Upload error:', error);
            },
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  public onSubmit() {
    if (this.validateAndSetData()) {
      this.apiService.addOrUpdateUser(this.user).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  public validateAndSetData() {
    if (!this.user.firstName || !this.user.lastName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter first and last names',
        life: 3000,
      });
      return false;
    }

    if (!this.user.emailAddress) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must enter a valid email address',
        life: 3000,
      });
      return false;
    }

    if (!this.selectedUserType || this.selectedUserType.id === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must select user type',
        life: 3000,
      });
      return false;
    }
    if (!this.selectedUserRole || this.selectedUserRole.id === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must select user role',
        life: 3000,
      });
      return false;
    }

    if (this.user.projects.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You must select at least one project',
        life: 3000,
      });
      return false;
    }

    if (this.currentFile) {
      if (!this.user.profilePictureUrl) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'You may have forgotten to upload user profile picture',
          life: 3000,
        });
        return false;
      }
    }

    this.user.userTypeId = this.selectedUserType.id;
    this.user.roleId = this.selectedUserRole.id;
    this.user.userName = this.user.emailAddress;
    return true;
  }

  public modalClose() {
    this.dialogRef.close();
  }
  //#region Validation
  protected messageRemover()
  {
    this.errorMessage='';
  }
  //#endregion
}
function progressCallback(percentage: number) {
  throw new Error('Function not implemented.');
}
