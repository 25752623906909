export class AWSServerCredentials {
    accessKey!: string;
    secretKey!: string;
    bucketName!: string;
    region!: string;
  }
  export class S3SignedUrl {
    encodedPath!: string;
    type!: string;
  }
  