import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ClientSetupLocationComponent } from '../client-setup-location/client-setup-location.component';
import { Result } from 'src/app/Models/Result';
import { ClientLocations } from 'src/app/Models/ClientLocations';

@Component({
  selector: 'app-client-locations',
  templateUrl: './client-locations.component.html',
  styleUrls: ['./client-locations.component.css']
})
export class ClientLocationsComponent {
  clientId: number;
  isLoading: boolean;
  clientLocation: ClientLocations = new ClientLocations();
  clientLocations: ClientLocations [] = [];
  slectedClientLocations: ClientLocations = new ClientLocations();
  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService:AuthService
  ) {}
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.clientId = +params['clientId'];
    });
    this.getClientLocations();
  }
  getClientLocations(){
    this.isLoading = true;
    this.apiService.getClientLocations(this.clientId).subscribe({
      next: (resp: Result) => {
          this.isLoading = false;
          if (resp.status === 'success') {
              this.clientLocations = resp.data;
              console.log(this.clientLocations);
          } else {
              this.clientLocations = []; // Handle cases where status is not success
          }
      },
      error: (error) => {
          this.isLoading = false; // Ensure loading stops even on error
          this.clientLocations = []; // Handle error case with empty data
          console.error('error:', error);
      }
  });
  }
  updateLocation(item: any){
    let clientLocationObj = new ClientLocations();
    if (item) {
      clientLocationObj = item;
    }
      const modalRef = this.dialogService.open(ClientSetupLocationComponent, {
        modal: true,
        showHeader: true,
        header: 'Manage Client Locations',
        width: '30%',
        contentStyle: { overflow: 'hidden', padding: '0px' },
        baseZIndex: 10000,
        maximizable: false,
        data: {
          clientLocation: clientLocationObj,
          client: this.clientId,
        },
      });
      modalRef.onClose.subscribe(() => {
        this.getClientLocations();
      });
  }
  editClientLocationById
  deleteClientLocationById(id: number){
    this.slectedClientLocations.id = id;
    this.slectedClientLocations.isActive = false;

    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      message: 'Do you want to delete this client location?',
      accept: () => {
        this.apiService.addorUpdateClientLocations(this.slectedClientLocations).subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Client location deleted successfully.',
                life: 3000,
              });
              this.getClientLocations();
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: resp.message,
                life: 3000,
              });
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
          },
        });
      },
      reject: () => {},
    });
  }

}
