import { HrmsPluginSettings } from "src/app/Models/HrmsPluginSettings";
import { Project } from "src/app/Models/Users";

export const environment = {
  production: false,
  apiUrl: 'route',
  oneSignalAppId: '49c652d7-a7e0-46cd-9e82-4a62b78dfeae'
};

export const GlobalVariables: {
  selectedProjectId: number;
  selectedProject: Project | null; // Change 'any' to more specific type if known
  hrmsPluginConfig: HrmsPluginSettings | null;
  siteLanguage: string;
  languageList: { code: string; label: string }[];
} = {
  selectedProjectId: 0,
  selectedProject: null,
  hrmsPluginConfig: null,
  siteLanguage: 'English',
  languageList: [
    { code: 'en', label: 'English' },
    { code: 'ar', label: 'Arabic' },
  ],
};
