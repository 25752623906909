<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-memo-circle-info"></i> {{ "Cost Details" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">

      <h5 class="m-0 me-2">Data Type:</h5>
      <p-selectButton [options]="reportTypes" [(ngModel)]="dataTypeId" (onChange)="dataTypeChange($event)" name="label"
        optionLabel="label" optionValue="id" dataKey="id" appendTo="body">
      </p-selectButton>

      <button class="btn btn-sm btn-outline-dark action-button-outline ml-3 " (click)="filterVisible = true"><i
          class="fa-light fa-filter-list"></i>
        {{ "Apply Filters" | translate }}</button>
        <!-- <button class="btn btn-sm btn-outline-dark action-button-outline " (click)="print()"><i
          class="fa-light fa-file-pdf"></i></button>
        <button class="btn btn-sm btn-outline-dark action-button-outline" (click)="exportCostDetails()">
          <i
          class="fa-light fa-file-excel"></i></button> -->
    </div>
  </div>

  <ng-container class="card-body" *ngIf="dataTypeId === 1">
    <div id="cost-detail-table" class="table-responsive" style="overflow-y: auto; height: calc(100vh - 200px);"
      *ngIf="!isLoading; else loader">
      <table class="table table-hover parentTable" *ngIf="costingData != null && costingData.length > 0; else noData">
        <thead>
          <tr class="sticky-header">
            <th style="width: 40px;"></th>
            <th>Task Title</th>
            <th>Client Name</th>
            <th>Engagement Name</th>
            <th>Billable Type</th>
            <th>Is Billable</th>
            <th>Total Hours</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let task of costingData">
            <tr>
              <td class="text-center">
                <button type="button" class="btn m-0 p-0" (click)="toggleExpand(task)">
                  <i [ngClass]="task.expanded ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-right'"></i>
                </button>
              </td>
              <td class="taskTitle">{{ padZero(task.taskId, 3) + ' - ' + task.taskTitle }}</td>
              <td>{{ task.clientName }}</td>
              <td>{{ task.caseName }}</td>
              <td>{{ task.billableType }}</td>
              <td>{{ task.isBillable ? 'Yes' : 'No' }}</td>
              <td [title]="getTaskMessages(task, 'hours')?.details"
                [class]="task.totalHours > task.estimatedHours ? 'text-danger fw-bold' : ''"><i
                  *ngIf="task.totalHours > task.estimatedHours" class="fa-regular fa-triangle-exclamation mr-1"></i>{{
                (task.totalHours | number : '1.2-2') + ' H' }}</td>
              <td [title]="getTaskMessages(task, 'cost')?.details"
                [class]="task.totalCost > task.estimatedCost ? 'text-danger fw-bold' : ''"><i
                  *ngIf="task.totalCost > task.estimatedCost" class="fa-regular fa-triangle-exclamation mr-1"></i>{{
                task.totalCost | currency: 'Rs ' }}</td>
            </tr>
            <ng-container *ngIf="task.expanded">
              <tr>
                <td colspan="20">
                  <div
                    [class]="!task.isLoadingBreakout ? 'task-detail-container' : 'task-detail-container d-flex justify-content-center'">
                    <ng-container *ngIf="!task.isLoadingBreakout; else rowLoader">
                      <ul class="alert alert-secondary task-message-box">
                        <li *ngFor="let msg of task.taskWarnings">
                          <i *ngIf="msg.type === 'warn'"
                            class="fa-regular fa-triangle-exclamation text-danger mr-1"></i>{{msg.details}}
                        </li>
                      </ul>
                      <table class="childTable">
                        <thead>
                          <tr>
                            <th>User Name</th>
                            <th>Total Hours</th>
                            <th>Total Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let item of task.breakOut">
                            <tr>
                              <td>{{ item.userName }}</td>
                              <td>{{(item.totalHours | number : '1.2-2') + ' H' }}</td>
                              <td>{{ item.totalCost | currency: 'Rs ' }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </ng-container>
                  </div>
                  <ng-template #rowLoader>
                    <div class="table-row-loader" style="width: 24px; margin: 2rem;"></div>
                  </ng-template>
                </td>
              </tr>

            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container class="card-body" *ngIf="dataTypeId === 2">
    <div id="case-wise-detail-table" class="table-responsive" style="overflow-y: auto; height: calc(100vh - 200px);"
      *ngIf="!isLoading; else loader">
      <table class="table table-hover parentTable"
        *ngIf="caseWiseData != null && caseWiseData?.length > 0; else noData">
        <thead>
          <tr class="sticky-header">
            <th>#</th>
            <th>Engagement Name</th>
            <th>Client Name</th>
            <th>Total Hours</th>
            <th>Total Cost</th>
            <th>Billable Hours</th>
            <th>Non Billable Hours</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of caseWiseData; let index = index">
            <tr>
              <td>{{ index+1 }}</td>
              <td>{{ item.caseName }}</td>
              <td>{{ item.clientName }}</td>
              <td>{{(item.hoursSpent.toFixed(2)) + ' H' }}</td>
              <td>{{ item.totalCost.toFixed(2) | currency: 'Rs ' }}</td>
              <td>{{ item.billableHours.toFixed(2) + ' H' }}</td>
              <td>{{ item.nonBillableHours.toFixed(2) + ' H' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>

  <p-sidebar [(visible)]="filterVisible" position="right" styleClass="w-25rem">
    <ng-template pTemplate="header">
      <h5 class="font-semibold text-xl">Filter Cost Details</h5>
    </ng-template>
    <div (keydown.escape)="filterVisible = false">
      <div>

        <div class="form-group">
          <label for="taskTitle" class="form-label">Task Title</label>
          <input type="text" id="taskTitle" [(ngModel)]="filters.taskTitle" class="form-control"
            placeholder="Enter title keyword">
        </div>

        <div class="form-group">
          <label for="clientName" class="form-label">Client Name</label>
          <input type="text" id="clientName" [(ngModel)]="filters.clientName" class="form-control"
            placeholder="Enter client name keyword">
        </div>

        <div class="form-group">
          <label for="billableType" class="form-label">Billable Type</label>
          <select id="billableType" [(ngModel)]="filters.billableTypeId" class="form-control custom-select">
            <option value="0">Any</option>
            <option value="1">User-Based</option>
            <option value="2">Task-Based</option>
            <option value="3">Engagement-Based</option>
            <option value="4">Not Billable</option>
          </select>
        </div>

        <div class="form-group">
          <label for="isBillable" class="form-label">Is Billable</label>
          <select id="isBillable" [(ngModel)]="isBillable" class="form-control custom-select">
            <option value="">Any</option>
            <option [value]="true">Yes</option>
            <option [value]="false">No</option>
          </select>
        </div>

        <div class="form-row d-flex justify-content-between">
          <div class="form-group col-md-6">
            <label for="startDate" class="form-label">From Date</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="startDate" ngbDatepicker
                #d1="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d1.toggle()" type="button">
                <i class="fa-solid fa-calendar"></i>
              </button>
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="endDate" class="form-label">To Date</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="endDate" ngbDatepicker
                #d2="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3 cal-btn" (click)="d2.toggle()" type="button">
                <i class="fa-solid fa-calendar"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary action-button-outline" (click)="resetFilters()">
            <i class="fas fa-times me-1"></i>Clear All
          </button>
          <button type="button" class="btn btn-outline-secondary action-button-outline" (click)="filterVisible = false">
            Close
          </button>
          <button class="btn btn-primary action-button" (click)="applyFilter()">
            <i class="fas fa-search me-1"></i>Search
          </button>
        </div>
      </div>
    </div>
  </p-sidebar>

  <ng-template #loader>
    <app-project-loading-indicator height="calc(100vh - 165px)" />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No data available yet." height="calc(100vh - 165px)"></app-nodatafound>
  </ng-template>
</div>
