<div class="text-center login-main-right p-5 mt-5 mb-5" [ngStyle]="{
       'height': height,
       'display': 'flex',
       'align-items': 'center',
       'justify-content': 'center',
       'flex-direction': 'column'
     }">

  <i class="fa-light fa-circle-info" style="font-size: 42px; color: #75beff;"></i>

  <!-- Display Message -->
  <!-- <p class="mt-4" style="text-align: center">
    {{ message }}
  </p> -->
  <br>

  <!-- Steps to complete with Explanations -->

  <h5 class="w-60">

    <!-- Step 1: Create a Case -->
    <span [ngClass]="{'completed': isStepCompleted('case')}">
      <small>To initiate the client engagement, please provide the necessary details to create an engagement. Once the engagement is
        established, you will be able to create and assign tasks, track progress, and manage all relevant information
        seamlessly.</small>
    </span>
    <br>
    <!-- Step 2: Manage Assignee -->
    <!-- <span [ngClass]="{'completed': isStepCompleted('assignee')}">
      2. Manage Assignee
      <br>
      <small>Assign team members or stakeholders to the case. This ensures that everyone knows their responsibilities.</small>
    </span>
    <br> -->
    <!-- Step 3: Add Billing Setup -->
    <!-- <span [ngClass]="{'completed': isStepCompleted('billing')}">
      3. Add Billing Setup
      <br>
      <small>Set up billing information to manage expenses and budget for the case effectively.</small>
    </span>
    <br> -->
  </h5>

  <!-- Create New Case Button -->
  <a (click)="addNewCase(null)" class="btn btn-sm btn-outline-dark action-button-outline mt-3">Create New Engagement</a>

  <!-- Conditional Rendering for Cases -->
  <ng-container *ngIf="cases?.length > 0">
    <ng-container *ngFor="let case of cases">
      <!-- Manage Assignee Button -->
      <a class="btn btn-sm btn-outline-light mr-2" (click)="manageAssignee(case)">
        <i class="fa-regular fa-user"></i>
        &nbsp;
        {{ "Manage Assignee" | translate }}
      </a>

      <!-- Billing Settings Button -->
      <a class="dropdown-item mb-2" (click)="addBillingSettings(case.id)">
        <i class="fa-light fa-money-bill-1"></i>
        &nbsp;
        {{ "Billing Settings" | translate }}
      </a>
    </ng-container>
  </ng-container>
</div>
