
<div class="modal-body">

          <div class="mt-3 d-flex justify-content-between"
          style="background-color: var(--gray-100);padding: 8px;align-items: center;padding-right: 20px;">
          <div class="patient-info-container">
            <div class="patient-img" style="background-image: url(img/common/profile_avatar.png);"></div>
            <div
              style="display: flex; flex-direction: column; padding-left: 10px; font-size: 12px; line-height: 1.1rem; justify-content: flex-start; align-items: flex-start;">
              <span class="boxlinks" style="font-weight:500; font-size: 13px;">{{this.clientInfo.clientName}}
              </span>
              <span>{{this.clientInfo.contactEmail}}</span>
              <span
                style="background: #869ac0; padding: 0px 3px; border-radius: 5px; color: white; font-size: 11px; font-weight: 600;">
                CLINET#{{clientInfo.id}}
              </span>
             
            </div>
            <div class="d-flex justify-content-between">
            <div
            style="padding: 0px 10px; color: black;">
            {{path}}
            </div>
        </div>
          </div>
          </div>
          <div class="row mt-3" id="basic">
            <div class="col-md-4">
              <div class="control-group">
                <label class="control-label">Document Name</label>
                <div class="controls">
                  <input type="text" class="form-control mt-1" name="documentName" id="documentName" placeholder="Enter document name"
                  [(ngModel)]="clientDocuments.documentName"  required>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row" id="basic">
                <div class="col-md-6">
                  <div class="control-group"><label class="control-label">Document description</label>
                    <div class="controls d-flex">
                      <input type="text" class="form-control mt-1" name="description" id="description"
                      placeholder="Enter contact name" [(ngModel)]="clientDocuments.description" >
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="row mt-3" id="basic">
          <div class="form-group col-md-12">
            <p-fileUpload name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php"
              (onUpload)="onUpload($event)" (onSelect)="onSelect($event)" [multiple]="false" [maxFileSize]="1000000" [disabled]="progress > 0 && progress < 99">
              <ng-template pTemplate="content">
                <ng-container *ngIf="!selectedFile && progress === 0">
                  <ng-template>
                    <div class="flex align-items-center" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    flex-direction: column;">
                    <span>
                      <i class="fa-thin fa-paperclip fs-64px mb-3"></i>
                    </span>
                    <span class="font-bold white-space-nowrap m-0">No attachment found</span>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-template>
            </p-fileUpload>
            <p-progressBar *ngIf="progress > 0" [value]="progress" [style]="{ height: '12px' }"></p-progressBar>
          </div>
          </div>

        </div>

     
      

  
<div class="modal-footer mt-3 mb-3 pr-4">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
  (click)="modalClose()">Cancel</button>
  <button type="submit" (click)="onSubmit()" [disabled]="this.isDataSaving" class="border-0 btn btn-primary btn-sm action-button"><i
      class="fas fa-save"></i> Save</button>
</div>
