<div class="container">
    <div class="page-head-container">
      <h4 class="page-title"><i class="fa-regular fa-users"></i> {{ "Manage Task Template" }}</h4>
      <!-- Filter Section -->
      <div class="d-flex align-items-center">
        <!-- [hasPermission]="{ access: 'ADD_ROLE', action: 'disable' }" -->
        <button 
          class="btn btn-primary action-button text-nowrap ms-5" (click)="addNewTemplate()"><i class="fa-light fa-plus"></i>
          {{ "Add New Template" }}</button>
      </div>
    </div>


    <div class="table-responsive" style="height: calc(100vh - 200px);" *ngIf="taskTemplate != null && taskTemplate.length > 0; else !isLoading ? noData : loader">
      <table class="table table-hover">
        <thead>
          <tr class="sticky-header">
          <tr>
            <th scope="col">Template Name</th>
            <th scope="col">Description</th>
            <th scope="col">No. of Tasks</th>
            <th scope="col">System Template</th>
            <th scope="col">Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let temp of taskTemplate; let i = index">
            <td>{{ temp.name }}</td>
            <td>{{ temp.templateType || 'N/A' }}</td>
            <td [ngClass]="{ 'clickable': temp.taskList && temp.taskList.length > 0 }"
              (click)="temp.taskList && temp.taskList.length > 0  && tasktemplateList(temp)">
      
            {{temp.taskList ? temp.taskList.length : 0}}
          </td>
            <td>
              <span class="badge status bg fs-12px" [class]="temp.isSystemTemplate && temp.isSystemTemplate === true ? 'not-started' : 'inactive'">{{temp.isSystemTemplate === true ? 'YES' : 'NO' }}</span>
            </td>
            <td><span class="bg status badge fs-12px" [class]="temp.active ? 'active' : 'inactive'">{{ temp.active ?
                'Active' : 'Inactive' }}</span></td>
            <td>
              <div class="btn-group" *ngIf="!temp.isSystemTemplate">
                <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ "Options" | translate }}
                </button>
                <div class="dropdown-menu">
                  <button class="dropdown-item table-action-btn mb-2" (click)="tasktemplateList(temp)">
                    <i class="fa-regular fa-eye mr-1"></i>
                    {{"View Tasks Template" | translate}}</button>
                  <button class="dropdown-item table-action-btn mb-2" (click)="bulkTasks(temp)">
                    <i class="fa-regular fa-plus mr-1"></i>
                    {{"Add Tasks to Template" | translate}}</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ng-template #loader>
      <app-project-loading-indicator height="calc(100vh - 205px)" />
    </ng-template>

    <ng-template #noData>
      <app-nodatafound message="{{'No template created yet.' | translate }}" height="calc(100vh - 205px)"></app-nodatafound>
    </ng-template>

