import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { Task, TaskStatus, TasksFilter } from 'src/app/Models/Task';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { GlobalVariables } from 'src/environments/environment';

@Component({
  selector: 'app-task-activity-filters',
  templateUrl: './task-activity-filters.component.html',
  styleUrls: ['./task-activity-filters.component.css'],
  animations: [
    trigger('childSlide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left <=> right', animate('300ms')),
    ]),
  ],
})
export class TaskActivityFiltersComponent
{
  @Input() data: any | undefined;
  @Input() slideState: string | undefined;
  @Output() onHideRequest = new EventEmitter<boolean>();
  @Output() onSearchRequest = new EventEmitter<TasksFilter>();

  public tasksFilter: TasksFilter = new TasksFilter();

  fromDate: any = null; //new Date(new Date().setMonth(new Date().getMonth() - 1)); //new Date;"1-1-2022";
  toDate: any = null; //new Date(); //"12-31-2022";
  public selectedUers: User[] = [];
  public selectedUersFilter: User[] = [];
  public allStatus: TaskStatus[] = [];
  public allUsers: User[] = [];
  dropdownSettingsUsers = {
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: 'There is no item availabale to show',
    singleSelection: false,
    enableCheckAll: true,
    clearSearchFilter: true,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private messageService: MessageService)
  {
    this.loadUsers();
  }
  hideFilter()
  {
    this.onHideRequest.emit(true);
  }
  applyFilter(): void
  {
    if (this.fromDate)
    {
      this.tasksFilter.fromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      );
    }
    if (this.toDate)
    {
      this.tasksFilter.toDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      );
    }
    this.tasksFilter.pageNumber = 1;
    this.tasksFilter.userIds = this.selectedUersFilter ? this.selectedUersFilter?.map((x) => x.id) : [];
    this.onSearchRequest.emit(this.tasksFilter);
  }
  resetFilters(): void
  {
    this.tasksFilter = new TasksFilter();
    this.tasksFilter.pageNumber = 1;
    this.onDeSelectAllIUsersFilter('');
    this.onSearchRequest.emit(this.tasksFilter);
  }
  loadUsers()
  {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe(
      {
        next:
          (resp: Result) =>
          {
            if (resp.status === 'success')
            {
              this.allUsers = resp.data;
            } else
            {
              this.allUsers = resp.data;
              this.messageService.add({ severity: 'warn', summary: 'Warning', detail: resp.message, life: 3000, });
            }
          },
        error:
          (error) =>
          {
            console.error('error:', error);
          },
        complete: () =>
        {

        }
      }

    );
  }
  selectUsers(e: any)
  {
    const data = e;
    this.selectedUers.push(data);
  }

  onItemDeSelectUsers(item: any)
  {
    this.selectedUers?.forEach((value: any, index: any) =>
    {
      if (value == item.id) this.selectedUers?.splice(index, 1);
    });
  }

  onSelectAllUsers(items: any)
  {
    this.selectedUers = items;
  }

  onDeSelectAllIUsers(items: any)
  {
    this.selectedUers = items;
  }

  selectUsersFilter(e: any)
  {
    const data = e;
    this.selectedUersFilter.push(data);
  }

  onItemDeSelectUsersFilter(item: any)
  {
    this.selectedUersFilter?.forEach((value: any, index: any) =>
    {
      if (value == item.id) this.selectedUersFilter?.splice(index, 1);
    });
  }

  onSelectAllUsersFilter(items: any)
  {
    this.selectedUersFilter = items;
  }

  onDeSelectAllIUsersFilter(items: any)
  {
    this.selectedUersFilter = items;
  }
}

