<div class="deal-view-container">
  <div class="modal-body pt-0 pb-0">
    <div class="row deal-view-content">
      <div class="col-3 deal-info-left-container">
        <div class="deal-info-left-container-child-container">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <b style="font-size: 1rem;">{{deal?.name}}</b>
          <div class="badge"
            [style]="deal?.color ? 'background-color:'+ deal?.color :'background-color: rgb(11, 94, 215)' ">
            <span>{{!deal?.dealStatus ? 'no-status': deal?.dealStatus}}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <span>Assigned To:
            <p-button label="{{deal.assigneesArray.length > 1 ? 'Multiple' :
              deal.assigneesArray.length === 0 ?
              noAssignee : deal.assignees.split(';')[1]}}" badge="{{deal.assigneesArray.length}}"
              [pTooltip]="tooltipContent" tooltipPosition="bottom" styleClass="lead-assignee-badge mr-2"></p-button>
            <ng-template #tooltipContent>
              <div *ngFor="let assignee of deal.assigneesArray" class="flex align-items-center">
                <img [src]="assignee.split(';')[2]" height="20" class="mr-2" />
                <span style="font-size: 11px;">{{assignee.split(';')[1]}}</span>
              </div>
            </ng-template>
            <ng-template #noAssignee>
              <b>Not Assigned Yet</b>
            </ng-template>
          </span>
        </div>
        <hr class="hr" />
        <div class="deal-info-left-scroll-area mt-2">
          <div class="deal-info-label">
            <i class="fa-solid fa-circle-info"></i>
            <b>About this deal</b>
          </div>
          <div class="deal-info-field-container">
            <div class="deal-info-label">
              <i class="fa-regular fa-user"></i>
              <label>Deal Valuation</label>
            </div>
            <b>{{deal?.value}}</b>
          </div>
          <div class="deal-info-field-container">
            <div class="deal-info-label">
              <i class="fa-regular fa-user"></i>
              <label>Probablity to win deal</label>
            </div>
            <b>{{deal?.probability*100}}% chance</b>
          </div>
          <div class="deal-info-field-container">
            <div class="deal-info-label">
              <i class="fa-regular fa-envelope"></i>
              <label>Deal Lead By</label>
            </div>
            <b>{{deal?.dealOwner}}</b>
          </div>
          <div class="deal-info-field-container">
            <div class="deal-info-label">
              <i class="fa-regular fa-user"></i>
              <label>Created By</label>
            </div>
            <b>{{deal?.createdBy}}</b>
          </div>
          <div class="deal-info-field-container">
            <div class="deal-info-label">
              <i class="fa-regular fa-user"></i>
              <label>Updated By</label>
            </div>
            <b>{{deal?.updatedBy}}</b>
          </div>
          <div class="deal-info-field-container">
            <div class="deal-info-label">
              <i class="fa-regular fa-note-sticky"></i>
              <label>Note</label>
            </div>
            <span style="font-style: italic;">{{deal?.comments}}</span>
          </div>
        </div>
      </div>
      </div>
      <div class="col-7 deal-info-center-container">
        <div style="background-color: lightslategray;padding: 0.5rem;">
          <h4 style="color: white;text-align: center; margin: 0;">Remarks</h4>
        </div>
        <div style="height: calc(100vh - 305px); overflow-y: auto; padding-bottom: 2rem;">
          <div class="log-container" *ngIf="logs.length>0; else noData">
            <table cellpadding="0" cellspacing="0" style="width: 100%;">
              <tbody *ngFor="let item of logs">
                <tr>
                  <td style="font-weight: 600; width: 115px;">Updated By:
                  </td>
                  <td style="position: relative;">
                    <div class="d-flex justify-content-between align-items-center">
                      <b>{{item?.name}}</b>
                      <div class="d-flex align-items-center">
                        <span class="me-2">{{item?.dateCreated |date:'medium'}}</span>
                        <button class="action-button-tbl-sm" (click)="editLogById(item)">
                          <i class="fa-solid fa-pencil"></i>
                        </button>
                        <button class="action-button-tbl-sm" (click)="deleteLogById(item.id)">
                          <i class="fa-regular fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: 600;">Status:
                  </td>
                  <td>
                    <span>{{!item?.dealStatus ? 'no-status': item?.dealStatus}}</span>
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: 600; vertical-align: super;">Remarks:
                  </td>
                  <td style="vertical-align: super;">{{!item?.details ? 'no-data': item?.details}}
                  </td>
                </tr>

                <hr class="hr activity-divider">
              </tbody>
            </table>
          </div>


        </div>
        <div class="remark-editor-area">
          <textarea class="textarea" [(ngModel)]="remarks.details"></textarea>
          <div class="d-flex justify-content-between align-items-center">
            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="remarks.statusId" class="form-select"
              style="width: 200px;">
              <option [disabled]="this.remarks.isEdit" *ngFor="let item of statusList" value="{{item.id}}">{{item.name}}
              </option>
            </select>
            <div class="d-flex justify-content-end align-items-center">
              <button type="button" class="btn btn-sm action-button-cancel mr-2" (click)="onCancel()">Close</button>
              <button *ngIf="this.remarks.isEdit" type="button" class="btn btn-sm btn-success mr-2" (click)="resetLogForm()">Cancel</button>
              <button type="button" class="btn btn-primary btn-sm action-button" (click)="addRemarks()">{{this.remarks.isEdit?'Update Remarks':'Save Remarks'}}</button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-2 deal-info-right-container">
        <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
      </div>
    </div>
  </div>
</div>







<ng-template #noData>
  <app-noactivity></app-noactivity>
</ng-template>
