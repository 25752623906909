<div class="modal-body">
  <div class="mt-3">
    <form id="create-task" autocomplete="off">
      <div style="background: #f5f7f9;padding: 3rem 10px 8px 10px;border-radius: 6px;position: relative;">
        <div class="d-flex justify-content-between align-items-center"
          style="position: absolute;top: 10px;width: 98%;left: 10px;">
          <h5><i class="fa-regular fa-list-check me-1"></i>Task Attributes</h5>
          <span *ngIf="selectedCaseName" class="badge pill"
            style="background-color: #539090 !important;font-size: 0.775rem;font-weight: 600;">Engagement:
            {{selectedCaseName}}</span>
        </div>
        <div class="form-row">
          <div *ngIf="taskId === 0 ;else elseBlock" class="form-group col-md-4">
            <label for="client">Client</label>
            <input type="text" class="form-control"  ngbInput [(ngModel)]="selectedClient" name="clientInfo"
              (selectItem)="onClientSelect($event)" [inputFormatter]="clientFormatter_search"
              [resultFormatter]="clientFormatter_selected" [ngbTypeahead]="searchClients" placeholder="Search client" required>
          </div>

          <ng-template #elseBlock>
            <div class="form-group col-md-4">
              <label for="client">Client</label>
              <input type="text" name="clientInfo" class="form-control" [value]="selectedClient?.clientName"
                disabled="true" />
            </div>
          </ng-template>

          <div class="form-group col-md-4">
            <label for="serviceName">Service</label>
            <p-dropdown required [options]="services" appendTo="body" name="serviceName" optionLabel="serviceName"
              [filter]="true" [(ngModel)]="task.serviceId" (selectItem)="onCaseSelect($event)" filterBy="serviceName"
              [showClear]="false" dataKey="serviceId" optionValue="serviceId" placeholder="Select service">
            </p-dropdown>
          </div>

          <div class="form-group col-md-4">
            <label for="status">Status</label>
            <p-dropdown  required [options]="taskStatuses" appendTo="body" name="status" optionLabel="status" [filter]="true"
              [(ngModel)]="task.taskStatusId" filterBy="status" [showClear]="false" dataKey="id" optionValue="id"
              placeholder="Select Status">
            </p-dropdown>
          </div>

        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="startDate">Start Date <span class="text-danger">* </span></label>
            <p-calendar [(ngModel)]="task.startDate" name="startDate" placeholder="Select start date"
              [icon]="'fa-regular fa-calendar'" [showIcon]="true" [showTime]="true" appendTo="body"
              [showButtonBar]="true"></p-calendar>
          </div>
          <div class="form-group col-md-4">
            <label for="dueDate">Due Date (Optional)</label>
            <p-calendar [(ngModel)]="task.dueDate" name="dueDate" placeholder="Select due date"
              [icon]="'fa-regular fa-calendar'" [showIcon]="true" [showTime]="true" appendTo="body"
              [showButtonBar]="true"></p-calendar>
          </div>

          <div class="form-group col-md-4">
            <label for="priorityName">Task Priority</label>

            <p-selectButton required [options]="taskPriorties" [(ngModel)]="task.taskPriorityId" name="priorityName"
              optionLabel="priorityName" optionValue="id" dataKey="id" appendTo="body">
              <ng-template let-item pTemplate>
                <i
                  [class]="item.id === 1 ? 'fas fa-exclamation-triangle orangish-red' : item.id === 2 ? 'fas fa-arrow-up text-success' : item.id === 4 ? 'fas fa-exclamation-triangle text-danger' : 'fas fa-arrow-down text-warning'"></i>
                <span class="ms-1">{{item.priorityName}}</span>
              </ng-template>
            </p-selectButton>
          </div>

        </div>

        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </div>

      <div class="form-group mt-3">
        <label for="taskName">Task Name</label>
        <input type="text" class="form-control" required [(ngModel)]="task.title" id="taskName" name="taskName"
          placeholder="Give your task a name" required>
      </div>

      <div class="form-group">
        <label for="taskDescription">Task Description</label>
        <div class="NgxEditor__Wrapper ngx-editor-task-detail">
          <ngx-editor [editor]="editorDetails" name="taskDescription" [(ngModel)]="jsonDescriptionDoc"
            [disabled]="false" (focusIn)="editorDetailsFocusToggle(true)" (focusOut)="editorDetailsFocusToggle(false)"
            [placeholder]="'Write details about this task'"></ngx-editor>
          <ngx-editor-menu *ngIf="editorDetailsMenu" [editor]="editorDetails" [toolbar]="toolbar" />
        </div>
      </div>

      <div class="form-group">
        <label for="acceptanceCriteria">Acceptance Criteria (Optional)</label>
        <div class="NgxEditor__Wrapper ngx-editor-task-accept">
          <ngx-editor [editor]="editorAcceptance" name="acceptanceCriteria" [(ngModel)]="jsonAcceptanceDoc"
            [disabled]="false" [placeholder]="'Write acceptance criteria for this task'"></ngx-editor>
          <ngx-editor-menu *ngIf="editorAcceptanceMenu" [toolbar]="toolbar" [editor]="editorAcceptance">
          </ngx-editor-menu>
        </div>
      </div>

      <div class="form-group">
        <label for="tags">Tags</label>
        <p-multiSelect [options]="tags" [(ngModel)]="selectedTags" name="tagName" optionLabel="tagName"
          placeholder="Select tags" dataKey="id" optionValue="id" appendTo="body" display="chip">
        </p-multiSelect>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer mt-3 mb-3 pr-4">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
    (click)="modalClose()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()">
    {{task.id > 0 ? 'Update Task' : 'Create Task'}}
  </button>
</div>

<ng-template #actions>
  <div class="d-flex align-items-center mt-3">

    <div class="btn-group">
      <button #taskDropdownButton type="button" class="btn btn-sm form-action-menu-btn "
        (click)="toggleAssigneesManager($event, assignees)">
        <i class="fa-light fa-user-group mr-1 "></i>
        <span class="required"> {{ "Task Assignees" | translate }} {{selectedAssignees.length > 0 ?
        '(' + selectedAssignees.length + ')' : ''}}</span>
      </button>

      <p-overlayPanel #assignees appendTo="body" [dismissable]="false">
        <div style="width: 750px; padding: 5px 10px 5px 10px;">
          <div class="d-flex align-content-center justify-content-between">
            <h5 class="mb-0" style="margin-bottom: 0px; font-size: 18px;">Assignees</h5>
            <button #taskDropdownButton type="button" class="btn btn-sm form-action-menu-btn"
             (click)="toggleAssigneesManager($event, assignees)"><i class="fa fa-light fa-times"></i></button>
          </div>
          <ng-container *ngIf="billableTypeId !== 2 && billableType">
            <div class="alert alert-warning p-1 mt-3 mb-1 fs-12px" role="alert">
              <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
              <span>You cannot set rates per hour for this task if the billing type in Engagement Billing Settings is not
                Task-Based
              </span>
            </div>
          </ng-container>
          <div class="d-flex align-items-center mt-3" style="background: aliceblue;padding: 5px 10px;">
            <i class="fa-regular fa-magnifying-glass mr-2 fw-bold text-secondary"></i>
            <p-autoComplete [(ngModel)]="selectedAssignee" [showEmptyMessage]="true" [suggestions]="filteredAssignees"
              field="name" appendTo="body" placeholder="Search Users" (completeMethod)="filterAssignees($event)"
              (onSelect)="onAssineeSelect($event)" (mousedown)="$event.stopPropagation()" [style.width]="'100%'">
              <ng-template let-user pTemplate="item">
                <div class="d-flex align-items-center gap-2">
                  <img [src]="user.profilePictureUrl" style="width: 18px; height: 18px; border-radius: 50%;" />
                  <div>{{ user.name }}</div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>

          <div *ngIf="selectedAssignees.length > 0; else noAssignee" class="table-responsive table-assignees-container"
            style="height: 150px;">
            <table class="table table-hover table-assignees">
              <thead>
                <tr class="sticky-header">
                  <th class="text-center" style="width: 35px;">-</th>
                  <th style="width: 280px;">Assinee</th>
                  <th class="text-center" style="width: 115px;">Total Hours</th>
                  <th class="text-center" style="width: 115px;">Cost (Hour)</th>
                  <th class="text-center" style="width: 120px;">Total Est. Cost</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of selectedAssignees">
                  <tr>
                    <td>
                      <p-checkbox [(ngModel)]="item.selected" [binary]="true" [value]="item.selected" />
                    </td>
                    <td>
                      <div class="d-flex align-items-center" style="font-size: 0.875rem;">
                        <img [src]="item.profilePictureUrl"
                          style="width: 18px; height: 18px; border-radius: 50%; margin-right: 7px;">
                        <span>{{item.name}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center me-3">
                        <span class="me-1 required">H</span>
                        <input type="text" class="form-control" [(ngModel)]="item.estimatedHours" placeholder="0"
                          style="width: 70px;" required />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="me-1">Rs</span>
                        <input type="text" class="form-control" [(ngModel)]="item.ratesPerHour" placeholder="0"
                          [disabled]="billableTypeId !== 2" style="width: 70px;" />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center justify-content-center">
                        <span class="me-1">{{(item.ratesPerHour * item.estimatedHours) | currency: 'Rs '}}</span>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <ng-template #noAssignee>
            <div class="d-flex flex-column justify-content-center align-items-center" style="height: 150px;">
              <i class="fa-light fa-user-group mt-3 mb-2"></i>No one assigned yet
            </div>
          </ng-template>

          <div class="d-flex justify-content-end mt-3">
            <button type="button" class="btn btn-sm btn-secondary me-2" (click)="resetAssinees()"
              style="font-size: 11px;">
              <i class="fa-regular fa-arrows-rotate"></i> Reset
            </button>
            <button type="button" class="btn btn-sm btn-primary" (click)="closeDropdown()" style="font-size: 11px;">
              <i class="fa-solid fa-check"></i> Done
            </button>
          </div>
        </div>
      </p-overlayPanel>
      <!-- <div class="dropdown-menu custom-dropdown-menu" (click)="$event.stopPropagation()">

      </div> -->

      <ng-container *ngIf="selectedAssignees.length > 0">
        <div class="likers-group ms-1">
          <img *ngFor="let u of selectedAssignees | slice:0:3; let i=index" [src]="u.profilePictureUrl" alt="{{u.name}}"
            title="{{u.name}}">
        </div>
      </ng-container>
    </div>

    <div class="btn-group ms-2">
      <button type="button" class="btn btn-sm form-action-menu-btn" (click)="deps.toggle($event)">
        <i class="fa-regular fa-object-subtract mr-1"></i> {{ "Task Dependencies" | translate }}
      </button>
      <p-overlayPanel #deps appendTo="body" [dismissable]="false">
        <div style="width: 450px;padding: 5px 10px 5px 10px;">

          <div class="d-flex align-content-center justify-content-between">
            <h5 class="mb-0" style="margin-bottom: 0px; font-size: 18px;">Dependencies</h5>
            <button type="button" class="btn btn-sm btn-primary" (click)="addDependency()" style="font-size: 11px;"><i
                class="fa fa-light fa-plus"></i> Add
              New</button>
          </div>
          <div class="dep-scroll">
            <ng-container *ngIf="task.checklists.length > 0 else noChecklist">
              <div *ngFor="let item of task.checklists; let i = index"
                class="d-flex flex-row align-items-center justify-content-between mt-3 pb-2 pt-2"
                style="border-bottom: 1px dashed gainsboro;background: aliceblue;padding: 5px;">

                <span class="ms-1 me-1">{{i+1}}</span>

                <input #dependencyInput type="text" class="form-control" [(ngModel)]="item.description" id="dep"
                  name="dep{{i}}" placeholder="Enter dependency details">

                <button type="button" class=" btn btn-sm btn-danger delete-btn ms-2" (click)="removeDependency(i)">
                  <i class="fa fa-light fa-minus" style="font-size: 9px;"></i>
                </button>
              </div>
            </ng-container>
            <ng-template #noChecklist>
              <div class="d-flex flex-column justify-content-center align-items-center h-100">
                <i class="fa-light fa-list-check mb-3 fs-42px text-secondary"></i>No items found. The dependency list is
                empty
              </div>
            </ng-template>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <button type="button" class="btn btn-sm btn-secondary me-2" (click)="removeAllDependency()"
              style="font-size: 11px;">
              <i class="fa-regular fa-arrows-rotate"></i> Reset
            </button>
            <button type="button" class="btn btn-sm btn-primary" (click)="deps.hide()" style="font-size: 11px;">
              <i class="fa-solid fa-check"></i> Done
            </button>
          </div>
        </div>
      </p-overlayPanel>
    </div>

    <div class="btn-group ms-2">
      <button type="button" class="btn btn-sm form-action-menu-btn" (click)="attachements.toggle($event)">
        <i class="fa-regular fa-paperclip mr-1"></i> {{ "Task Attachments" | translate }}{{task.documents.length > 0 ?
        '(' + task.documents.length + ')' : '' }}
      </button>
      <p-overlayPanel #attachements appendTo="body" [dismissable]="false">
        <div style="width: 750px; padding: 5px 10px 5px 10px;">
          <!-- <div class="d-flex align-content-center justify-content-between">
            <h5 class="mb-0" style="margin-bottom: 0px; font-size: 18px;">Attachments</h5>
            <button type="button" class="btn btn-sm btn-primary" style="font-size: 11px;"
            (click)="addAttachment()" *ngIf="!isAttachment"><i
                class="fa fa-light fa-plus"></i> Add
              Files</button>
          </div> -->

          <div class="form-group col-md-12">
            <!-- Trigger button for file upload -->
            <button class="border-0 btn btn-info btn-sm action-button ml-5 mb-2" (click)="triggerFileUpload()">
              Upload Files
            </button>
            <p-progressBar *ngIf="progress > 0" [value]="progress" [style]="{ height: '12px' }"></p-progressBar>
            <!-- Hidden file upload component -->
            <p-fileUpload #fileUploadInput [style.display]="'none'" name="demo[]"
              url="https://www.primefaces.org/cdn/api/upload.php" (onRemove)="onRemove($event)"
              (onSelect)="onSelect($event)" [auto]="true" [multiple]="true" [maxFileSize]="1000000">
            </p-fileUpload>

            <!-- Display uploaded files only after files are selected or uploaded -->
            <div *ngIf="uploadedFiles.length > 0 || task.documents.length > 0 ; else noFilesFound" class="card-body">
              <div class="table-responsive activity-tables" style="max-height: 180px;">
                <table class="table table-hover">
                  <thead class="sticky-header thead-light">
                    <tr>
                      <th style="width: 200px;">{{ "Name" | translate }}</th>
                      <th>{{ "Size" | translate }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of task.documents; let rowIndex = index">
                      <tr>
                        <td class="leadname-td">
                          <div class="d-flex align-items-center leadname-container">
                            <div class="leadname d-flex align-items-center file-name">
                              <div class="file-icon mr-2" [ngClass]="item.fileExt"></div>{{ item.documentName }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="ellipsis" style="max-width: 130px; font-weight: 500;">
                            {{ item.size }} Kb
                          </div>
                        </td>
                        <td><button type="button" class=" btn btn-sm btn-danger delete-btn ms-2"
                            (click)="onRemoveFile(item)">
                            <i class="fa fa-light fa-minus" style="font-size: 9px;"></i>
                          </button></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
            <ng-template #noFilesFound>
              <div class="flex align-items-center" style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 150px;
              flex-direction: column;">
                <span>
                  <i class="fa-thin fa-paperclip fs-64px mb-3"></i>
                </span>
                <span class="font-bold white-space-nowrap m-0">No attachment found</span>
              </div>
            </ng-template>
          </div>
        </div>

      </p-overlayPanel>
    </div>

    <div class="form-check form-check billable-container ms-3">
      <span class="billable-container-span me-2">
        <i class="fa-regular fa-circle-dollar me-1"></i>Is Billable Task</span>
      <p-selectButton [options]="billableOptions" [(ngModel)]="task.isBillable" optionLabel="label" optionValue="value"
        [class]="task.isBillable ? 'billable' : 'non-billable'" />
    </div>

  </div>
</ng-template>
