import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirectiveDirective
{

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent)
  {
    const input = event.target as HTMLInputElement;
    const initialValue = input.value;
    input.value = initialValue.replace(/[^0-9+-]/g, '').slice(0, 16);

    if (initialValue !== input.value)
    {
      event.stopPropagation();
    }
  }
}
